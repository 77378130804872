import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import GroupIcon from "@material-ui/icons/Group";

import { UserReportBreadCrumb } from "views/Report/UserReport";
import TeamMemberProfile from "views/Teams/TeamMemberProfile";
import TeamUserReport from "views/Teams/TeamUserReport";
import UserTeams from "views/Teams/UserTeams";

import * as intl from "./localization";

export const teamRoutes: HackingLabRoute[] = [
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teams",
		name: "Teams",
		icon: GroupIcon,
		component: UserTeams,
		hasSidebarLink: true,
		needsRole: HackingLabRole.COMPOSITE_USER,
		needsAnyRole: [ HackingLabRole.USER_TEAMS, HackingLabRole.TEAMLEADER ],
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		breadcrumb: "Teams",
		help: { role: "teams", action: "overview" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teams/report/:teamId/users/:userId",
		name: intl.UserReport,
		component: TeamUserReport,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		breadcrumb: UserReportBreadCrumb,
		help: { role: "auditor", action: "pageUserReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teams/:teamId/users/:userId",
		component: TeamMemberProfile,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		breadcrumb: "Team Member",
		help: { role: "user", action: "teams" },
	},
];
