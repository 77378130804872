import defaultLogoSmall from "assets/img/logo-small.png";
import defaultLogo from "assets/img/logo.png";
import { hostnameBase, protocol } from "helper/hostname";
import { ActionType } from "actions/ActionType";

export const defaultBranding = {
	websiteName: "Hacking-Lab",
	appLogo: defaultLogo,
	appLogoSmall: defaultLogoSmall,
	realm: "master",
	helpUrl: `${protocol}//help.${hostnameBase}/`,
	dcServiceDomain: `${hostnameBase}`,
	emailNotification: false,
	legalNoticeUrl: "https://compass-security.com/en/imprint",
	privacyPolicyUrl: "https://www.hacking-lab.com/privacy"
};

export const fetchBranding = () => ({ type: ActionType.FETCH_BRANDING_CONFIG });

const setBranding = payload => ({
	type: ActionType.SET_BRANDING_CONFIG,
	payload
});

export default { fetchBranding, setBranding };
