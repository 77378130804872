import { UUID } from "types";

export const enum RemoteResourceStatus {
	INITIALIZED,
	LOADING,
	LOADED,
	ERROR,
}

export interface Dto<T extends UUID | number = UUID | number> {
	id?: T;
}

export interface ErrorResult {
	message?: string;
}

export type RemoteResourceStateData = Dto | Dto[];

export interface RemoteResourceState<T extends RemoteResourceStateData = RemoteResourceStateData> {
	status: RemoteResourceStatus;
	data?: T;
	error?: any;
}

export const isAnyResourceNotLoaded = (statuses: RemoteResourceStatus[]) => {
	return statuses.some(status => status !== RemoteResourceStatus.LOADED);
};

