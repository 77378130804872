import { grayColor } from "../../../material-dashboard-pro-react";

const managerStyle = {
	topInfoCards: {
		display: "flex",
		marginTop: 16,
		"&>div": { margin: "5px" }
	},
	infoCardFooter: {
		display: "flex",
		justifyContent: "space-between"
	},
	cardStatsIcon: {
		position: "relative",
		margin: "0 6px",
		top: "4px",
		width: "16px",
		height: "16px"
	},
	filterField: {
		float: "right",
		marginTop: "-22px"
	},
	filterBox: {
		display: "flex",
		flexDirection: "column"
	},
	editButton: {
		margin: 0,
		marginRight: "5px",
		padding: 5
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative"
	},
	titleBox: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between"
	},
	titleBoxButtons: { marginTop: -10 },
	publishedTitle: {
		marginLeft: 10,
		fontSize: "0.75em",
		opacity: 0.5,
		fontWeight: 400
	},
	detailsForm: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between"
	},
	imageUploadForm: {
		minWidth: "200px",
		maxWidth: "600px",
		maxHeight: "420px",
		width: "auto",
		height: "auto",
		flexBasis: "auto",
		flexGrow: 10,
		margin: "0 8px",
		objectFit: "cover"
	},
	descriptionBox: {
		minWidth: "300px",
		margin: "0 8px"
	},
	inputOverflow: { textOverflow: "ellipsis" },
	floatRight: { float: "right" },
	selectBoxDistance: { marginRight: "5px" },
	typeIcon: { color: grayColor },
	tooltip: {
		fontSize: "1.2em",
		letterSpacing: ".05em"
	},
	helpIcon: {
		width: "20px",
		height: "20px",
		color: "#666",
		position: "relative",
		marginLeft: 5,
		top: "4px"
	},
	inputLabel: {
		display: "block",
		fontSize: "1em",
		marginTop: 10
	},
	uuid: {
		fontSize: "11px",
		color: "gray"
	},
	titleLabel: {
		display: "inline-flex",
		alignItems: "center",
		lineHeight: "1em",
		fontSize: "12px",
		cursor: "pointer"
	},
	subEventButton: { margin: 0 },
	subEventButtonText: { lineHeight: "1em" },
	sortOrderSpacing: {
		display: "inline-block",
		width: 32,
		height: 1
	},
	tokenTableRow: { "&:hover": { background: "#f3f3f3" } },
	tokenTableHeader: {
		fontWeight: 400,
		fontSize: "0.9em",
		opacity: 0.6,
		padding: "0 10px",
		textAlign: "right"
	},
	tokenTableType: {
		fontWeight: 400,
		fontSize: "1.1em",
		opacity: 0.9,
		padding: "0 10px",
		textAlign: "left"
	},
	tokenTableValue: {
		fontWeight: 400,
		textAlign: "end",
		padding: "5px 10px"
	},
	voucherAmountInput: { width: 100 },
	tokenActions: { marginTop: 20 }
};

export default managerStyle;
