import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { formatMessage } from "@hlcr/ui/Intl";
import BugReportIcon from "@material-ui/icons/BugReport";
import { number } from "prop-types";
import { ExtractRouteParams, RouteProps } from "react-router";

import { BugBountyBugReport, BugBountyBugReportBreadCrumb } from "bugBounty/BugBountyBugReport";
import { BugBountyEvent, BugBountyEventBreadCrumb } from "bugBounty/BugBountyEvent";
import { OverviewPage } from "bugBounty/OverviewPage";

export enum BugBountyRoutes {
	ROOT = "ROOT",
	EVENT = "EVENT",
	CREATE_NEW_BUG = "CREATE_NEW_BUG",
	BUG_REPORT = "BUG_REPORT",
	EVENT_REDEEM = "EVENT_REDEEM",
	EVENT_REDEEM_TOKEN = "EVENT_REDEEM_TOKEN",
}

const bugBountyRootRoute: RouteProps<"/bugbounty/:eventId"> = { path: "/bugbounty/:eventId" };

const ROOT_PATH = "/bugbounty";
const EVENT_PATH = "/bugbounty/:eventId";
const CREATE_NEW_BUG_PATH = "/bugbounty/:eventId/bug/new";
const BUG_REPORT_PATH = "/bugbounty/:eventId/bug/:bugId";
const EVENT_REDEEM_PATH = "/bugbounty/redeem/";
const EVENT_REDEEM_TOKEN_PATH = "/bugbounty/redeem/:tokenParam";


export const BUG_BOUNTY_ROUTES: EnumDetail<BugBountyRoutes, HackingLabRoute> = {
	[BugBountyRoutes.ROOT]: {
		title: "navigation.entries.bugBounty.title",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.bugBounty.title"),
		path: ROOT_PATH,
		iconColor: "purple",
		icon: BugReportIcon,
		component: OverviewPage,
		hasSidebarLink: true,
		needsRole: HackingLabRole.ROLE_BUG_BOUNTY_HUNTER,
		breadcrumb: () => formatMessage("navigation.entries.bugBounty.root"),
	},
	[BugBountyRoutes.EVENT_REDEEM]: {
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: EVENT_REDEEM_PATH,
		component: OverviewPage,
		needsRole: HackingLabRole.ROLE_BUG_BOUNTY_HUNTER,
	},
	[BugBountyRoutes.EVENT_REDEEM_TOKEN]: {
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: EVENT_REDEEM_TOKEN_PATH,
		component: OverviewPage,
		needsRole: HackingLabRole.ROLE_BUG_BOUNTY_HUNTER,
	},
	[BugBountyRoutes.EVENT]: {
		title: "navigation.entries.bugBounty.event",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.bugBounty.root"),
		path: EVENT_PATH,
		pathParameter: { eventId: number },
		iconColor: "purple",
		icon: BugReportIcon,
		component: BugBountyEvent,
		hasSidebarLink: false,
		needsRole: HackingLabRole.ROLE_BUG_BOUNTY_HUNTER,
		breadcrumb: BugBountyEventBreadCrumb,
	},
	[BugBountyRoutes.CREATE_NEW_BUG]: {
		title: "navigation.entries.bugBounty.createNewBug",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.bugBounty.root"),
		path: CREATE_NEW_BUG_PATH,
		iconColor: "purple",
		icon: BugReportIcon,
		component: BugBountyBugReport,
		hasSidebarLink: false,
		needsRole: HackingLabRole.ROLE_BUG_BOUNTY_HUNTER,
		breadcrumb: BugBountyBugReportBreadCrumb,
	},
	[BugBountyRoutes.BUG_REPORT]: {
		title: "navigation.entries.bugBounty.bugReport",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.bugBounty.bugReport"),
		path: BUG_REPORT_PATH,
		iconColor: "purple",
		icon: BugReportIcon,
		component: BugBountyBugReport,
		hasSidebarLink: false,
		needsRole: HackingLabRole.ROLE_BUG_BOUNTY_HUNTER,
		breadcrumb: BugBountyBugReportBreadCrumb,
	},
};

export type BugBountyRootRouteParams = ExtractRouteParams<typeof ROOT_PATH, string>;
export type BugBountyCreateNewBugRouteParams = ExtractRouteParams<typeof CREATE_NEW_BUG_PATH, string>;
export type BugBountyEventRouteParams = ExtractRouteParams<typeof EVENT_PATH, string>;
export type BugBountyBugReportRouteParams = ExtractRouteParams<typeof BUG_REPORT_PATH, string>;
