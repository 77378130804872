import { Dto, RemoteResourceStateData, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";

import { RemoteResource, RemoteResourcesState } from "models/RemoteResource";
import { RemoteResourceAction, RemoteResourceActionType } from "redux/actions";

const defaultInitState = {
	status: RemoteResourceStatus.INITIALIZED,
	data: undefined,
};

const defaultArrayInitState = {
	status: RemoteResourceStatus.INITIALIZED,
	data: [],
};


export const initState: RemoteResourcesState = {
	remoteResources: {
		[RemoteResource.TENANT_ACCESS_POLICY]: { ...defaultInitState },
		[RemoteResource.TENANT_CONFIGURATION_ACCESS_POLICY]: { ...defaultInitState },
		[RemoteResource.TENANT_ADMIN_CONFIGURATION]: { ...defaultInitState },
		[RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL]: { ...defaultArrayInitState },
		[RemoteResource.USER_PROFILE]: { ...defaultInitState },
		[RemoteResource.EVENT_TERMS]: { ...defaultArrayInitState },
		[RemoteResource.TERM_AGREEMENTS]: { ...defaultArrayInitState },
		[RemoteResource.EVENT_PARTICIPANTS_USERS]: { ...defaultArrayInitState },
		[RemoteResource.EVENT_PARTICIPANTS_TEAMS]: { ...defaultArrayInitState },
		[RemoteResource.EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS]: { ...defaultArrayInitState },
		[RemoteResource.EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS]: { ...defaultArrayInitState },
		[RemoteResource.EVENT_PRE_JOIN_INFO]: { ...defaultArrayInitState },
		[RemoteResource.BUG_BOUNTY_EVENTS]: { ...defaultArrayInitState },
		[RemoteResource.BUG_BOUNTY_REPORTS]: { ...defaultArrayInitState },
	},
};

export const remoteResourceReducer = (state = initState, action?: RemoteResourceAction): RemoteResourcesState => {
	if (!action || !action?.resource) {
		return state;
	}
	switch (action.type) {
		case RemoteResourceActionType.REMOTE_RESOURCE_REQUEST:
			return {
				...state,
				remoteResources: {
					...state.remoteResources,
					[action.resource]: {
						...state.remoteResources[action.resource],
						status: RemoteResourceStatus.LOADING,
					},
				},
			};
		case RemoteResourceActionType.REMOTE_RESOURCE_RESULT:
			return {
				...state,
				remoteResources: {
					...state.remoteResources,
					[action.resource]: {
						data: mergeData(
							state.remoteResources[action.resource].data,
							action.payload,
						),
						status: RemoteResourceStatus.LOADED,
					},
				},
			};
		case RemoteResourceActionType.REMOTE_RESOURCE_REMOVE:
			return {
				...state,
				remoteResources: {
					...state.remoteResources,
					[action.resource]: {
						data: removeData(
							state.remoteResources[action.resource].data,
							action.payload,
						),
						status: RemoteResourceStatus.LOADED,
					},
				},
			};
		case RemoteResourceActionType.REMOTE_RESOURCE_CLEAR:
			return {
				...state,
				remoteResources: {
					...state.remoteResources,
					[action.resource]: {
						data: undefined,
						status: RemoteResourceStatus.LOADED,
					},
				},
			};
		default:
			return state;
	}
};

const removeData = (currentData?: RemoteResourceStateData, dataToRemove?: RemoteResourceStateData) => {
	if (!dataToRemove) {
		return currentData;
	}

	if (!currentData || !Array.isArray(currentData)) {
		return undefined;
	}

	return removeDtoFromArray(currentData, dataToRemove);

};

const removeDtoFromArray = (currentDtos: Dto[], dataToRemove: RemoteResourceStateData): Dto[] => {
	if (!Array.isArray(dataToRemove)) {
		if (!dataToRemove.id) {
			// impossible to remove if no id is provided
			return currentDtos;
		}

		return currentDtos.filter(data => data.id !== dataToRemove.id);
	}

	return currentDtos.filter(data => !dataToRemove.find(dto => dto.id === data.id));
};

const mergeData = (currentData?: RemoteResourceStateData, newData?: RemoteResourceStateData) => {
	if (!newData) {
		return currentData;
	}

	if (!currentData || !Array.isArray(currentData)) {
		// data is not stored as an array replace with current data
		return newData;
	}

	return mergeDtoIntoArray(currentData, newData);
};

const mergeDtoIntoArray = (currentDtos: Dto[], newData: RemoteResourceStateData): Dto[] => {
	if (!Array.isArray(newData)) {
		if (!newData.id) {
			// impossible to merge if no id is provided
			return [ newData ];
		}

		return [
			...currentDtos.filter(data => data.id !== newData.id),
			newData,
		];
	}

	return [
		...currentDtos.filter(data => !newData.find(dto => dto.id === data.id)),
		...newData,
	];
};
