import React, { Dispatch } from "react";

import { fetchTeacherEventUnits } from "actions/teacher";
import { BulkSolutionCommentSubmissionDto, postTeacherSolutionCommentBulk } from "grading/bulkActionModal.action";
import { TeacherEventUnit } from "shared/event/model/TeacherEventUnit";

export const submitModal = (
	submissionDto: BulkSolutionCommentSubmissionDto,
	setSubmitting: (arg0: boolean) => void,
	dispatch: Dispatch<any>,
	unit: TeacherEventUnit,
	onClose: () => void,
) => () => {
	setSubmitting(true);
	dispatch(
		postTeacherSolutionCommentBulk(
			unit.id,
			submissionDto,
			() => {
				setSubmitting(false);
			},
			() => {
				onClose();
				setSubmitting(false);
				dispatch(fetchTeacherEventUnits(unit.eventId));
			},
			() => {
				setSubmitting(false);
			},
		),
	);
};

export const onParticipantChange = (setter:  React.Dispatch<React.SetStateAction<number[]>>) => (event: any) => {
	setter(event.target.value.map((id: string) => parseInt(id)));
};

export const exportEvent = (unit: TeacherEventUnit) => () => {
	window.open(`/api/events/${unit.eventId}/export/`, "_blank");
};
