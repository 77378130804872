import { Page } from "@hlcr/core";
import { useIntl } from "@hlcr/ui/Intl";
import { Button, Card, CardActions, CardContent, createStyles, IconButton, LinearProgress, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef, GridOverlay, GridRowId, GridRowParams, GridSelectionModelChangeParams, GridValueGetterParams } from "@material-ui/data-grid";
import { DeleteOutlined, DraftsOutlined, MailOutlined } from "@material-ui/icons";
import * as React from "react";
import { useDispatch } from "react-redux";

import { ConfirmationDialog } from "dialog/ConfirmationDialog";
import { formatFullDate } from "helper/dateCalc";
import inboxApi from "messaging/actions";
import { getSubjectOfMessage, MessageReceived, Sender } from "messaging/model";

const getFullName = (params: GridValueGetterParams) => {
	const sender = params.value as Sender;

	const name = `${sender.firstName || ""} ${sender.lastName || ""}`.trim();
	return name ? `${name} (${sender.username})` : sender.username;
};

interface InboxMessageListProps {
	setSelectedMessage: (message?: MessageReceived) => void;
}


function CustomLoadingOverlay() {
	return (
		<GridOverlay>
			<div style={{ position: "absolute", top: 0, width: "100%" }}>
				<LinearProgress />
			</div>
		</GridOverlay>
	);
}

export const InboxMessageList = ({ setSelectedMessage }: InboxMessageListProps) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const classes = useStyles();

	const [ currentMessage, setCurrentMessage ] = React.useState<MessageReceived>();
	const [ topmostSelectedMessageIndex, setTopmostSelectedMessageIndex ] = React.useState<number | undefined>();
	const [ messages, setMessages ] = React.useState<MessageReceived[]>([]);
	const [ page, setPage ] = React.useState(0);
	const [ pageSize, setPageSize ] = React.useState(10);
	const [ rowCount, setRowCount ] = React.useState(0);
	const [ loading, setLoading ] = React.useState<boolean>(false);
	const [ selectionModel, setSelectionModel ] = React.useState<GridRowId[]>([]);
	const [ markAllDialogOpen, setMarkAllDialogOpen ] = React.useState<boolean>(false);
	const [ deleteAllDialogOpen, setDeleteAllDialogOpen ] = React.useState<boolean>(false);
	const [ mouseInColumnHeaderElement, setMouseInColumnHeaderElement ] = React.useState<boolean>(false);

	const handleOnRowClick = (params: GridRowParams) => {
		setCurrentMessage(params.row as MessageReceived);
		setSelectionModel([ params.id ]);
	};

	// const handleOnRowSelected = (params: GridRowSelectedParams) => {
	// 	if (params.isSelected) {
	// 		setCurrentMessage(params.data as MessageReceived);
	// 	}
	// };

	const invertHeaderCheckboxSelection = (newSelectionModel: GridSelectionModelChangeParams) =>
		newSelectionModel.selectionModel.length === 0
			&& selectionModel.length > 0
			&& selectionModel.length < Math.min(messages.length, pageSize)
			&& mouseInColumnHeaderElement
			? messages.slice(0, Math.min(messages.length, pageSize)).map(message => message.id)
			: newSelectionModel.selectionModel;

	const handleSelectionChange = (newSelectionModel: GridSelectionModelChangeParams) => {
		newSelectionModel.selectionModel = invertHeaderCheckboxSelection(newSelectionModel);

		setSelectionModel(newSelectionModel);

		if (newSelectionModel.length === 0) {
			setCurrentMessage(undefined);
			setTopmostSelectedMessageIndex(undefined);
		} else {
			if (newSelectionModel.length === 1) {
				setCurrentMessage(messages.find(message => message.id === newSelectionModel[0]));
			}
			setTopmostSelectedMessageIndex(newSelectionModel.reduce(
				(minIndex: number, currentUuid: GridRowId) => Math.min(messages.findIndex(message => message.id === currentUuid), minIndex),
				messages.length,
			));
		}
	};

	const getRowClassName = (params: GridRowParams) => {
		const rowClassNames = [];

		rowClassNames.push(`hlcr--inbox-${params.row.isRead ? "read" : "unread"}`);

		if (currentMessage === params.row) {
			rowClassNames.push("hlcr--inbox-current");
		}

		return rowClassNames.join(" ");
	};

	const handlePageChange = (newPage: number) => {
		setPage(newPage);
	};

	const handlePageSizeChange = (newPageSize: number) => {
		const firstElement = page * pageSize + 1;
		setPage(Math.floor(firstElement / newPageSize));
		setPageSize(newPageSize);
	};

	const handleOnPageResultReceived = (inboxMessagePage: Page<MessageReceived>) => {
		setMessages(inboxMessagePage.content);
		setRowCount(inboxMessagePage.totalElements);

		if (inboxMessagePage.content.length > 0 && (!currentMessage || !inboxMessagePage.content.includes(currentMessage))) {
			let initialMessage;
			if (!currentMessage || !topmostSelectedMessageIndex) {
				initialMessage = inboxMessagePage.content[0];
			} else {
				initialMessage = inboxMessagePage.content[Math.min(topmostSelectedMessageIndex, inboxMessagePage.content.length - 1)];
			}
			setCurrentMessage(initialMessage);
			setSelectionModel([ initialMessage.id ]);
		} else {
			setCurrentMessage(undefined);
			setSelectionModel([]);
		}

		setLoading(false);
	};

	const handleDelete = (messageIds: Array<string | number>) => {
		dispatch(inboxApi.archiveMessages(messageIds, reloadMessages));
	};

	const handleMarkRead = (messageIds: Array<string | number>) => {
		dispatch(inboxApi.markMessagesAsRead(messageIds, reloadMessages));
	};

	const renderActionsCell = (params: GridCellParams) => {
		const rowMessage = params.row as MessageReceived;

		const handleSingleDelete = () => handleDelete([ rowMessage.id ]);
		const handleMarkAsRead = () => handleMarkRead([ rowMessage.id ]);
		const handleMarkAsUnread = () => dispatch(inboxApi.markMessagesAsUnread([ rowMessage.id ], reloadMessages));

		return (<>
			{
				rowMessage.isRead ?
						<Tooltip title={"Mark as unread"}>
							<IconButton onClick={handleMarkAsUnread}>
								<MailOutlined />
							</IconButton>
						</Tooltip>
					: <Tooltip title={"Mark as read"}>
							<IconButton onClick={handleMarkAsRead}>
								<DraftsOutlined />
							</IconButton>
						</Tooltip>
			}
			<Tooltip title={"Delete"}>
				<IconButton onClick={handleSingleDelete}>
					<DeleteOutlined />
				</IconButton>
			</Tooltip>
		</>);
	};

	const columns: GridColDef[] = [
		{
			field: "sender",
			headerName: intl.fm("user.inbox.header.sender"),
			width: 160,
			valueGetter: getFullName,
			filterable: false,
			sortable: false,
		},
		{
			field: "receivedDateTime",
			headerName: intl.fm("user.inbox.header.receivedDateTime"),
			width: 150,
			type: "dateTime",
			filterable: false,
			sortable: false,
			valueFormatter: (params) => formatFullDate(Date.parse(params.value as string)),
		},
		{
			field: "subject",
			headerName: intl.fm("user.inbox.header.subject"),
			flex: 1,
			filterable: false,
			sortable: false,
			valueGetter: params => getSubjectOfMessage(params.row as MessageReceived, intl),
		},
		{
			field: "",
			headerName: intl.fm("user.inbox.header.actions"),
			width: 120,
			filterable: false,
			sortable: false,
			renderCell: renderActionsCell,
		},
	];

	const reloadMessages = () => {
		setLoading(true);
		dispatch(inboxApi.fetchInboxMessages(page, pageSize, handleOnPageResultReceived));
		dispatch(inboxApi.fetchUnreadMessages());
	};

	const allSelected = () => {
		return selectionModel.length === pageSize || selectionModel.length === rowCount;
	};

	const handleDeleteSelectedMessages = () => {
		if (allSelected()) {
			setDeleteAllDialogOpen(true);
		} else {
			handleDelete(selectionModel);
		}
	};

	const handleToggleReadSelectedMessages = () => {
		if (allSelected()) {
			setMarkAllDialogOpen(true);
		} else {
			handleMarkRead(selectionModel);
		}
	};

	const onMarkAllDialogClose = (hasClickedYes: boolean) => {
		setMarkAllDialogOpen(false);
		handleMarkRead(hasClickedYes ? messages.map(m => m.id) : selectionModel);
	};

	const onDeleteAllDialogClose = (hasClickedYes: boolean) => {
		setDeleteAllDialogOpen(false);
		handleDelete(hasClickedYes ? messages.map(m => m.id) : selectionModel);
	};

	React.useEffect(() => {
		setSelectedMessage(currentMessage);
	}, [ setSelectedMessage, currentMessage ]);

	React.useEffect(() => {
		reloadMessages();
	}, [ page, pageSize ]);

	return (
		<>
			<Card>
				<CardActions style={{ justifyContent: "flex-end" }}>
					{`${selectionModel.length} ${intl.fm("user.inbox.selectedMessages")}`}
					<Button disabled={selectionModel.length === 0} onClick={handleToggleReadSelectedMessages}>
						<DraftsOutlined /> Mark as read
					</Button>
					<Button disabled={selectionModel.length === 0} onClick={handleDeleteSelectedMessages}>
						<DeleteOutlined /> Delete
					</Button>
				</CardActions>
				<CardContent>
					<Typography variant={"h5"}>{intl.fm("user.inbox.title")}</Typography>
					<div style={{ height: 800, width: "100%" }} className={classes.root}>
						<DataGrid
							disableColumnFilter={true}
							rows={messages}
							columns={columns}
							pagination={true}
							pageSize={pageSize}
							page={page}
							rowCount={rowCount}
							checkboxSelection={true}
							disableSelectionOnClick={true}
							onRowClick={handleOnRowClick}
							// onRowSelected={handleOnRowSelected}
							rowsPerPageOptions={[ 5, 10, 25 ]}
							paginationMode={"server"}
							onPageChange={handlePageChange}
							onPageSizeChange={handlePageSizeChange}
							loading={loading}
							components={{ LoadingOverlay: CustomLoadingOverlay }}
							getRowClassName={getRowClassName}
							selectionModel={selectionModel}
							onSelectionModelChange={handleSelectionChange}
							onColumnHeaderEnter={() => setMouseInColumnHeaderElement(true)}
							onColumnHeaderLeave={() => setMouseInColumnHeaderElement(false)}
						/>
					</div>
				</CardContent>
			</Card>
			{markAllDialogOpen &&
				<ConfirmationDialog
					message={intl.fm("user.inbox.markAll.question")}
					onClose={(hasClickedYes) => onMarkAllDialogClose(hasClickedYes)}
					yesButtonLabel={intl.fm("user.inbox.markAll.labelYes")}
					noButtonLabel={intl.fm("user.inbox.markAll.labelNo")}></ConfirmationDialog>
			}
			{deleteAllDialogOpen &&
				<ConfirmationDialog
					message={intl.fm("user.inbox.deleteAll.question")}
					onClose={(hasClickedYes) => onDeleteAllDialogClose(hasClickedYes)}
					yesButtonLabel={intl.fm("user.inbox.deleteAll.labelYes")}
					noButtonLabel={intl.fm("user.inbox.deleteAll.labelNo")}></ConfirmationDialog>
			}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .inbox.unread": { fontWeight: theme.typography.fontWeightBold },
			"& .hlcr--inbox-unread": { fontWeight: theme.typography.fontWeightBold },
			"& .hlcr--inbox-current": { backgroundColor: "rgba(63, 81, 181, 0.2)!important;" },
		},
	}),
);
