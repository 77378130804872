import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { containerFluid, drawerMiniWidth, drawerWidth, EASE_TRANSITION, transition } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import cx from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import { fetchBranding } from "actions/branding";
import { fetchTenantAccessPolicy } from "actions/tenantAccessPolicy";
import logoMiniBottom from "assets/img/logo-small.png";
import logoBottom from "assets/img/logo.png";
import image from "assets/img/sidebar-2.jpg";
import { checkDenyRoles, checkHasAnyRole, checkHasRole } from "auth/authUtils";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { checkUserProfileRequirementPolicyViolation } from "helper/policy";
import usePersistedState from "helper/usePersistedState";
import Header from "layouts/Header";
import Sidebar from "layouts/Sidebar";
import { RemoteResource } from "models/RemoteResource";
import { TenantAccessPolicy } from "models/TenantAccessPolicy";
import { UserProfile } from "models/User";
import { RootState } from "reducers";
import { dashboardRoutes, PROFILE_PATH } from "routes/dashboardRoutes";
import { fetchPersonalProfile } from "userProfile/actions";

function mapRoutes(routes: HackingLabRoute[], tenantAccessPolicyViolated: boolean): ReactNode {
	return routes.map((route, index) => {
		if (route.type === HackingLabRouteType.COLLAPSIBLE_ROUTE && route.routes) {
			return mapRoutes(route.routes, tenantAccessPolicyViolated);
		}

		if (route.type === HackingLabRouteType.COMPONENT_ROUTE) {
			if (route.needsRole && !checkHasRole(route.needsRole)) {
				return (
					<Route
						component={() => <NoData text="Access Denied" Icon={BlockIcon} />}
						exact={true}
						key={index}
						path={route.path}
					/>
				);
			}

			if (route.checkAccessPolicy && tenantAccessPolicyViolated) {
				const from = Array.isArray(route.path) ? route.path[0] : route.path;
				return <Redirect from={from} to={PROFILE_PATH} key={index} />;
			}

			return <Route exact={true} path={route.path} component={route.component} key={index} />;
		}

		if (route.type === HackingLabRouteType.REDIRECT_ROUTE) {
			if (route.roleMapping) {
				for (const mapping of route.roleMapping) {
					const hlRole = mapping.role;
					const redirectTo = mapping.redirectTo;

					if (hlRole && checkHasRole(hlRole)) {
						return <Redirect from={route.from} to={redirectTo} key={index} />;
					}
				}
			}
			return <Redirect from={route.from} to={route.to} key={index} />;
		}
	});
}


interface DashboardProps {
}

export const Dashboard = ({ history, location }: RouteComponentProps<DashboardProps>) => {

	const classes = useStyles();
	const mainPanelRef = useRef(null);

	const dispatch = useDispatch();


	const appLogo: string = useSelector<RootState, string>(state => state.branding.appLogo);
	const appLogoSmall: string = useSelector<RootState, string>(state => state.branding.appLogoSmall);
	const websiteName: string = useSelector<RootState, string>(state => state.branding.websiteName);
	const realm: string = useSelector<RootState, string>(state => state.branding.realm);
	const legalNoticeUrl: string = useSelector<RootState, string>(state => state.branding.legalNoticeUrl);
	const privacyPolicyUrl: string = useSelector<RootState, string>(state => state.branding.privacyPolicyUrl);

	const tenantAccessPolicyState = useSelector<RootState, RemoteResourceState<TenantAccessPolicy>>(state => state.remoteResourceReducer.remoteResources[RemoteResource.TENANT_ACCESS_POLICY]);
	const userProfileState = useSelector<RootState, RemoteResourceState<UserProfile>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.USER_PROFILE]);


	const [ isMobileOpen, setMobileOpen ] = useState(false);
	const [ isMiniActive, setMiniActive ] = useState(false);
	const [ isFixed, setFixed ] = usePersistedState("Layout::FixedLayout", true);
	const [ tenantAccessPolicyViolated, setTenantAccessPolicyViolated ] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (userProfileState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchPersonalProfile());
		}
	}, [ userProfileState ]);

	useEffect(() => {
		if (tenantAccessPolicyState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchTenantAccessPolicy());
		}
	}, [ tenantAccessPolicyState ]);

	useEffect(() => {
		dispatch(fetchBranding());
	});

	useEffect(() => {
		if (history.location.pathname !== location.pathname && mainPanelRef?.current) {
			// mainPanelRef.current.scrollTop = 0;
		}
	}, [ history.location.pathname, location.pathname ]);

	useEffect(() => {
		if (tenantAccessPolicyState.status === RemoteResourceStatus.LOADED && userProfileState.status === RemoteResourceStatus.LOADED) {
			setTenantAccessPolicyViolated(
				checkUserProfileRequirementPolicyViolation(
					tenantAccessPolicyState.data?.userProfileRequirements,
					userProfileState.data,
				),
			);
		}
	}, [ tenantAccessPolicyState.status, userProfileState.status ]);

	const toggleDrawer = () => setMobileOpen(!isMobileOpen);

	const toggleMinimizeSidebar = () => setMiniActive(!isMiniActive);

	if (tenantAccessPolicyViolated === undefined) {
		return <LoadingSpinner />;
	}

	return (
		<div className={classes.wrapper}>
			<Helmet>
				<title>{websiteName}</title>
			</Helmet>
			<Sidebar
				routes={dashboardRoutes
					.filter(route => route.type !== HackingLabRouteType.REDIRECT_ROUTE
						&& route.hasSidebarLink
						&& checkHasRole(route.needsRole)
						&& checkHasAnyRole(route.needsAnyRole)
						&& checkDenyRoles(route.denyIfAnyRole),
					)
				}
				logo={appLogo}
				logoMini={appLogoSmall}
				logoBottom={logoBottom}
				logoMiniBottom={logoMiniBottom}
				image={image}
				handleDrawerToggle={toggleDrawer}
				open={isMobileOpen}
				color="blue"
				bgColor="black"
				miniActive={isMiniActive}
				sidebarMinimize={toggleMinimizeSidebar}
				realm={realm}
				location={location}
			/>
			<div className={cx(classes.fluidWrapper, { [classes.fluidWrapperSidebarMini]: isMiniActive })}>
				<div className={cx(classes.mainPanel, { [classes.mainPanelSidebarMini]: isMiniActive, [classes.fixedWidth]: isFixed })} ref={mainPanelRef}>
					<Header
						// miniActive={isMiniActive}
						routes={dashboardRoutes}
						handleDrawerToggle={() => setMobileOpen(!isMobileOpen)}
						location={location}
						isFixed={isFixed}
						toggleFixedLayout={() => setFixed(!isFixed)}
					/>
					<div className={classes.content}>
						<div className={classes.container}>
							<Switch>{mapRoutes(dashboardRoutes, tenantAccessPolicyViolated)}</Switch>
						</div>
						<div className={classes.footer}>
							<a href="https://www.compass-security.com/en/imprint" target={"_blank"} rel="noopener noreferrer" className={classes.footerLink}>Imprint</a>
							<span className={classes.footerSeparator}>•</span>
							<a href="https://www.compass-security.com/en/legal/privacy-policy" target={"_blank"} rel="noopener noreferrer" className={classes.footerLink}>Privacy Policy</a>
							<span className={classes.footerSeparator}>•</span>
							<a href="https://bugbounty.compass-security.com/bug-bounty-terms-and-conditions" target="_blank" rel="noopener noreferrer" className={classes.footerLink}>Bug Bounty Terms and Conditions</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles(
		{
			wrapper: {
				position: "relative",
				top: "0",
				height: "100vh",
				"&:after": {
					display: "table",
					clear: "both",
					content: "\" \"",
				},
			},
			fluidWrapper: {
				display: "flex",
				justifyContent: "center",
				transition: EASE_TRANSITION,
				[theme.breakpoints.up("md")]: { marginLeft: drawerWidth },
			},
			mainPanel: {
				transitionProperty: "top, bottom, width",
				transitionDuration: ".2s, .2s, .35s",
				transitionTimingFunction: "linear, linear, ease",
				[theme.breakpoints.up("md")]: { width: `calc(100% - ${drawerWidth}px)` },
				overflow: "auto",
				position: "relative",
				float: "right",
				...transition,
				maxHeight: "100%",
				width: "100%",
				overflowScrolling: "touch",
				flexGrow: 1,
			},
			fixedWidth: { maxWidth: 1440 },
			content: {
				display: "flex",
				flexDirection: "column",
				alignItems: "stretch",
				justifyContent: "space-between",
				marginTop: "85px",
				padding: "0 15px 10px 15px",
				minHeight: "calc(100vh - 85px)",
				"@media (max-width: 600px)": {
					marginTop: 60,
					paddingLeft: 0,
					paddingRight: 0,
				},
			},
			container: {
				...containerFluid,
				marginLeft: "inherit",
				marginRight: "inherit",
			},
			footer: { textAlign: "center" },
			footerLink: {
				color: "#999",
				textDecoration: "none",
				"&:hover, &:focus": {
					color: "#333",
					textDecoration: "underline",
				},
			},
			footerSeparator: {
				padding: "0 8px",
				color: "#bbb",
			},
			map: { marginTop: "70px" },
			fluidWrapperSidebarMini: { [theme.breakpoints.up("md")]: { marginLeft: drawerMiniWidth } },
			mainPanelSidebarMini: { [theme.breakpoints.up("md")]: { width: `calc(100% - ${drawerMiniWidth}px)` } },
		},
	),
);
