import { Accordion } from "@hlcr/mui";
import { WithIntl, withIntl } from "@hlcr/ui/Intl";
import { WithStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";

import { formatFullLongDate } from "helper/dateCalc";

interface UserFlag {
	timestamp: string,
	flag: string
}

export interface UserFlagsBoxProperties extends WithStyles, WithIntl {
	flags: UserFlag[];
}

const UserFlagsBox = (props: UserFlagsBoxProperties) => {
	const { flags, intl, classes } = props;

	return flags && flags.length > 0 ? (
		<Accordion sections={[
			{
				title: intl.fm("solution.gradinginstruction.userFlag"),
				content: (<List>
					{flags.map((flag, index) =>
						<ListItem key={index} className={classes.listItem}>
							<ListItemText
								classes={{
									primary: classes.listItemTextPrimary,
									secondary: classes.listItemTextSecondary,
								}}
								primary={flag.flag}
								secondary={formatFullLongDate(flag.timestamp, intl.fm("common.labels.unknown"))} />
						</ListItem>,
					)}
				</List>),
			},
		]} />
	) : null;
};

export default compose(
	withStyles({
		listItem: { padding: 0 },
		listItemTextPrimary: { fontSize: "1em", fontWeight: 500 },
		listItemTextSecondary: { fontSize: "12px" },
	}),
	withIntl,
)(UserFlagsBox);
