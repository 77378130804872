import { percentage } from "@hlcr/core/numeric";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "actions/teacher";
import teacherSolutionStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teacherSolutionStyle";
import InfoCard from "components/Cards/InfoCard";
import RegularCard from "components/Cards/RegularCard";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import NoData from "components/NoData/NoData";
import HiddenContentSlider from "components/Slider/HiddenContentSlider";
import { SolutionHistory } from "components/Solution/SolutionHistory";
import SolutionSubmitDialog from "components/Solution/SolutionSubmitDialog";
import UserFlagsBox from "components/Solution/UserFlagsBox";
import { formatFullDate } from "helper/dateCalc";
import { createMemoize } from "helper/memoize";
import { withIntl } from "@hlcr/ui/Intl";
import { getSolutionState, getSolutionSuccessStateBySolution, getSolutionSuccessStateEnum, SolutionState } from "models/SolutionState";
import { StyledMarkdown } from "@hlcr/ui";

class TeacherSolutionRaw extends React.Component {
	componentDidMount() {
		const { solutionId, fetchTeacherSolutions, eventId } = this.props;
		if (fetchTeacherSolutions) fetchTeacherSolutions(eventId);
		if (solutionId) this.fetchDataUpdate();
	}

	componentDidUpdate(prevProps) {
		const { solutionId } = this.props;
		if (prevProps.solutionId !== solutionId) {
			this.fetchDataUpdate();
		}
	}

	fetchDataUpdate() {
		const { fetchTeacherSolution, fetchTeacherSolutionUserFlags, solutionId } = this.props;
		fetchTeacherSolution(solutionId);
		if (fetchTeacherSolutionUserFlags) fetchTeacherSolutionUserFlags(solutionId);
	}

	render() {
		const { solution, intl, classes } = this.props;

		if (!solution) {
			return <NoData />;
		}

		const displayName = (solution.user.firstName || solution.user.lastName)
			? `${solution.user.firstName || "?"} ${solution.user.lastName || "?"} (${solution.user.username})`
			: solution.user.username;

		return (
			<div>
				<h3>
					{intl.fm("teacher.solution.title")}{" "}
					{displayName}
				</h3>
				<div className={classes.topInfoCards}>
					<GradingStatus {...this.props} />
					<ChallengeInfo {...this.props} />
					<TimesStatus {...this.props} />
				</div>
				<div>
					<Panel {...this.props} />
				</div>
			</div>
		);
	}
}

class Panel extends React.Component {
	state = {
		isModalOpen: false,
		writeupGrade: 0,
		disableMarkdown: true,
	};

	render() {
		const {
			solutionId,
			eventId,
			solution: { challenge, flagPoints },
			nextSolutionId,
			solutionComments,
			fetchTeacherSolution,
			gradingInstruction,
			userFlags,
			postTeacherSolutionComment,
			disabled,
			intl,
			classes,
			darkMode,
		} = this.props;

		const { isModalOpen, writeupGrade, disableMarkdown } = this.state;

		const onSuccess = () => fetchTeacherSolution(solutionId);

		return (
			<React.Fragment>
				<RegularCard
					content={
						<div className={classes.panelHeightFiller}>
							<div className={classes.panelWithInfo}>
								<div className={classes.solutionHistory}>
									<InputLabel className={classes.historyMarkdownLabel}>
										{intl.fm("solution.history.markdown.disable")}
										<CustomSwitch checked={disableMarkdown} onChange={this.switchDisableMarkdown} />
									</InputLabel>
									<SolutionHistory
										maxPoints={challenge ? challenge.maxPoints : 0}
										isTeacher
										solutionComments={solutionComments}
										isDisabled={disabled}
										disableMarkdown={disableMarkdown}
										openSolutionModal={this.openModal}
										submitGrading={this.submitGrading}
									/>
								</div>
								<HiddenContentSlider openLabel={intl.fm("solution.gradinginstruction.open")} closeLabel={intl.fm("solution.gradinginstruction.close")}>
									<h4>{intl.fm("solution.gradinginstruction.title")}</h4>
									<UserFlagsBox flags={userFlags} />
									{gradingInstruction ? (
										<StyledMarkdown source={gradingInstruction.content} darkMode={darkMode} />
									) : (
										<NoData />
									)}
								</HiddenContentSlider>
							</div>
						</div>
					}
				/>
				{postTeacherSolutionComment && challenge && (
					<SolutionSubmitDialog
						eventUnit={challenge}
						eventId={eventId}
						challengeId={challenge.id}
						maxPoints={!isNaN(challenge.maxPoints) ? challenge.maxPoints : 0}
						flagPoints={!isNaN(flagPoints) ? flagPoints : 0}
						writeupWeight={!isNaN(challenge.writeupWeight) ? challenge.writeupWeight : 0}
						solutionId={solutionId}
						nextSolutionId={nextSolutionId}
						isOpen={isModalOpen}
						writeupGrade={writeupGrade}
						isTeacher
						onSubmit={postTeacherSolutionComment}
						onSuccess={onSuccess}
						closeModal={this.closeModal}
						lastSolutionComment={getLastUserSolutionComment(solutionComments)}
						lastTeacherSolutionComment={getLastTeacherSolutionComment(
							solutionComments,
						)}
						userFlags={userFlags}
					/>
				)}
			</React.Fragment>
		);
	}

	switchDisableMarkdown = () => this.setState(({ disableMarkdown }) => ({ disableMarkdown: !disableMarkdown }));

	closeModal = () => this.setState({ isModalOpen: false });

	openModal = percentage =>
		this.setState({
			              isModalOpen: true,
			              writeupGrade: percentage ?? (isNaN(this.props.solution.writeupGrade) ? 1 : this.props.solution.writeupGrade),
		              });

	submitGrading = (percentage) => {
		const {
			solutionId,
			postTeacherSolutionComment,
			fetchTeacherSolution,
			nextSolutionId,
			eventId,
			history,
		} = this.props;

		let additionalCallback;
		if (nextSolutionId) {
			additionalCallback = () => history.push(`/teacher/events/${eventId}/solutions/${nextSolutionId}`);
		} else {
			additionalCallback = () => history.push(`/teacher/events/${eventId}`);
		}

		postTeacherSolutionComment(
			{
				comment: "",
				grade: percentage,
			},
			solutionId,
			() => {
				fetchTeacherSolution(solutionId);
				additionalCallback();
			},
		);
	};
}

const getLastTeacherSolutionComment = solutionComments => {
	return getLastSolutionComment(
		solutionComments.filter(sc => (sc.writeupGrade !== undefined || sc.comment) && sc.user?.username !== "system"),
	);
};
const getLastUserSolutionComment = solutionComments => {
	return getLastSolutionComment(
		solutionComments.filter(sc => (sc.writeup ?? "" !== "") || sc.attachment),
	);
};

const getLastSolutionComment = solutionComments => {
	return solutionComments.sort((a, b) => moment(a.creationTime).isBefore(b.creationTime) ? 1 : -1)[0];
};

const TimesStatus = ({ solutionComments, intl }) => {
	const lastComment = getLastSolutionComment(solutionComments);
	const footer = lastComment
		? intl.fm("teacher.solution.lastUpdate", null, { date: formatFullDate(lastComment.creationTime) })
		: intl.fm("teacher.solution.noTeacherComment");

	const lastUserComment = getLastUserSolutionComment(solutionComments);
	const lastUserSolution = lastUserComment
		? intl.fm("teacher.solution.lastUserSolution", null, { date: formatFullDate(lastUserComment.creationTime) })
		: intl.fm("teacher.solution.noUserComment");


	return (
		<InfoCard
			icon={AccessTimeIcon}
			iconColor="green"
			title={intl.fm("teacher.event.solution.times")}
			description={<small>{lastUserSolution}</small>}
			footer={footer}
		/>
	);
};

const ChallengeInfo = ({ solution: { challenge }, intl, eventId }) => (
	<InfoCard
		icon={AssignmentTurnedInIcon}
		iconColor="purple"
		title={intl.fm("challenge.name")}
		description={challenge &&
		             <Link title={intl.fm("teacher.solution.previewChallenge")} target={"_blank"}
		                   href={`/teacher/events/${eventId}/units/${challenge.id}/challenge`}>{challenge.title}</Link>}
	/>
);

const GradingStatus = ({ solution, solutionComments, intl }) => {
	const { points, flagPoints, writeupPoints, stepsPenalty, challenge: { maxPoints, flagWeight, writeupWeight, maxFlagPoints, maxWriteupPoints } } = solution;

	const lastTeacherComment = getLastTeacherSolutionComment(solutionComments);
	const footer = lastTeacherComment
		? intl.fm("teacher.solution.lastGradingUpdate", null, { date: formatFullDate(lastTeacherComment.creationTime) })
		: intl.fm("teacher.solution.noTeacherComment");

	const solutionSuccessState = getSolutionSuccessStateEnum(getSolutionSuccessStateBySolution(solution));
	const solutionState = getSolutionState(solution.state);

	return (
		<InfoCard
			icon={solutionSuccessState.icon}
			iconColor={solutionSuccessState.iconColor}
			title={intl.fm(solutionState?.title)}
			description={
				<small>
					{flagWeight > 0 && intl.fm("teacher.solution.points.flag", null, { flagPoints, maxFlagPoints })}
					{flagWeight > 0 && writeupWeight > 0 && " + "}
					{writeupWeight > 0 && intl.fm("teacher.solution.points.writeup", null, { writeupPoints, maxWriteupPoints })}
					<strong> = {intl.fm("teacher.solution.points.total", null, { points: stepsPenalty > 0 ? flagPoints + writeupPoints : points, maxPoints })}</strong>
					{stepsPenalty > 0 && <div>
						{intl.fm("teacher.solution.points.stepsPenalty", null, { penalty: percentage(stepsPenalty) })}
						<strong> =&gt; {intl.fm("teacher.solution.points.total", null, { points, maxPoints })}</strong>
					</div>}
				</small>
			}
			footer={footer}
		/>
	);
};

const findSolution = createMemoize((solutions, solutionId) => solutions.find(solution => solution.id === solutionId));

const getNextSolutionId = createMemoize((solutions, solutionId) => {
	const submittedIds = solutions
		.filter(solution => solution.state === SolutionState.WAITING_FOR_TEACHER)
		.sort((a, b) => (moment(a.lastUserSolutionTime).isBefore(b.lastEdited) ? -1 : 1))
		.map(solution => solution.id);

	return submittedIds.length <= 1 ? undefined : submittedIds[(submittedIds.indexOf(solutionId) + 1) % submittedIds.length];
});

const filterBySolutionIdProperty = createMemoize((arr, solutionId) => arr.filter(obj => obj.solutionId === solutionId));

const findGradingInstructions = createMemoize((comments, solution) => comments.find(instruction => solution && solution.challenge && solution.challenge.id === instruction.id));



const mapStateToProps = (state, ownProps) => {
	const resources = state.api.resources;

	// if unitId is set, we filter the solutions according to the unitId
	const unitId = Number(ownProps.match.params.unitId);
	const solutionData = resources.teacherSolutions.data.filter(s => !unitId || s.challenge.id === unitId);

	// in case we open a unit, we don't have a solutionId yet
	let solutionId = Number(ownProps.match.params.solutionId);
	solutionId = solutionId || getNextSolutionId(solutionData, solutionId);
	const solution = solutionId ? findSolution(solutionData, solutionId) : null;

	const eventId = Number(ownProps.match.params.eventId);
	if (solution && solution.eventId && solution.eventId !== eventId) {
		ownProps.history.replace(
			`/teacher/events/${solution.eventId}/solutions/${solutionId}`,
		);
	}

	return {
		solutionId,
		eventId,
		solution,
		unitId,
		nextSolutionId: getNextSolutionId(solutionData, solutionId),
		solutionComments: filterBySolutionIdProperty(resources.teacherSolutionComments.data, solutionId),
		userFlags: filterBySolutionIdProperty(resources.teacherSolutionUserFlags.data, solutionId),
		gradingInstruction: findGradingInstructions(resources.teacherGradingInstruction.data, solution),
		isLoadingSolution: resources.teacherSolutions.pending,
		darkMode: state.ui.darkMode,
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTeacherSolutions: teacherApi.fetchTeacherSolutions,
			fetchTeacherSolution: teacherApi.fetchTeacherSolution,
			fetchTeacherSolutionUserFlags: teacherApi.fetchTeacherSolutionUserFlags,
			postTeacherSolutionComment: teacherApi.postTeacherSolutionComment,
		},
		dispatch,
	);

export const TeacherSolution = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(teacherSolutionStyle),
	withIntl,
)(TeacherSolutionRaw);

let TeacherSolutionBreadCrumb = ({ solution }) => (
	<span>
		{solution && solution.challenge ? solution.challenge.title : "Solution"}
	</span>
);

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const solutionId = Number(ownProps.match.params.solutionId);
	return {
		solution: state.api.resources.teacherSolutions.data.find(
			solution => solution.id === solutionId,
		),
	};
};

TeacherSolutionBreadCrumb = connect(mapStateToPropsBreadCrumbs)(
	TeacherSolutionBreadCrumb,
);

export { TeacherSolutionBreadCrumb, TeacherSolutionRaw };
