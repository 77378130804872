import { IntlWithFm } from "@hlcr/ui/Intl";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


export const BulkActionModalButtonBar = (
	{ intl, clearModal, selectedUserIds, selectedTeamIds, submitModal, isSubmitting }:
	{ intl: IntlWithFm, clearModal: () => void, selectedUserIds: number[], selectedTeamIds: number[], submitModal: () => void, isSubmitting: boolean }) => (
	<>
		<Button onClick={clearModal}>
			{intl.fm("common.labels.clearForm")}
		</Button>
		<Button disabled={!(selectedUserIds?.length > 0) && !(selectedTeamIds?.length > 0)} onClick={submitModal}>
			{isSubmitting ? (
				<CircularProgress size={15} />
			) : (
				intl.fm("common.labels.submit")
			)}
		</Button>
	</>
);
