import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import cx from "classnames";
import React from "react";

import enhancedTableStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/enhancedTableStyle";
import { EnhancedTableBase } from "components/Table/EnhancedTable";

const COLLAPSIBLE = "collapsible";
export const COLLAPSE_CELL = "COLLAPSE_CELL";

class EnhancedCollapsibleTable extends EnhancedTableBase {
	constructor(props) {
		super(props);
		this.state = { openCollapsible: this.getOpenCollapsible(), ...this.state };
	}

	getOpenCollapsible = () => {
		const openCollapsible = JSON.parse(localStorage.getItem(`${this.getPersistencePrefix()}::${COLLAPSIBLE}`));
		return openCollapsible ? openCollapsible : [];
	};

	toggleOpenCollapsible = (event, id) => {
		if(event) {
			event.stopPropagation();
		}
		const { openCollapsible } = this.state;
		const index = openCollapsible.findIndex(e => e === id);
		if (index === -1) {
			openCollapsible.push(id);
		} else {
			openCollapsible.splice(index, 1);
		}
		this.setState({ openCollapsible });
		return localStorage.setItem(`${this.getPersistencePrefix()}::${COLLAPSIBLE}`, JSON.stringify(openCollapsible));
	};

	renderTableRow = (rowIn, rowKey) => {
		const {
			classes,
			tableRenderer,
			collapsedTableRenderer,
			hideCollapsedTableHeader = true,
			toggleCollapseOnRow = false,
			hasCollapseProperties,
			extractCollapseProperties,
			disabledRow,
			hover,
			striped,
			persistSortFor
		} = this.props;

		if (tableRenderer) {
			const { openCollapsible, dimensions: { width } } = this.state;

			let rowColor = "";
			let rowColored = false;
			let row = rowIn;

			if (row.color !== undefined) {
				rowColor = row.color;
				rowColored = true;
				row = row.data;
			}
			const tableRowClasses = cx({
				[classes[rowColor + "Row"]]: rowColored,
				[classes.tableStripedRow]: striped && rowKey % 2 === 0
			});
			const isOpen = openCollapsible.includes(row.id);
			const isCollapsable = hasCollapseProperties(row);

			return (
				<>
					<TableRow
						key={rowKey}
						hover={hover}
						className={cx(classes.tableRow, tableRowClasses, { [classes.tableRowHover]: hover, [classes.disabledStyle]: disabledRow && disabledRow(row) })}
						onClick={event => toggleCollapseOnRow && this.toggleOpenCollapsible(event, row.id)}
					>
						{tableRenderer.filter(columnRenderer => columnRenderer.hideBelowWidth === undefined || columnRenderer.hideBelowWidth <= width)
							.map((columnRenderer, fieldIndex) => {
								if (columnRenderer.id === COLLAPSE_CELL) {
									return (
										<TableCell style={{ cursor: "pointer" }} align={columnRenderer.align}
										           className={this.calculateCellClasses(fieldIndex)}
										           key={fieldIndex}
										           onClick={event => this.toggleOpenCollapsible(event, row.id)}>
											{isCollapsable && (
												<IconButton aria-label="expand row" size="small">
													{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
												</IconButton>
											)}
										</TableCell>
									);
								}
								return (
									<TableCell align={columnRenderer.align} className={this.calculateCellClasses(fieldIndex)} key={fieldIndex}>
										{columnRenderer.renderCell(row, fieldIndex, event => this.toggleOpenCollapsible(event, row.id))}
									</TableCell>
								);
							})}
					</TableRow>
					{isCollapsable && (
						<TableRow
							key={`${rowKey}.collapse`}
							hover={false}
							className={cx(classes.tableRow, tableRowClasses, { [classes.tableRowHover]: false, [classes.disabledStyle]: disabledRow && disabledRow(row) })}
						>
							<TableCell style={{ padding: "0 0 0 1em" }} colSpan={width}>
								<Collapse in={isOpen} timeout={0}>
									<EnhancedTableBase
										tableRenderer={collapsedTableRenderer ? collapsedTableRenderer : tableRenderer}
										hover={hover}
										classes={classes}
										tableData={extractCollapseProperties(row)}
										hideHeader={hideCollapsedTableHeader}
										persistSortFor={`${persistSortFor}CollapsedTable`}
									/>
								</Collapse>
							</TableCell>
						</TableRow>
					)}
				</>
			);
		}

		return super.renderTableRow(rowIn, rowKey);
	};
}

export default withStyles(enhancedTableStyle)(EnhancedCollapsibleTable);
