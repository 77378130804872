import moment from "moment";

const UNDEFINED_TIME = "not defined";

export const checkDatePassed = date =>
	date ? moment(date).isBefore(moment()) : false;

export const checkDatePendent = date =>
	date ? moment(date).isAfter(moment()) : false;

export const dateOnlyFormat = "DD.MM.YYYY";
export const timeOnlyFormat = "HH:mm";
export const fullDateFormat = "DD.MM.YYYY HH:mm";
export const fullLongDateFormat = "D MMMM YYYY HH:mm";

export const formatDateOnly = date => {
	if (!date) {
		return UNDEFINED_TIME;
	}
	return moment(date).format(dateOnlyFormat);
};

export const formatTimeOnly = date => {
	if (!date) {
		return UNDEFINED_TIME;
	}
	return moment(date).format(timeOnlyFormat);
};

export const formatFullDate = date => {
	if (!date) {
		return UNDEFINED_TIME;
	}
	return moment(date).format(fullDateFormat);
};
export const formatFullLongDate = (date, alternative) => {
	if (!date) {
		return alternative ? alternative : UNDEFINED_TIME;
	}
	return moment(date).format(fullLongDateFormat);
};

export const tryFormatShort = date => {
	if (!date) {
		return UNDEFINED_TIME;
	}
	const mDate = moment(date);
	if (moment().date() === mDate.date()) {
		return formatTimeOnly(date);
	} else {
		return formatFullDate(date);
	}
};

export const getDateDiff = (startDate, endDate) => {
	return startDate && endDate
		? moment(endDate).diff(moment(startDate), "seconds")
		: null;
};

const mightAddDurationEntry = (workObject, value, intlName) => {
	const { result, counter, maxEntries, intl } = workObject;
	if (value > 0 && counter < maxEntries) {
		workObject.result = result.concat(
			intl.fm(`common.duration.${intlName}`, null, { value }) + ", "
		);
		workObject.counter++;
	}
};

export const printDuration = (durationSeconds, intl, maxEntries = 5) => {
	if (isNaN(durationSeconds)) return null;
	const duration = moment.duration(durationSeconds, "seconds");

	const workObject = { result: "", counter: 0, maxEntries, intl };

	const months = duration.months();
	mightAddDurationEntry(workObject, months, "month");

	const days = duration.days();
	mightAddDurationEntry(workObject, days, "day");

	const hours = duration.hours();
	mightAddDurationEntry(workObject, hours, "hour");

	const minutes = duration.minutes();
	mightAddDurationEntry(workObject, minutes, "minute");

	const seconds = duration.seconds();
	mightAddDurationEntry(workObject, seconds, "second");

	return workObject.result.slice(0, -2);
};

export const getEndTime = (event, eventUnit) => {
	const endTimes = [];
	if (event && event.endTime) endTimes.push(moment(event.endTime));
	if (event && event.timeLimitedEndDate)
		endTimes.push(moment(event.timeLimitedEndDate));
	if (eventUnit && eventUnit.endTime) endTimes.push(moment(eventUnit.endTime));
	if (endTimes.length === 0) return null;
	else return moment.min(endTimes);
};

export const isFinished = (event, eventUnit) => {
	if (eventUnit && eventUnit.solution && eventUnit.solution.finished)
		return true;
	const endTime = getEndTime(event, eventUnit);
	if (!endTime) return false;
	return endTime.isBefore(moment());
};

export const getDateInputValue = time => {
	if (!time) return null;
	const momentTime = moment(time);
	return momentTime && momentTime.isValid() ? momentTime : time;
};

export const isEventRunning = event =>
	moment(event.startTime).isBefore() &&
	(!event.endTime || moment(event.endTime).isAfter());
