import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { Dto } from "@hlcr/core/api/RemoteResource";
import { WithIntl } from "@hlcr/ui/Intl";

import { formatDateOnly } from "helper/dateCalc";
import { createMemoize } from "helper/memoize";

export enum ScoringMode {
	STATIC = "STATIC",
	DYNAMIC = "DYNAMIC",
}

export const SCORING_MODE: EnumDetail<ScoringMode> = {
	[ScoringMode.STATIC]: { title: "Static" },
	[ScoringMode.DYNAMIC]: { title: "Dynamic" },
};

export enum EventType {
	STANDALONE_EVENT = "STANDALONE_EVENT",
	CURRICULUM = "CURRICULUM",
	CURRICULUM_EVENT = "CURRICULUM_EVENT",
	BUG_BOUNTY_EVENT = "BUG_BOUNTY_EVENT",
}

export enum EventAccessibility {
	OPEN = "OPEN",
	STATIC_TOKEN = "STATIC_TOKEN",
	VOUCHER = "VOUCHER",
}

export enum EventVisibility {
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE",
}

export interface BaseEvent extends Dto {
	id: number;
	name: string;
	type: EventType;
	description: string;
	startTime: Date;
	endTime: Date;
	freezeTimeBeforeEndInMinutes?: number;
	accessibility: EventAccessibility;
	visibility: EventVisibility;
	exam: boolean;
	ranked: boolean;
	rankedShowNoPointsUsers: boolean;
	scoringMode: ScoringMode;
}

export function formatDateRange(event: BaseEvent, intl: WithIntl["intl"], parentEvent?: BaseEvent) {
	const startTime = event.startTime ?? parentEvent?.startTime;
	const endTime = event.endTime ?? parentEvent?.endTime;

	if (startTime) {
		if (endTime) {
			return intl.fm("manager.eventOverview.date.startAndEnd", undefined, { startDate: formatDateOnly(startTime), endDate: formatDateOnly(endTime) });
		}
		return intl.fm("manager.eventOverview.date.startOnly", undefined, { startDate: formatDateOnly(startTime) });
	}

	if (endTime) {
		return intl.fm("manager.eventOverview.date.endOnly", undefined, { endDate: formatDateOnly(endTime) });
	}
	return intl.fm("manager.eventOverview.date.open");
}

export const getEvent = createMemoize((events: BaseEvent[], eventId: number) => {
	return events.find(event => event.id === eventId);
});
