import { grayColor, infoColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { createStyles, makeStyles } from "@material-ui/core";

const solutionStyle = createStyles({
	actions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		"& > button": { padding: 12 },
	},
	pointSliderBox: {
		display: "flex",
		paddingBottom: "15px",
		overflow: "visible",
		flexWrap: "wrap",
	},
	slider: {
		margin: "4px 12px",
		width: "auto",
		flexGrow: 1,
	},
	labels: {
		display: "flex",
		flexBasis: "50%",
		flexDirection: "column",
		alignItems: "flex-end",
		minWidth: 150,
	},
	points: { flexBasis: "50%" },
	lastCommentAttachment: {
		display: "inline-flex",
		alignItems: "center",
		marginTop: 6,
		color: grayColor,
		fontWeight: 400,
		"&:hover": {
			color: infoColor,
			textDecoration: "underline",
		},
	},
	solutionInputForm: { marginBottom: 0 },
	lastCommentAttachmentIcon: {
		color: infoColor,
		marginRight: 5,
	},
	monoSpaced: { fontFamily: "Menlo, Monaco, Consolas, \"Courier New\", monospace" },
	monospaceLabel: {
		fontSize: ".9em",
		fontWeight: 400,
		cursor: "pointer",
	},
	timelineMonospaced: { fontFamily: "Menlo, Monaco, Consolas, \"Courier New\", monospace", whiteSpace: "break-spaces", overflowWrap: "break-word" },
	timelineNonMonospaced: { fontFamily: "inherit", whiteSpace: "break-spaces", overflowWrap: "break-word" },
	addGradeSwitch: {
		fontSize: ".9em",
		marginLeft: "auto",
		width: "fit-content",
	},
});

export const useSolutionStyles = makeStyles(solutionStyle);


export default solutionStyle;
