import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import CheckTickedIcon from "@material-ui/icons/CheckBox";
import CheckBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PropTypes from "prop-types";
import React, { createElement, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";

import managerApi from "actions/manager";
import { Button } from "@hlcr/mui/Button";
import IconButton from "components/CustomButtons/IconButton";
import CustomInput from "components/CustomInput/CustomInput";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import LevelLabel from "components/LevelLabel/LevelLabel";
import ModalWindow from "components/ModalWindow/ModalWindow";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import { CategoryIcons } from "helper/categories";
import levelColors from "helper/levelColors";

import addUnitsModalStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/addUnitsModalStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { ASSET_TYPES } from "models/Asset";

class AddUnitsModal extends React.Component {
	focusRef = createRef();

	constructor(props) {
		super(props);
		this.state = {
			query: "",
			selectedUnits: [],
			filteredUnits: props.units,
			loading: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { query } = this.state;
		const { isOpen, units } = this.props;

		if (!prevProps.isOpen && isOpen) {
			setTimeout(() => {
				if (this.focusRef.current) this.focusRef.current.focus();
			}, 200);
		}

		if (prevProps.isOpen && !isOpen) {
			this.setState({ selectedUnits: [] });
		}

		if (prevProps.units !== units || prevState.query !== query) {
			this.setState(({ query }) => ({ filteredUnits: filterUnits(units, query) }));
		}
	}

	render() {
		const { filteredUnits, selectedUnits, query, loading } = this.state;
		const { intl, classes, isOpen, handleClose } = this.props;

		return (
			isOpen && (
				<ModalWindow
					open={isOpen}
					onClose={handleClose}
					title={
						<CustomInput
							searchIcon
							helpText={intl.fm("manager.addUnits.queryHelp")}
							inputProps={{
								type: "inputSearch",
								value: query,
								onChange: event => {
									const query = event.target.value;
									this.setState({ query });
								},
								placeholder: intl.fm("manager.addUnits.queryPlaceholder"),
								inputRef: this.focusRef
							}}
							formControlProps={{ className: classes.filterControl }}
						/>
					}
					classes={{ modal: classes.modal }}
					actionSection={
						<div className={classes.actions}>
							<Button
								onClick={this.addSelectedUnits}
								disabled={selectedUnits.length === 0 || loading}
								color="infoNoBackground"
							>
								{loading ? (
									<CircularProgress size={18} />
								) : (
									intl.fm("manager.addUnits.addButton", null, { count: selectedUnits.length })
								)}
							</Button>
						</div>
					}
					fullWidth
				>
					<EnhancedTable
						tableRenderer={this.tableRenderer}
						tableData={filteredUnits}
						hover
						paginate={50}
						emptyContent={
							<NoData text={intl.fm("manager.addUnits.table.noData")} />
						}
						classes={{ tableResponsive: classes.table }}
						disablePS
					/>
				</ModalWindow>
			)
		);
	}

	addSelectedUnits = async () => {
		const { selectedUnits } = this.state;
		const { addUnit, handleClose } = this.props;

		this.setState({ loading: true });

		for (let unit of selectedUnits)
			await new Promise(resolve => addUnit(unit, resolve));

		this.setState({ loading: false });

		handleClose();
	};

	selectedUnit = unit => {
		const { selectedUnits } = this.state;

		if (selectedUnits.some(selected => selected === unit)) {
			this.setState(({ selectedUnits }) => ({ selectedUnits: selectedUnits.filter(selected => selected !== unit) }));
		} else {
			this.setState(({ selectedUnits }) => ({
				selectedUnits: [
					...selectedUnits,
					unit,
				]
			}));
		}
	};

	tableRenderer = [
		{
			id: "select",
			renderCell: unit => {
				const { selectedUnits } = this.state;
				const { addedUnitUuids } = this.props;

				const isSelected = selectedUnits.some(selected => selected === unit);
				const isAdded = addedUnitUuids.some(uuid => uuid === unit.uuid);

				return (
					<IconButton
						color="infoNoBackground"
						onClick={() => this.selectedUnit(unit)}
						disabled={isAdded}
					>
						{isSelected || isAdded ? <CheckTickedIcon /> : <CheckBlankIcon />}
					</IconButton>
				);
			}
		},
		{
			id: "type",
			renderCell: unit => (
				<Tooltip title={unit.type} placement="top">
					<div className={this.props.classes.typeIcon}>
						{createElement(ASSET_TYPES[unit.type].icon)}
					</div>
				</Tooltip>
			)
		},
		{
			id: "title",
			title: this.props.intl.fm("manager.addUnits.table.title"),
			renderCell: unit => unit.title
		},
		{
			id: "level",
			title: this.props.intl.fm("manager.addUnits.table.level"),
			renderCell: unit => unit.level && <LevelLabel value={unit.level.name} />,
			filter: {
				name: "level",
				func: (unit, value) =>
					value === undefined || (unit.level && unit.level.name === value),
				dialog: callback => (
					<div className={this.props.classes.levelFilter}>
						<Button onClick={callback()} color="primary">
							{this.props.intl.fm("common.labels.all")}
						</Button>
						{Object.keys(levelColors).map(level => (
							<Button
								onClick={callback(level)}
								key={level}
								style={{ background: levelColors[level] }}
							>
								{level}
							</Button>
						))}
					</div>
				)
			}
		},
		{
			id: "category",
			title: this.props.intl.fm("manager.addUnits.table.category"),
			renderCell: unit =>
				unit.categories && <CategoryIcons categories={unit.categories} />
		}
	];
}

const filterUnits = (units, query) => {
	if (units.length === 0) return [];
	if (!query) return units;

	const tokens = query.toLowerCase().split(" ");
	let filtered = units;

	tokens.forEach(token => {
		filtered = filtered.filter(
			unit =>
				(unit.type && unit.type.toLowerCase().includes(token)) ||
				unit.title.toLowerCase().includes(token) ||
				(unit.level && unit.level.name.includes(token)) ||
				(Array.isArray(unit.categories) &&
					unit.categories.some(cat => cat.name.toLowerCase().includes(token)))
		);
	});

	return filtered;
};

AddUnitsModal.propTypes = {
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	addUnit: PropTypes.func.isRequired,
	units: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			title: PropTypes.string,
			level: PropTypes.shape({ name: PropTypes.string.isRequired }),
			categories: PropTypes.arrayOf(
				PropTypes.shape({ name: PropTypes.string.isRequired })
			)
		})
	).isRequired,
	addedUnitUuids: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			addEvent: managerApi.addEvent,
			addCurriculumEvent: managerApi.addCurriculumEvent,
			cloneEvent: managerApi.cloneEvent
		},
		dispatch
	);

export default compose(
	connect(
		undefined,
		mapDispatchToProps
	),
	withRouter,
	withIntl,
	withStyles(addUnitsModalStyle)
)(AddUnitsModal);
