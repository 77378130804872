import { Button } from "@hlcr/mui/Button";
import { ProcessedFile, Upload } from "@hlcr/mui/Upload";
import { useInput } from "@hlcr/ui/hooks";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, Grid, makeStyles, TextField, TextFieldProps, Theme } from "@material-ui/core";
import Chip from "@material-ui/core/Chip/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import * as React from "react";

import { NewBugBountyReport } from "bugBounty/models/BugBountyReport";

export const ReportNewBugForm = ({ handleSubmit, isSubmitting }: { handleSubmit: (report: NewBugBountyReport) => void, isSubmitting: boolean }) => {
	const intl = useIntl();
	const classes = useStyles();

	const { inputProps: bugTitleInputProps } = useInput("");
	const { inputProps: cvssScoreInputProps } = useInput<number>(0.0);
	const { inputProps: cvssVectorInputProps } = useInput("");
	const { inputProps: newCweIdInputProps, reset: resetNewCweInputProps } = useInput<string>("");
	const { inputProps: descriptionInputProps } = useInput("");
	const { inputProps: attachmentsInputProps } = useInput<File[]>([]);

	const [ cweIds, setCweIds ] = React.useState<string[]>([]);

	const [ attachments, setAttachments ] = React.useState<ProcessedFile[]>([]);
	const [ currentAttachment, setCurrentAttachment ] = React.useState<ProcessedFile>();

	const handleSubmitClick = () => {
		handleSubmit({
			title: bugTitleInputProps.value,
			hunterCvss: {
				score: cvssScoreInputProps.value,
				vector: cvssVectorInputProps.value,
			},
			cweIds: cweIds,
			description: descriptionInputProps.value,
			attachments: attachments,
		});
	};

	const handleAttachmentAdded = (file: ProcessedFile | null, index?: number) => {
		if (file) {
			if (index !== undefined) {
				// replace attachment at index
				setAttachments((prevState => {
					const newState = [ ...prevState ];
					newState[index] = file;
					return newState;
				}));
			} else {
				// add an attachment to the end of the list
				setAttachments((prevState => [ ...prevState, file ]));
			}
		}
		if (file === null && index !== undefined) {
			// remove attachment at index
			setAttachments((prevState => prevState.filter((_, i) => i !== index)));
		}
	};

	const handleDeleteCwe = (cweToDelete: string) => {
		return () => {
			setCweIds((cweIds: string[]) => {
				return cweIds.filter(cwe => cwe !== cweToDelete);
			});
		};
	};

	const handleAddCweClicked = () => {
		setCweIds((cweIds: string[]) => {
			const cwe = newCweIdInputProps.value;
			if (!cweIds.includes(cwe) && cwe.length) {
				resetNewCweInputProps();
				return [ ...cweIds, cwe ];
			}
			return cweIds;
		});
	};

	const handleCweKeyPress: TextFieldProps["onKeyPress"] = (event) => {
		if (event.key === "Enter") {
			handleAddCweClicked();
		}
	};

	const invalidFormState = bugTitleInputProps.value.length === 0
		|| descriptionInputProps.value.length === 0
		|| cvssScoreInputProps.value < 0
		|| cvssScoreInputProps.value > 10
		|| !/^AV:[NALP]\/AC:[LH]\/PR:[NLH]\/UI:[NR]\/S:[UC]\/C:[NLH]\/I:[NLH]\/A:[NLH]$/.test(cvssVectorInputProps.value);

	const invalidAddCweState = newCweIdInputProps.value.length === 0 || cweIds.includes(newCweIdInputProps.value);

	return (
		<>
			<div>
				<Grid container={true} spacing={3}>
					<Grid item={true} xs={12}>
						<TextField type={"text"} required={true} fullWidth={true} label={intl.fm("bugBounty.newBugReport.title")} {...bugTitleInputProps} />
					</Grid>
					<Grid item={true} xs={12} sm={3}>
						<TextField type={"number"} required={true} fullWidth={true} multiline={false} label={intl.fm("bugBounty.newBugReport.cvssScore")} InputProps={{ inputProps: { min: 0, max: 10 } }} {...cvssScoreInputProps} />
					</Grid>
					<Grid item={true} xs={12} md={7}>
						<TextField required={true} fullWidth={true} multiline={false} label={intl.fm("bugBounty.newBugReport.cvssVector")} {...cvssVectorInputProps} />
					</Grid>
					<Grid item={true} xs={12} sm={2}>
						<a href={`https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?version=3.1${cvssVectorInputProps.value !== "" ? `&vector=${cvssVectorInputProps.value}` : ""}`}
						   target="_blank"
						   rel="noopener noreferrer">
							<Button color={"primary"} fullWidth={true} endIcon={<OpenInNewIcon />}>NIST Calculator</Button>
						</a>
					</Grid>
					<Grid item={true} xs={12}>
						{cweIds.map(cwe => {
							return (
								<Chip
									key={cwe}
									label={cwe}
									onDelete={handleDeleteCwe(cwe)}
									className={classes.chip}
								/>
							);
						})}
					</Grid>
					<Grid item={true} xs={12} lg={10}>
						<TextField fullWidth={true} label={intl.fm("bugBounty.newBugReport.cweIds")} onKeyPress={handleCweKeyPress} {...newCweIdInputProps} />
					</Grid>
					<Grid item={true} xs={12} lg={2}>
						<Button color={"primary"} onClick={handleAddCweClicked} fullWidth={true} disabled={invalidAddCweState}>
							Add CWE ID
						</Button>
					</Grid>
					<Grid item={true} xs={12}>
						<TextField required={true} fullWidth={true} multiline={true} minRows={10}
								   label={intl.fm("bugBounty.newBugReport.description")} {...descriptionInputProps} />
					</Grid>
					<Grid item={true} xs={12}>
						<Upload
							type="file"
							file={currentAttachment}
							disabled={isSubmitting}
							handleProcessedFile={(file) => handleAttachmentAdded(file)}
						/>
					</Grid>
					{attachments.map((attachment, index) => (
						<Grid item={true} xs={6} lg={4} xl={3} key={index}>
							<Upload
								rootClassName={classes.upload}
								type="file"
								file={attachment}
								disabled={false}
								handleProcessedFile={(file) => handleAttachmentAdded(file, index)}
							/>
						</Grid>
					))}
				</Grid>
				<Grid container={true} direction={"row-reverse"} spacing={3}>
					<Grid item={true} xs={4} md={2}>
						<Button fullWidth={true} onClick={handleSubmitClick} color={"info"} disabled={invalidFormState || isSubmitting}>
							{
								isSubmitting
									? <CircularProgress size={18} />
									: `${intl.fm("bugBounty.newBugReport.submit")}`
							}
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: { width: "100%" },
		column: { display: "flex", flexDirection: "column" },
		subject: { maxWidth: 350, marginBottom: theme.spacing(1) },
		buttons: { display: "flex", flexDirection: "row-reverse" },
		chip: { margin: "2px 4px" },
		upload: { height: "100%" },
	}),
);
