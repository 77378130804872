import { withStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import cx from "classnames";
import React from "react";

import timelineStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionTimelineStyle.jsx";
import { Badge } from "@hlcr/mui";
import SpeedDials from "components/CustomButtons/SpeedDials";
import TweetButton from "components/Social/TweetButton";

const TimeLineItem = ({ comment, simple, compact, addAction, noArrows, classes }) => {
	const panelClasses = cx(classes.timelinePanel, {
		[classes.timelinePanelArrows]: !noArrows,
		[classes.timelinePanelInverted]: comment.inverted,
		[classes.timelineSimplePanel]: simple
	});

	const timelineBadgeClasses = cx(
		classes.timelineBadge,
		classes[comment.badgeColor],
		{
			[classes.timelineSimpleBadge]: simple,
			[classes.timelineBadgeAction]: comment.onClick
		}
	);

	const timelineBodyClasses = cx(classes.timelineBody, {
		[classes.timelineBodyCompact]: compact
	});

	return (
		<>
			{comment.badgeIcon &&
				timeLineBadge(comment, addAction, timelineBadgeClasses, classes)}
			{comment.title || comment.body ? (
				<div className={panelClasses}>
					{comment.title && commentTitle(comment, classes)}
					<div className={timelineBodyClasses}>{comment.body}</div>
					{comment.attachment &&
						comment.attachment.id &&
						renderAttachment(comment.attachment, classes)}

					{comment.footerTitle && footerTitle(comment, classes)}

					{comment.footer && <hr className={classes.footerLine} />}
					{comment.footer && (
						<div className={classes.timelineFooter}>{comment.footer}</div>
					)}
				</div>
			) : (
				<div className={cx({ [classes.actionButtonSpacing]: !simple })} />
			)}
		</>
	);
};

const renderAttachment = (attachment, classes) => (
	<a
		href={`/api/attachments/${attachment.id}`}
		target="_blank"
		rel="noopener noreferrer"
		className={classes.timelineAttachment}
	>
		<AttachmentIcon className={classes.timelineAttachmentIcon} />
		{attachment.name}
	</a>
);

// Show the challenge title and append a tweet button if a flag was found
const commentTitle = (comment, classes) => (
	<div className={classes.timelineHeading}>
		<Badge color={comment.titleColor}>{comment.title}</Badge>
		<span className={classes.titleMeta}>{comment.titleMeta}</span>
		{comment.flagGrade === 1 || comment.writeupGrade > 0 && (
			<TweetButton
				text={`Success! Solved ${comment.eventName ||
					"a Challenge"} and got points! Awesome.`}
			/>
		)}
	</div>
);

const footerTitle = (comment, classes) => (
	<div className={classes.footerTitle}>
		{comment.footerTitle}
		{comment.footerMeta && (
			<span className={classes.timelineFooterMeta}>{comment.footerMeta}</span>
		)}
	</div>
);

const timeLineBadge = (comment, addAction, timelineBadgeClasses, classes) => {
	return comment.isDial ? (
		<div className={timelineBadgeClasses}>
			<SpeedDials BaseIcon={comment.badgeIcon} actions={addAction} />
		</div>
	) : (
		<div onClick={comment.onClick} className={timelineBadgeClasses}>
			<comment.badgeIcon className={classes.badgeIcon} />
		</div>
	);
}



export default withStyles(timelineStyle)(TimeLineItem);
