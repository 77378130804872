import { EnumDetail, EnumWithColor } from "@hlcr/app/enum/EnumBase";

export enum BugBountyStatusEnum {
	WAITING_FOR_TRIAGE = "WAITING_FOR_TRIAGE",
	IN_TRIAGE = "IN_TRIAGE",
	WAITING_FOR_HUNTER = "WAITING_FOR_HUNTER",
	ACCEPTED = "ACCEPTED",
	WAITING_FOR_FIX = "WAITING_FOR_FIX",
	DONE = "DONE",
	WAITING_FOR_RESPONSE = "WAITING_FOR_RESPONSE",
	CLOSED = "CLOSED",
	UNKNOWN = "UNKNOWN",
}

export const BUG_BOUNTY_STATUSES: EnumDetail<BugBountyStatusEnum, EnumWithColor> = {
	WAITING_FOR_TRIAGE: { title: "bugBounty.state.waitingForTriage", color: "secondary" },
	IN_TRIAGE: { title: "bugBounty.state.inTriage", color: "info" },
	WAITING_FOR_HUNTER: { title: "bugBounty.state.waitingForHunter", color: "primary" },
	ACCEPTED: { title: "bugBounty.state.accepted", color: "success" },
	WAITING_FOR_FIX: { title: "bugBounty.state.waitingForFix", color: "success" },
	DONE: { title: "bugBounty.state.done", color: "secondary" },
	WAITING_FOR_RESPONSE: { title: "bugBounty.state.waitingForResponse", color: "secondary" },
	CLOSED: { title: "bugBounty.state.closed", color: "#888" },
	UNKNOWN: { title: "bugBounty.state.unknown", color: "#888" },
};

export const getBugBountyStatusEnumDetail = (bountyStatus?: BugBountyStatusEnum) => {
	if (bountyStatus === undefined || !Object.keys(BUG_BOUNTY_STATUSES).includes(bountyStatus)) {
		console.debug(`invalid bounty status: ${bountyStatus}`);
		return;
	}
	return BUG_BOUNTY_STATUSES[bountyStatus];
};
