import { RemoteResourceState } from "@hlcr/core/api/RemoteResource";

import { BugBountyProgram } from "bugBounty/models/BugBountyProgram";
import { BugBountyReportSummary } from "bugBounty/models/BugBountyReport";
import { EventPreJoin } from "eventAccessToken/models/EventPreJoin";
import { TenantAccessPolicy } from "models/TenantAccessPolicy";
import { TeamSolutionSummary, UserProfile, UserSolutionSummary } from "models/User";
import { TenantAdminConfiguration, TenantAdminConfigurationAccessPolicy, TenantAdminConfigurationEmail } from "tenantAdmin/model/TenantAdminConfiguration";
import { EventTerm, TermAgreement } from "term/term.model";

export enum RemoteResource {
	TENANT_ACCESS_POLICY = "TENANT_ACCESS_POLICY",
	USER_PROFILE = "USER_PROFILE",
	EVENT_TERMS = "EVENT_TERMS",
	TERM_AGREEMENTS = "TERM_AGREEMENTS",
	TENANT_ADMIN_CONFIGURATION = "TENANT_ADMIN_CONFIGURATION",
	TENANT_CONFIGURATION_ACCESS_POLICY = "TENANT_CONFIGURATION_ACCESS_POLICY",
	TENANT_ADMIN_CONFIGURATION_EMAIL = "TENANT_ADMIN_CONFIGURATION_EMAIL",
	EVENT_PARTICIPANTS_USERS = "EVENT_PARTICIPANTS_USERS",
	EVENT_PARTICIPANTS_TEAMS = "EVENT_PARTICIPANTS_TEAMS",
	EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS = "EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS",
	EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS = "EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS",
	EVENT_PRE_JOIN_INFO = "EVENT_PRE_JOIN_INFO",
	BUG_BOUNTY_EVENTS = "BUG_BOUNTY_EVENTS",
	BUG_BOUNTY_REPORTS = "BUG_BOUNTY_REPORTS",
}

type AllRemoteResources = {
	[key in RemoteResource]: RemoteResourceState;
}

// Map each remote resource to its corresponding state for type safety
export interface RemoteResources extends AllRemoteResources {
	[RemoteResource.TENANT_ACCESS_POLICY]: RemoteResourceState<TenantAccessPolicy>;
	[RemoteResource.USER_PROFILE]: RemoteResourceState<UserProfile>;
	[RemoteResource.EVENT_TERMS]: RemoteResourceState<EventTerm[]>;
	[RemoteResource.TERM_AGREEMENTS]: RemoteResourceState<TermAgreement[]>;
	[RemoteResource.TENANT_ADMIN_CONFIGURATION]: RemoteResourceState<TenantAdminConfiguration>;
	[RemoteResource.TENANT_CONFIGURATION_ACCESS_POLICY]: RemoteResourceState<TenantAdminConfigurationAccessPolicy>;
	[RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL]: RemoteResourceState<TenantAdminConfigurationEmail[]>;
	[RemoteResource.EVENT_PARTICIPANTS_USERS]: RemoteResourceState<UserSolutionSummary[]>;
	[RemoteResource.EVENT_PARTICIPANTS_TEAMS]: RemoteResourceState<TeamSolutionSummary[]>;
	[RemoteResource.EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS]: RemoteResourceState<UserSolutionSummary[]>;
	[RemoteResource.EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS]: RemoteResourceState<TeamSolutionSummary[]>;
	[RemoteResource.EVENT_PRE_JOIN_INFO]: RemoteResourceState<EventPreJoin[]>;
	[RemoteResource.BUG_BOUNTY_EVENTS]: RemoteResourceState<BugBountyProgram[]>;
	[RemoteResource.BUG_BOUNTY_REPORTS]: RemoteResourceState<BugBountyReportSummary[]>;
}

export interface RemoteResourcesState {
	remoteResources: RemoteResources;
}
