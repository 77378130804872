import { boxShadow, card, cardActions, cardHeader, defaultFont, grayColor, roseColor, successColor, warningColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const basicTopLeftBow = {
	position: "absolute",
	top: -30,
	left: -8,
	width: 45,
	height: 90,
	background: roseColor,
	transform: "rotate(45deg)",
	boxShadow: "0 0 5px rgba(0, 0, 0, .7);",
	zIndex: 1,
};

const basicBowIcon = {
	position: "absolute",
	top: 5,
	left: 5,
	color: "white",
	zIndex: 2,
};

const commonEventCardStyle = {
	card: {
		...card,
		width: 364,
		"@media (max-width: 450px)": { width: 300 },
	},
	cardSubheader: {
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "relative",
		width: "100%",
		height: 200,
		"@media (max-width: 450px)": { height: 160 },
	},
	curriculumBow: { ...basicTopLeftBow },
	bugBountyProgramBow: {
		...basicTopLeftBow,
		background: "black",
	},
	examBow: {
		...basicTopLeftBow,
		background: warningColor,
	},
	archivedBow: {
		...basicTopLeftBow,
		left: "inherit",
		right: -8,
		background: "black",
		transform: "rotate(-45deg)",
	},
	termBowAccepted: {
		...basicTopLeftBow,
		left: "inherit",
		right: -8,
		background: successColor,
		transform: "rotate(-45deg)",
	},
	termBowNotAccepted: {
		...basicTopLeftBow,
		left: "inherit",
		right: -8,
		background: warningColor,
		transform: "rotate(-45deg)",
	},
	termBowHover: {
		"&:hover": {
			cursor: "pointer",
			"& div": {
				filter: "brightness(120%)",
			},
		},
	},
	termBowIcon: {
		...basicBowIcon,
		left: "inherit",
		right: 5,
	},
	curriculumBowIcon: { ...basicBowIcon },
	examBowIcon: { ...basicBowIcon },
	bugBountyProgramBowIcon: { ...basicBowIcon },
	archivedBowIcon: {
		...basicBowIcon,
		left: "inherit",
		right: 5,
		color: warningColor,
	},
	clickable: { cursor: "pointer" },
	cardOverlay: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
		position: "absolute",
		opacity: 0,
		"&:hover": {
			background: "rgba(0, 0, 0, .6)",
			opacity: 1,
			transition: "all .1s ease-out",
		},
	},
	linkRadius: {
		borderRadius: 100,
		display: "inline-block",
	},
	cardOverlayText: {
		cursor: "pointer",
		padding: 12,
		border: "1px solid rgba(255, 255, 255, .6)",
		borderRadius: 100,
		background: "rgba(125, 125, 125, .25)",
		fontSize: "2em",
		"&:hover": {
			transform: "scale(1.07)",
			border: "1px solid rgba(255, 255, 255, .75)",
			background: "rgba(0, 0, 0, .5)",
			transition: "all .05s ease-out",
		},
	},
	cardOverlayTextMargin: { marginLeft: 15 },
	cardOverlaySpacer: { width: 15 },
	overlayLinkIcon: { color: "#fff" },
	cardHeader: {
		...cardHeader,
		padding: "0",
		...defaultFont,
		position: "relative",
		zIndex: 3,
		transition: "all 150ms cubic-bezier(0.34, 1.61, 0.7, 1)",
		transform: "translate3d(0, 0, 0)",
		height: "60%",
		overflow: "hidden",
		marginLeft: "15px",
		marginRight: "15px",
		marginTop: "-30px",
		borderRadius: "6px",
		...boxShadow,
	},
	cardImage: {
		width: "100%",
		height: "100%",
		overflow: "hidden",
		borderRadius: "6px",
		pointerEvents: "none",
		verticalAlign: "middle",
	},
	cardContent: {
		padding: "15px 20px",
		position: "relative",
	},
	cardTitle: {
		marginTop: "0",
		marginBottom: "5px",
		textAlign: "center",
	},
	cardCategory: {
		marginBottom: "0",
		color: grayColor,
		fontWeight: 500,
		fontSize: "0.9em",
		textAlign: "center",
	},
	contentText: {
		marginBottom: "0",
		color: grayColor,
		...defaultFont,
		textAlign: "center",
		height: "1.5em",
	},
	eventDescription: {
		marginBottom: "0",
		...defaultFont,
		textAlign: "center",
		margin: "0 10px 20px 10px !important",
	},
	cardActions: {
		...cardActions,
		padding: "5px 0 !important",
		margin: "0px 20px",
		display: "grid",
		gridAutoColumns: "minmax(0, 1fr)",
		gridAutoFlow: "column",
		justifyItems: "center",
	},
	cardActionItem: {
		alignSelf: "center",
	},
	cardActionButton: {
		paddingLeft: 0,
		paddingRight: 0,
		margin: 0,
		width: "100%",
	},
	cardCollapseButton: {
		padding: 0,
		margin: 0,
		justifySelf: "end",
	},
	cardStats: {
		color: grayColor,
		fontSize: 11,
		margin: "0",
		justifySelf: "start",
		display: "flex",
		// add left margin to all but first child
		"& > *:not(:first-child)": {
			marginLeft: "15px",
		},
	},
	cardStatContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	cardStatsIcon: {
		position: "relative",
		top: 4,
		width: 16,
		height: 16,
	},
	buttonIcon: {
		width: "17px",
		height: "17px",
	},
};

export default commonEventCardStyle;
