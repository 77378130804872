import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import EventIcon from "@material-ui/icons/Event";

import { EventDetails, EventDetailsBreadCrumb } from "views/Events/EventDetails";
import * as Events from "views/Events/Events";
import UserChallenge, { UserChallengeBreadCrumb } from "views/Events/UserChallenge";
import UserQuiz, { UserQuizBreadCrumb } from "views/Events/UserQuiz";
import UserTheory, { UserTheoryBreadCrumb } from "views/Events/UserTheory";
import RankingPage from "views/Ranking/RankingPage";

import * as intl from "./localization";


export interface EventDetailsRouteParams {
	eventId: string;
	parentId?: string;
}

export const eventRoutes: HackingLabRoute[] = [
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events",
		name: intl.Events,
		icon: EventIcon,
		component: Events,
		hasSidebarLink: true,
		checkAccessPolicy: true,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		breadcrumb: "Events",
		help: { role: "user", action: "pageEvents" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/redeem",
		component: Events,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/redeem/:tokenParam",
		component: Events,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:eventId",
		name: "Event Details",
		component: EventDetails,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: EventDetailsBreadCrumb,
		help: { role: "user", action: "pageEventDetails" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:parentId/curriculumevents/:eventId",
		name: "Curriculum Event Details",
		component: EventDetails,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: EventDetailsBreadCrumb,
		help: { role: "user", action: "pageEventDetails" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:eventId/challenges/:unitId",
		name: "Challenge",
		component: UserChallenge,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: UserChallengeBreadCrumb,
		help: { role: "user", action: "pageChallenge" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:eventId/theory/:unitId",
		name: "Theory",
		component: UserTheory,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: UserTheoryBreadCrumb,
		help: { role: "user", action: "pageTheory" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:eventId/quiz/:unitId",
		name: "Quiz",
		component: UserQuiz,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: UserQuizBreadCrumb,
		help: { role: "user", action: "pageQuiz" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:parentId/curriculumevents/:eventId/challenges/:unitId",
		name: "Challenge",
		component: UserChallenge,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: UserChallengeBreadCrumb,
		help: { role: "user", action: "pageChallenge" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:parentId/curriculumevents/:eventId/theory/:unitId",
		name: "Theory",
		component: UserTheory,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: UserTheoryBreadCrumb,
		help: { role: "user", action: "pageTheory" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:parentId/curriculumevents/:eventId/quiz/:unitId",
		name: "Quiz",
		component: UserQuiz,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: UserQuizBreadCrumb,
		help: { role: "user", action: "pageQuiz" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:eventId/ranking",
		name: "Ranking",
		component: RankingPage,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: "Ranking",
		help: { role: "user", action: "pageRanking" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/events/:parentId/curriculumevents/:eventId/ranking",
		name: "Ranking",
		component: RankingPage,
		needsRole: HackingLabRole.COMPOSITE_USER,
		denyIfAnyRole: [ HackingLabRole.ROLE_BUG_BOUNTY_HUNTER ],
		checkAccessPolicy: true,
		breadcrumb: "Ranking",
		help: { role: "user", action: "pageRanking" },
	},
];
