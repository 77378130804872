import { withStyles } from "@material-ui/core/styles";
import capitalize from "@material-ui/core/utils/capitalize";
import AddIcon from "@material-ui/icons/Add";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { infoColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { Badge } from "@hlcr/mui";

const styles = theme => ({
	speedDial: {
		position: "absolute",
		"&$directionUp, &$directionLeft": {
			bottom: theme.spacing(2),
			right: theme.spacing(3)
		},
		"&$directionDown, &$directionRight": { left: 0 },

		"& button": { background: infoColor }
	},
	directionUp: {},
	directionRight: {},
	directionDown: {},
	directionLeft: {}
});

class SpeedDials extends React.Component {
	state = {
		direction: "right",
		open: false,
		hidden: false
	};

	startFirstAction = () => {
		if (this.props.actions.length >= 1) {
			this.props.actions[0].handleClick();
		}
	};

	handleClick = () => {
		this.setState(
			state => ({ open: !state.open }),
			this.startFirstAction
		);
	};

	handleDirectionChange = (event, value) => {
		this.setState({ direction: value });
	};

	handleHiddenChange = (event, hidden) => {
		this.setState(state => ({
			hidden,
			// hidden implies !open
			open: hidden ? false : state.open
		}));
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleOpen = () => {
		this.setState({ open: true });
	};

	getBaseIcon() {
		const { BaseIcon } = this.props;
		if (BaseIcon && React.isValidElement(BaseIcon)) {
			return BaseIcon;
		} else if (BaseIcon) {
			return <BaseIcon />;
		} else {
			return <AddIcon />;
		}
	}

	render() {
		const { actions, classes } = this.props;
		const { direction, hidden, open } = this.state;

		const speedDialClassName = classNames(
			classes.speedDial,
			classes[`direction${capitalize(direction)}`]
		);

		const tailActions = actions.slice(1);

		return (
			<SpeedDial
				ariaLabel="SpeedDial"
				className={speedDialClassName}
				hidden={hidden}
				icon={<Badge>{this.getBaseIcon()} </Badge>}
				onBlur={this.handleClose}
				onClick={this.handleClick}
				onClose={this.handleClose}
				onFocus={this.handleOpen}
				onMouseEnter={this.handleOpen}
				onMouseLeave={this.handleClose}
				open={open}
				direction={direction}
			>
				{tailActions.map(action => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={event => {
							action.handleClick();
							event.stopPropagation();
						}}
						FabProps={{ style: action.style }}
					/>
				))}
			</SpeedDial>
		);
	}
}

SpeedDials.propTypes = {
	actions: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SpeedDials);
