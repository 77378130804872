import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import * as PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import solutionStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionStyle";
import { Accordion } from "@hlcr/mui";
import { Button } from "@hlcr/mui/Button";
import ModalWindow from "components/ModalWindow/ModalWindow";
import UserFlagsBox from "components/Solution/UserFlagsBox";
import { withIntl } from "@hlcr/ui/Intl";
import "rc-slider/assets/index.css";
import { fetchHelp } from "variables/helpPage";
import { GRADING_MODES } from "models/EventUnit";
import FlagIcon from "@material-ui/icons/Flag";
import { TextField } from "@material-ui/core";
import { dangerColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { WriteUpForm } from "grading/WriteUpForm/WriteUpForm";
import { PointSliderForm } from "grading/PointSliderForm/PointSliderForm";

class SolutionSubmitDialog extends React.Component {
	state = {
		file: null,
		writeup: null,
		flag: null,
		writeupGrade: 0,
		submitting: false,
		addGrade: true,
		formDirty: false,
	};

	componentDidUpdate(prevProps) {
		const { isOpen, lastTeacherSolutionPoints, writeupGrade } = this.props;
		if (prevProps.isOpen !== isOpen && isOpen) {
			this.setState({ submitting: false });
			if (writeupGrade >= 0) this.setState({ writeupGrade });
			else if (lastTeacherSolutionPoints > 0)
				this.setState({ writeupGrade: lastTeacherSolutionPoints });
			else this.setState({ writeupGrade: 0 });
		}

		// if solutionId has changed, reset form
		if (prevProps.solutionId !== this.props.solutionId) {
			this.clearForm();
		}
	}

	render() {
		const { isTeacher, lastSolutionComment, isOpen, closeModal, intl, classes, helpUrl, userFlags } = this.props;
		const { addGrade } = this.state;

		return (
			isOpen && <ModalWindow
				open={true}
				onClose={closeModal}
				title={isTeacher
					? intl.fm("challenge.comment.newSolution.titleTeacher", null, { what: addGrade ? intl.fm("teacher.grading.grade") : intl.fm("teacher.grading.comment") })
					: intl.fm("challenge.comment.newSolution.title")
				}
				helpLink={isTeacher
					? fetchHelp(helpUrl, "teacher", "correct")
					: fetchHelp(helpUrl, "student", "submitChallenge")
				}
				actionSection={this.commentActions()}
				fullWidth
				maxWidth="md"
			>
				{isTeacher && lastSolutionComment && lastSolution(lastSolutionComment, classes, intl)}
				{isTeacher && <UserFlagsBox flags={userFlags} />}
				{this.commentForm()}
			</ModalWindow>
		);
	}

	isNotSubmittable() {
		const { eventUnit, isTeacher } = this.props;
		const { flag, writeup, file, submitting } = this.state;
		const gradingMode = GRADING_MODES[eventUnit?.grading];

		const flagMissing = gradingMode.isFlagBased && !flag?.length > 0;
		const writeupMissing = gradingMode.isWriteupBased && !writeup?.length > 0 && file === null;
		const hasBothGradingModes = gradingMode.isFlagBased && gradingMode.isWriteupBased;
		const missingInput = (hasBothGradingModes && flagMissing && writeupMissing) || (!hasBothGradingModes && (flagMissing || writeupMissing));

		return submitting || (!isTeacher && missingInput);
	}

	commentActions() {
		const { intl, isTeacher, classes, nextSolutionId } = this.props;
		const { submitting } = this.state;
		const nextDisabled = !nextSolutionId;

		return (
			<div className={classes.actions}>
				<Button
					onClick={this.clearForm}
					color="defaultNoBackground"
				>
					{intl.fm("common.labels.clearForm")}
				</Button>
				{isTeacher && (
					<Button
						disabled={nextDisabled}
						onClick={this.submitAndNext}
						color="infoNoBackground"
					>
						{submitting ? (
							<CircularProgress size={15} />
						) : (
							intl.fm("common.labels.submitAndNext")
						)}
					</Button>
				)}
				{isTeacher && (
					<Button
						onClick={this.submitAndBack}
						color="infoNoBackground"
					>
						{submitting ? (
							<CircularProgress size={15} />
						) : (
							intl.fm("common.labels.submitAndBack")
						)}
					</Button>
				)}
				<Button
					onClick={() => this.submit()}
					color="infoNoBackground"
				>
					{submitting ? (
						<CircularProgress size={15} />
					) : (
						intl.fm("common.labels.submit")
					)}
				</Button>
			</div>
		);
	}

	commentForm() {
		const { eventUnit, maxPoints, flagPoints, writeupWeight, isTeacher, intl, classes } = this.props;
		const { flag, submitting, addGrade, formDirty, writeupGrade } = this.state;
		const gradingMode = GRADING_MODES[eventUnit?.grading];

		const sliderDisabled = submitting || !addGrade;

		if (isTeacher && !gradingMode.isWriteupBased && addGrade) {
			this.setState({ addGrade: false });
		}

		return (
			<form onSubmit={e => e.preventDefault()}>
				{isTeacher && gradingMode.isWriteupBased && (
					<>
						<h4>{intl.fm("teacher.solution.grading.title")}</h4>
						<PointSliderForm
							maxPoints={maxPoints}
							flagPoints={flagPoints}
							writeUpWeight={writeupWeight}
							writeUpGrade={writeupGrade}
							isAddGradeActive={addGrade}
							disabled={sliderDisabled}
							onWriteUpGradeChange={(value) => this.setState({ writeupGrade: value })}
							onIsAddGradeActiveChange={(isActive) => this.setState({ addGrade: isActive})}/>
					</>
				)}
				{gradingMode.isFlagBased && !isTeacher && (
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FlagIcon style={{color: dangerColor, marginBottom: 20, marginRight: 10}} />
						<TextField
							variant="filled"
							error={formDirty && this.isNotSubmittable()}
							label={intl.fm("grading.mode.flag")}
							helperText={intl.fm("solution.history.goldNugget.helper")}
							onChange={this.updateFlagText}
							disabled={submitting}
							value={flag}
							fullWidth
							margin="dense"
							inputProps={{
								className: classes.monoSpaced
							}}
						/>
					</div>
				)}
				{(gradingMode.isWriteupBased || isTeacher) && (
					<WriteUpForm
						writeUpText={this.state.writeup}
						writeUpFile={this.state.file}
						isTeacher={isTeacher}
						isSubmitting={submitting}
						onWriteUpChange={(writeup) => this.setState({ writeup: writeup })}
						onFileChange={(file) => this.setState({ file: file })}
						hasError={formDirty && this.isNotSubmittable()}>
					</WriteUpForm>
				)}
			</form>
		);
	}

	updateFlagText = event => this.setState({ flag: event.currentTarget.value });

	clearForm = () => this.setState({ file: null, flag: null, writeup: null, formDirty: false });

	startSubmit = () => this.setState({ submitting: true });

	stopSubmit = () => this.setState({ submitting: false });

	submit = callback => {
		const {
			onSubmit,
			challengeId,
			solutionId,
			onSuccess,
			isTeacher,
			closeModal,
		} = this.props;
		const { flag, writeup, writeupGrade, file, addGrade } = this.state;

		if (!this.isSubmissionAllowed()) {
			// we only set the form to dirty (marking errors red) when submission is not allowed
			this.setState({ formDirty: true });
			return;
		} else {
			this.setState({ formDirty: false });
		}

		let attachment, entityId, commentDto;

		if (file) {
			attachment = {
				name: file.name,
				content: file.data
			};
		}

		if (!isTeacher) {
			commentDto = {
				writeup: writeup?.length > 0 ? writeup.trim() : null,
				flag: flag?.length > 0 ? flag.trim() : null,
				attachment,
			};
			entityId = challengeId;
		} else {
			entityId = solutionId;
			commentDto = {
				comment: writeup?.length > 0 ? writeup.trim() : null,
				grade: addGrade ? writeupGrade : null,
				attachment,
			};
		}

		this.startSubmit();

		onSubmit(
			commentDto,
			entityId,
			() => {
				this.clearForm();
				closeModal();
				if (onSuccess) onSuccess();
				if (callback) callback();
			},
			() => this.stopSubmit()
		);
	};

	submitAndNext = () => {
		const { eventId, nextSolutionId, history } = this.props;
		const additionalCallback = () => history.push(`/teacher/events/${eventId}/solutions/${nextSolutionId}`);
		if (!this.isSubmissionAllowed()) {
			return;
		}
		this.submit(additionalCallback);
	};

	submitAndBack = () => {
		const { history, eventId } = this.props;
		const additionalCallback = () => history.push(`/teacher/events/${eventId}`);
		if (!this.isSubmissionAllowed()) {
			return;
		}
		this.submit(additionalCallback);
	};

	isSubmissionAllowed = () => {
		return !this.isNotSubmittable()
	}
}

SolutionSubmitDialog.propTypes = {
	eventId: PropTypes.number,
	eventUnit: PropTypes.object,
	challengeId: PropTypes.number.isRequired,
	maxPoints: PropTypes.number,
	solutionId: PropTypes.number,
	nextSolutionId: PropTypes.number,
	lastSolutionComment: PropTypes.object,
	lastTeacherSolutionComment: PropTypes.object,
	lastTeacherSolutionPoints: PropTypes.number,
	isOpen: PropTypes.bool.isRequired,
	writeupGrade: PropTypes.number,
	isTeacher: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	onSuccess: PropTypes.func,
	closeModal: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
	helpUrl: PropTypes.string.isRequired,
	flagPoints: PropTypes.number,
	writeupWeight: PropTypes.number,
	userFlags: PropTypes.arrayOf(PropTypes.shape({
		timestamp: PropTypes.string.isRequired,
		flag: PropTypes.string.isRequired
	}))
};

const lastSolution = (comment, classes, intl) => {
	return (
		<Accordion
			sections={[
				{
					title: intl.fm("teacher.solution.latestWriteup"),
					content: (
						<span>
							{comment.writeup}{" "}
							{comment.attachment && comment.attachment.id && (
								<a
									href={`/api/attachments/${comment.attachment.id}`}
									target="_blank"
									rel="noopener noreferrer"
									className={classes.lastCommentAttachment}
								>
									<AttachmentIcon
										className={classes.lastCommentAttachmentIcon}
									/>
									{comment.attachment.name}
								</a>
							)}
						</span>
					)
				}
			]}
		/>
	);
};

const mapStateToProps = state => ({
	helpUrl: state.branding.helpUrl,
	darkMode: state.ui.darkMode,
});

export default compose(
	connect(mapStateToProps),
	withStyles(solutionStyle),
	withIntl,
	withRouter
)(SolutionSubmitDialog);
