import { Color } from "@material-ui/core";
import { Palette, PaletteColor } from "@material-ui/core/styles/createPalette";

// if renamed: change in _misc.scss as well
export const DARK_MODE_CLASS = "hl--dark-mode";
export const DARK_MODE_SELECTOR = `.${DARK_MODE_CLASS} &`;

export const COLOR = {
	LIGHT_TEXT_1: "#ccc", // change in _misc.scss as well
	LIGHT_TEXT_2: "#666",
	DARK_1: "#222",
	DARK_2: "#333",
	DARK_BORDER: "#444",
};

export const CSS_SELF_HOVER_FOCUS_PSEUDO_CLASSES = "&,&:hover,&:focus";
export const CSS_HOVER_FOCUS_PSEUDO_CLASSES = "&:hover,&:focus";

export const COLOR_PRIMARY = "var(--hl-color-primary)";
export const COLOR_WARNING = "var(--hl-color-warning)";
export const COLOR_DANGER = "var(--hl-color-danger)";
export const COLOR_ROSE = "var(--hl-color-rose)";
export const COLOR_SUCCESS = "var(--hl-color-success)";
export const COLOR_INFO = "var(--hl-color-info)";
export const COLOR_GRAY = "var(--hl-color-gray)";

export type CssColor = typeof COLOR_PRIMARY | typeof COLOR_WARNING | typeof COLOR_DANGER | typeof COLOR_ROSE | typeof COLOR_SUCCESS | typeof COLOR_INFO | typeof COLOR_GRAY;


export type Colors<T> = {
	[X in keyof T as T[X] extends PaletteColor ? X : T[X] extends Color ? X : never]: string;
}
export type PaletteColorKey = keyof Colors<Palette>;
