import { EventType } from "models/EventType";

import { ActionType } from "actions/ActionType";

const BASE_URL = "/api/user";

export const fetchEventJoinInfo = (token, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/join/${token}`,
	method: "get",
	resource: "eventJoin",
	onSuccess: (dispatch, result) => {
		if (onSuccess && typeof onSuccess === "function") {
			onSuccess(result);
		}
	},
	onFailure: (dispatch, result) => {
		if (onFailure && typeof onFailure === "function") {
			onFailure(result);
		}
	},
});

const joinEvent = (token, joinInfo, onSuccess, onFailure, successNotification) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/join/${token}`,
	method: "post",
	body: joinInfo,
	onSuccess: (dispatch) => {
		dispatch(fetchEvents());
		if (onSuccess && typeof onSuccess === "function") {
			onSuccess();
		}
	},
	onFailure: onFailure,
	successNotification: successNotification,
});

export const fetchEvents = (types = [ EventType.CURRICULUM, EventType.STANDALONE_EVENT ]) => {
	const onlyRootEvents = types.length === 2 && types.includes(EventType.CURRICULUM) && types.includes(EventType.STANDALONE_EVENT);
	const updateStrategy = onlyRootEvents ? { updatePropForArray: { key: "parent", value: undefined } } : { updatePropForObj: { idSelector: event => event.id } };
	return {
		type: ActionType.API_REQUEST,
		url: `${BASE_URL}/events?type=${types.join()}`,
		method: "get",
		resource: "events",
		...updateStrategy
	};
};

export const fetchEvent = (id, resolveParents, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${id}`,
	method: "get",
	resource: "events",
	onSuccess: (dispatch, event) => {
		if (onSuccess) onSuccess(dispatch, event);
		if (resolveParents && event.parent) {
			dispatch(fetchEvent(event.parent, resolveParents));
		}
	}
});

export const fetchEventWithUnits = (id, isPreview) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${id}`,
	method: "get",
	resource: "events",
	onSuccess: (dispatch, event) => {
		if (
			(isPreview || !event.exam || event.timeLimitedStartDate) &&
			event.type !== EventType.CURRICULUM
		) {
			dispatch(fetchEventUnits(id));
		}
	}
});

export const fetchEventUnits = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/units/`,
	method: "get",
	resource: "eventUnits",
	updatePropForArray: {
		key: "eventId",
		value: eventId,
	},
});

export const fetchCurriculumEvents = parentId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${parentId}/curriculumevents/`,
	method: "get",
	resource: "events",
	updatePropForArray: {
		key: "parent",
		value: parentId
	}
});

const startEvent = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/start`,
	body: { eventId },
	method: "POST",
	onSuccess: dispatch => dispatch(fetchEventWithUnits(eventId))
});

const fetchChallenge = (challengeId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}`,
	method: "get",
	resource: "challenges",
	onSuccess: (dispatch, challenge) => {
		const { sections, solution, eventId, id } = challenge;
		if (resolveParents && eventId) {
			dispatch(fetchEvent(eventId, resolveParents));
		}
		if (Array.isArray(sections) && sections.length) {
			sections.forEach(s => s.id && dispatch(fetchSteps(challengeId, s.id)));
		}
		if (solution && solution.id && id) {
			dispatch(fetchSolutionComments(solution.id, id));
		}
	}
});

const fetchTheory = (theoryId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/theories/${theoryId}`,
	method: "get",
	resource: "theories",
	onSuccess: (dispatch, theory) => {
		const { eventId } = theory;
		if (resolveParents && eventId) dispatch(fetchEvent(eventId, resolveParents));
	}
});

const fetchQuiz = (quizId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}`,
	method: "get",
	resource: "quizzes",
	onSuccess: (dispatch, quiz) => {
		const { eventId } = quiz;
		if (eventId)
			dispatch(
				fetchEvent(eventId, resolveParents, (dispatch, event) => {
					if (!event.exam) dispatch(fetchQuizComments(quiz.id));
				})
			);
	}
});

const fetchQuizAnswers = quizId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/answers/`,
	method: "get",
	resource: "quizAnswers",
	setFieldOnObject: {
		key: "quizId",
		value: quizId
	}
});

const fetchQuizComments = quizId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/comments/`,
	method: "get",
	resource: "quizComments",
	updatePropForArray: {
		key: "quizId",
		value: quizId
	},
	setFieldOnObject: {
		key: "quizId",
		value: quizId
	}
});

const fetchQuizGradingInstructions = quizId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/gradingInstructions/`,
	method: "get",
	resource: "questionGradingInstructions",
	updatePropForArray: {
		key: "quizId",
		value: quizId
	},
	setFieldOnObject: {
		key: "quizId",
		value: quizId
	}
});

const postFinishQuiz = (quizId, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/finish`,
	body: { quizId },
	method: "POST",
	onSuccess: dispatch => {
		dispatch(fetchQuiz(quizId));
		if (onSuccess && typeof onSuccess === "function") {
			onSuccess();
		}
	},
	onFailure
});

const postSolutionComment = (comment, challengeId, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/comments/`,
	body: comment,
	method: "post",
	resource: "solutionComments",
	onSuccess: onSuccess,
	onFailure
});

const fetchSteps = (challengeId, sectionId) => {
	return {
		type: ActionType.API_REQUEST,
		url: `${BASE_URL}/challenges/${challengeId}/sections/${sectionId}/steps/`,
		method: "get",
		resource: "steps",
		updatePropForArray: {
			key: "sectionId",
			value: sectionId
		}
	};
};

const fetchStep = (challengeId, sectionId, stepId, onSuccess) => {
	return {
		type: ActionType.API_REQUEST,
		url: `${BASE_URL}/challenges/${challengeId}/sections/${sectionId}/steps/${stepId}`,
		method: "get",
		resource: "steps",
		updatePropForArray: {
			key: "sectionId",
			value: sectionId
		},
		onSuccess: onSuccess,
	};
};

const fetchStepTitles = (challengeId, sectionId, onSuccess) => {
	return {
		type: ActionType.API_REQUEST,
		url: `${BASE_URL}/challenges/${challengeId}/sections/${sectionId}/steps?include_titles=true`,
		method: "get",
		resource: "steps",
		updatePropForArray: {
			key: "sectionId",
			value: sectionId
		},
		onSuccess: onSuccess,
	};
};

const fetchSolutionComments = (solutionId, challengeId) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/comments/`,
	method: "get",
	resource: "solutionComments",
	updatePropForArray: {
		key: "solutionId",
		value: solutionId
	}
});

const updateResourceState = (
	challengeId,
	requestedOperation,
	resourceId,
	onSuccess,
	onBefore,
	onDone
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/resources/${resourceId}`,
	body: { requestedOperation },
	method: "put",
	resource: "challenges",
	updateField: { key: "resources", entityId: challengeId },
	onSuccess: (dispatch, resource) => {
		if (onSuccess) onSuccess(resource);
		if (onDone) onDone();
	},
	onFailure: onDone,
	onBefore: onBefore
});

export const postEventRegistration = (eventId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/register`,
	body: { eventId },
	method: "post",
	resource: "events",
	onSuccess: (dispatch, event) => {
		if (onSuccess) onSuccess(event);
	}
});

const updateQuestionAnswer = (quizId, questionId, answer, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/questions/${questionId}/answer`,
	body: answer,
	method: "put",
	resource: "quizAnswers",
	updatePropForObj: { idSelector: answerObj => answerObj.questionUuid },
	setFieldOnObject: {
		key: "quizId",
		value: quizId
	},
	onSuccess: onSuccess,
	onFailure: onFailure
});

const updateQuestionAnswers = (quizId, answer, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/questions/answers/`,
	body: answer,
	method: "put",
	resource: "quizAnswers",
	updatePropForObj: { idSelector: answerObj => answerObj.questionUuid },
	setFieldOnObject: {
		key: "quizId",
		value: quizId
	},
	onSuccess: onSuccess,
	onFailure: onFailure
});

const finishExamen = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/finish`,
	body: { eventId },
	method: "post",
	onSuccess: dispatch => dispatch(fetchEvent(eventId))
});

export default {
	fetchEventJoinInfo,
	joinEvent,
	fetchEvents,
	fetchEvent,
	fetchEventUnits,
	fetchEventWithUnits,
	fetchCurriculumEvents,
	startEvent,
	fetchChallenge,
	fetchTheory,
	fetchQuiz,
	fetchQuizAnswers,
	fetchQuizComments,
	fetchQuizGradingInstructions,
	fetchSteps,
	fetchStep,
	fetchStepTitles,
	postFinishQuiz,
	postSolutionComment,
	updateResourceState,
	postEventRegistration,
	updateQuestionAnswer,
	updateQuestionAnswers,
	finishExamen
};
