import { NonNullish } from "@hlcr/core/types";
import { Badge } from "@hlcr/mui/Badge";
import { Button } from "@hlcr/mui/Button";
import { useIntl } from "@hlcr/ui/Intl";
import AttachmentIcon from "@material-ui/icons/Attachment";
import FileUploadIcon from "@material-ui/icons/CloudUpload"; // FileUpload needs to be changed (not cloud...)
import cx from "classnames";
import * as React from "react";
import { useDropzone } from "react-dropzone";

import { useUploadStyles } from "./style";

const SIZE_MB = 1024 * 1000;

export interface ProcessedFile {
	name: string;
	size: number;
	type?: string;
	data: string;
	byteData: ArrayBuffer;
}


interface UploadProps {
	type: "image" | "file";
	imageUrl?: string;
	maxFileSize?: number;
	disabled?: boolean;
	file?: ProcessedFile;
	rootClassName?: string;
	handleRawFile?: (file: File | null) => void,
	handleProcessedFile?: (file: ProcessedFile | null) => void,
}

const reader = new FileReader();
const byteReader = new FileReader();

export const Upload = ({ type, file, imageUrl, maxFileSize, disabled, rootClassName, handleRawFile, handleProcessedFile }: UploadProps) => {
	const classes = useUploadStyles();
	const intl = useIntl();

	const onDrop = (files: File[]) => {
		if (!files?.length) {
			return;
		}

		handleRawFile?.(files[0]);
		processFile(files[0], handleProcessedFile);
	};

	const onRemove = (event: React.MouseEvent) => {
		event.stopPropagation();
		handleRawFile?.(null);
		handleProcessedFile?.(null);
	};

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		fileRejections,
	} = useDropzone({
		accept: type === "image" ? "image/*" : undefined,
		maxSize: maxFileSize,
		multiple: false,
		disabled,
		onDropAccepted: onDrop,
	});

	let text: string | React.ReactElement = `or drag & drop ${type} here`;
	if (file) {
		text = (
			<Badge color="info" large={true}>
				{file.name}
				{file?.size ? <><br /><span style={ { whiteSpace: "nowrap" } }>{Math.round(file.size / 1024)} kB</span></> : undefined}
			</Badge>
		);
	} else {
		if (isDragActive) {
			text = "Drop it like it's hot!";
		} else if (fileRejections?.length > 0 && !!maxFileSize) {
			fileRejections[0].errors.forEach((err) => {
				if (err.code === "file-too-large") {
					text = intl.fm("mui.upload.error.file-too-large", "", { type, maxFileSizeInMB: maxFileSize / SIZE_MB });
				}
			});
		}
	}

	const style: { backgroundImage: string | undefined, backgroundSize: string, backgroundPosition: string, } = {
		backgroundImage: undefined,
		backgroundSize: "cover",
		backgroundPosition: "center",
	};
	if (file && file.type?.startsWith("image/") && file.data) {
		style.backgroundImage = file.data.startsWith("data:")
			? `url('${file.data}')`
			: `url('data:${file.type ?? "image/jpeg"};base64,${file.data}')`;
	} else if (imageUrl) {
		style.backgroundImage = `url('${imageUrl}')`;
	}

	return (
		<div {...(rootClassName && { className: rootClassName })}>
			<div {...getRootProps({ className: cx(classes.root, { [classes.accept]: file, ...(type === "image" && { [classes.backGroundImage]: file }) }), style: style.backgroundImage ? style : undefined })}>
				<input {...getInputProps()} />
				<ZoneContent
					type={type}
					accepted={!!file}
					disabled={disabled}
					onRemove={onRemove}
				>
					{text}
				</ZoneContent>
			</div>
		</div>
	);
};

const processFile = (file: File, handler?: UploadProps["handleProcessedFile"]) => {
	if (!handler) {
		return;
	}

	if (!reader.readAsBinaryString) {
		processFileLegacy(file, handler);
		return;
	}

	byteReader.onload = () => reader.readAsBinaryString(file);
	reader.onload = () => {
		console.log(file.type);
		handler({
			name: file.name,
			size: file.size,
			type: file.type,
			data: btoa(reader.result as string),
			byteData: byteReader.result as ArrayBuffer,
		});
	};
	byteReader.readAsArrayBuffer(file);
};

const processFileLegacy = (file: File, handler: NonNullish<UploadProps["handleProcessedFile"]>) => {
	// Catering for IE 10/11
	reader.onload = (evt) => {
		let result = "";
		const bytes = new Uint8Array(evt.target!.result! as ArrayBufferLike);

		for (let i = 0; i < bytes.byteLength; i++) {
			result += String.fromCharCode(bytes[i]);
		}

		handler({
			name: file.name,
			size: file.size,
			data: btoa(result),
			byteData: reader.result as ArrayBuffer,
		});
	};
	reader.readAsArrayBuffer(file);
};

interface ZoneContentProps {
	type: "image" | "file";
	accepted: boolean;
	disabled?: boolean;
	onRemove: (event: React.MouseEvent) => void;
}

const ZoneContent: React.FC<ZoneContentProps> = ({ children, accepted, disabled, type, onRemove }) => {
	const classes = useUploadStyles();
	return (
		<div className={classes.zoneContent}>
			{accepted
				? type === "file" && <AttachmentIcon className={classes.zoneIcon} />
				: <FileUploadIcon className={classes.zoneIcon} />
			}
			{!accepted ? (
				<Button color="info" size="sm" disabled={disabled}>
					Select {type}
				</Button>
			) : (
				<Button color="dangerNoBackground" size="sm" disabled={disabled} onClick={onRemove}>
					Remove {type}
				</Button>
			)}
			<div className={classes.zoneText}>{children}</div>
		</div>
	);
};
