export const teacherInitState = {
	teacherEvents: {
		pending: false,
		data: []
	},
	teacherEventUnits: {
		pending: false,
		data: []
	},
	teacherChallenges: {
		pending: false,
		data: []
	},
	teacherTheories: {
		pending: false,
		data: []
	},
	teacherQuizzes: {
		pending: false,
		data: []
	},
	teacherSolutions: {
		pending: false,
		data: []
	},
	teacherSolutionUserFlags: {
		pending: false,
		data: []
	},
	teacherSolutionComments: {
		pending: false,
		data: []
	},
	teacherSteps: {
		pending: false,
		data: []
	},
	teacherGradingInstruction: {
		pending: false,
		data: []
	},
	teacherQuizSolutions: {
		pending: false,
		data: []
	},
	teacherQuizAnswers: {
		pending: false,
		data: []
	},
	teacherQuizGradingInstructions: {
		pending: false,
		data: []
	},
	teacherQuizComments: {
		pending: false,
		data: []
	},
	teacherEventParticipants: {
		pending: false,
		data: []
	},
	teacherEventProgress: {
		pending: false,
		data: []
	},
	teacherVms: {
		pending: false,
		data: []
	}
};
