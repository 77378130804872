import { ONE_HUNDRED_PERCENT, percent, roundToDecimals } from "@hlcr/core/numeric";
import { roseColor, successColor, warningColor, grayColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { useSolutionStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionStyle";
import { useIntl } from "@hlcr/ui/Intl";
import { makeStyles, Slider } from "@material-ui/core";


const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = ONE_HUNDRED_PERCENT;

interface PointSliderProps {
	value: number;
	maxPoints: number;
	flagPoints: number;
	writeupWeight: number;
	onChange: (value: number) => void;
	disabled: boolean;
}

const PointSlider = ({ value, maxPoints, flagPoints, writeupWeight, onChange, disabled }: PointSliderProps) => {
	const classes = useSolutionStyles();
	const intl = useIntl();

	let color;
	if (disabled) {
		color = grayColor;
	} else if (value === MIN_PERCENTAGE) {
		color = roseColor;
	} else if (value === MAX_PERCENTAGE) {
		color = successColor;
	} else {
		color = warningColor;
	}
	const localClasses = useStyles(color);

	const handleChange = (event: React.ChangeEvent<{}>, value: number | number[]) => onChange(value as number);
	const valueLabelFormat = (value: number) => `${value}%`;

	const writeupPoints = roundToDecimals(percent(value * writeupWeight) * maxPoints, 0);
	const marks = [ { value, label: value + "%" } ];
	value >= 5 && marks.push({ value: 0, label: "0%" });
	(value <= 20 || value >= 30) && marks.push({ value: 25, label: "25%" });
	(value <= 45 || value >= 55) && marks.push({ value: 50, label: "50%" });
	(value <= 70 || value >= 80) && marks.push({ value: 75, label: "75%" });
	value <= 95 && marks.push({ value: 100, label: "100%" });

	return (
		<div className={classes.pointSliderBox}>
			<div className={classes.points}>
				{writeupWeight < 1 && <>
					{flagPoints ? <span>{intl.fm("teacher.solution.grading.flagPoints", undefined, { flagPoints })} + </span> : null}
					{intl.fm("teacher.solution.grading.writeupPoints", undefined, { writeupPoints })}
				</>}
			</div>
			<div className={classes.labels}>
				<div>
					{intl.fm("teacher.solution.grading.totalLabel")}
					<strong>{flagPoints + writeupPoints}</strong>
					{intl.fm("teacher.solution.grading.ofMaxPoints", undefined, { maxPoints })}
				</div>
			</div>
			<Slider
				className={classes.slider}
				value={value}
				min={MIN_PERCENTAGE}
				max={MAX_PERCENTAGE}
				getAriaValueText={valueLabelFormat}
				onChange={handleChange}
				aria-labelledby="discrete-slider-always"
				step={1}
				marks={marks}
				valueLabelDisplay="off"
				classes={{ ...localClasses }}
				disabled={disabled}
			/>
		</div>
	);
};

const useStyles = (color: string) => makeStyles({
	root: { height: 16 },
	thumb: {
		height: 20,
		width: 20,
		marginTop: -8,
		marginLeft: -12,
		backgroundColor: "#fff",
		border: "2px solid",
	},
	track: {
		height: 4,
		borderRadius: 4,
		color,
	},
	rail: {
		height: 4,
		borderRadius: 4,
		color,
	},
})();

export default PointSlider;
