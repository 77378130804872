import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import FilterListIcon from "@material-ui/icons/FilterList";
import cx from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import React from "react";
import Measure from "react-measure";

import enhancedTableStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/enhancedTableStyle";
import { Button } from "@hlcr/mui/Button";

import Tooltip from "../CustomTooltip/CustomTooltip";

export const DIRECTION = {
	ASC: "asc",
	DESC: "desc"
};

export class EnhancedTableBase extends React.Component {
	constructor(props) {
		super(props);

		const sortColumnId = this.getStoredSortColumn() || props.defaultSortColumnId;
		const sortDirection = this.getStoredSortDirection() || props.defaultSortDirection || DIRECTION.ASC;

		this.state = {
			sortColumnId,
			sortDirection,
			filter: null,
			filterValue: null,
			openedFilterDialog: null,
			currentPage: 0,
			dimensions: {
				width: -1,
				height: -1
			}
		};


	}

	componentDidMount() {
		const { disablePS, paginate } = this.props;
		if (!disablePS && !paginate)
			this.ps = new PerfectScrollbar(this.refs.tableContainer, {
				suppressScrollX: false,
				suppressScrollY: true,
				wheelPropagation: true
			});
	}

	componentDidUpdate(prevProps, prevState) {
		const { filterValue } = this.state;
		const { tableData } = this.props;

		if (this.ps) this.ps.update();
		if (filterValue !== prevState.filterValue) this.setState({ currentPage: 0 });
		if (prevProps.tableData !== tableData) this.setState({ currentPage: 0 });
	}

	componentWillUnmount() {
		this.ps && this.ps.destroy();
	}

	render() {
		const { currentPage } = this.state;
		const { classes, tableRenderer, tableHeaderColor, emptyContent, paginate, jumpButton, hideHeader } = this.props;

		const data = this.getDisplayData();

		const hasData = Array.isArray(data) && data.length > 0;
		const hasColStyling = tableRenderer.some(r => r.colStyle);

		return (
			<div className={classes.tableResponsive} ref="tableContainer">
				<Measure bounds onResize={contentRect => this.setState({ dimensions: contentRect.bounds })}>
					{({ measureRef }) => (
						<div ref={measureRef}>
							<Table className={classes.table}>
								{hasColStyling && (
									<colgroup>
										{tableRenderer.map((ren, i) => (<col key={i} style={ren.colStyle} />))}
									</colgroup>
								)}
								{!hideHeader && <TableHead className={classes[tableHeaderColor]}>
									<TableRow key={"HeadRow"} className={classes.tableRow}>
										{tableRenderer && tableRenderer.map((column, key) => this.renderColumnHeader(column, key))}
									</TableRow>
								</TableHead>}
								<TableBody>
									{hasData && applyPaging(data, currentPage, paginate).map((row, key) => this.renderTableRow(row, key))}
								</TableBody>
							</Table>
							{paginate && (
								<div className={classes.pagingBar}>
									{getPageNavigation(data.length, currentPage, paginate).map(
										(page, i) => {
											const key = `${currentPage}-${page}-${i}`;
											return page === null ? (
												<div key={key} className={classes.pagingEllipsis}>
													…
												</div>
											) : (
												<Button
													key={key}
													onClick={() => this.setState({ currentPage: page })}
													color={page === currentPage ? "info" : "infoNoBackground"}
													size="sm"
													round
												>
													{page + 1}
												</Button>
											);
										}
									)}
								</div>
							)}
							{jumpButton && (
								<div className={classes.jumpButtonContainer}>
									<Button onClick={this.jumpToResult} size="sm" color="infoNoBackground">{jumpButton.label}</Button>
								</div>
							)}
							{!hasData && emptyContent}
						</div>
					)}
				</Measure>
			</div>
		);
	}

	getPersistencePrefix = () => {
		const { persistSortFor } = this.props;
		return persistSortFor && `${persistSortFor}::TableSorter`;
	};

	getStoredSortColumn = () => {
		const prefix = this.getPersistencePrefix();
		return prefix && localStorage.getItem(`${prefix}::column`);
	};

	getStoredSortDirection = () => {
		const prefix = this.getPersistencePrefix();
		return prefix && localStorage.getItem(`${prefix}::direction`);
	};

	setStoredSortColumn = column => {
		const prefix = this.getPersistencePrefix();
		return prefix && localStorage.setItem(`${prefix}::column`, column);
	};

	setStoredSortDirection = direction => {
		const prefix = this.getPersistencePrefix();
		return prefix && localStorage.setItem(`${prefix}::direction`, direction);
	};

	getDisplayData = () => {
		const { sortColumnId, sortDirection, filter, filterValue } = this.state;
		const { tableData, tableRenderer } = this.props;

		const filteredData = filter ? tableData.filter(row => filter(row, filterValue)) : tableData;

		return applySorting(filteredData, tableRenderer, sortColumnId, sortDirection);
	};

	jumpToResult = () => {
		const { jumpButton, paginate } = this.props;

		if (!jumpButton || !paginate) return;

		const itemIndex = this.getDisplayData().findIndex(jumpButton.findPredicate);
		if (itemIndex < 0) return;

		const itemPage = Math.floor(itemIndex / paginate);
		this.setState({ currentPage: itemPage });
	};

	handleFilterOpen = filterName => () => this.setState({ openedFilterDialog: filterName });

	handleFilterApply = column => value => () => this.setState({ defaultFilter: undefined, filter: column.filter.func, filterValue: value, openedFilterDialog: null });

	handleFilterClose = () => this.setState({ openedFilterDialog: null });

	createSortHandler = column => () => {
		const { sortDirection, sortColumnId } = this.state;

		const newSortDirection = sortColumnId === column.id ? flipDirection(sortDirection) : DIRECTION.ASC;

		this.setState({
			sortColumnId: column.id,
			sortDirection: newSortDirection
		});

		this.setStoredSortColumn(column.id);
		this.setStoredSortDirection(newSortDirection);
	};

	getColumnFilterButton(column) {
		const { classes } = this.props;
		const { filterValue } = this.state;
		if (!column.filter || !column.filter.dialog) return null;

		return (
			<React.Fragment>
				<Button color={filterValue ? "primaryNoBackground" : "defaultNoBackground"} onClick={this.handleFilterOpen(column.filter.name)} customClass={classes.filterButton}>
					<FilterListIcon />
				</Button>
				<FilterDialog handleClose={this.handleFilterClose} open={this.state.openedFilterDialog === column.filter.name} content={column.filter.dialog(this.handleFilterApply(column))} />
			</React.Fragment>
		);
	}

	renderColumnHeader = (column, columnKey) => {
		const { sortDirection, sortColumnId, dimensions: { width } } = this.state;
		const { customHeadCellClasses, customHeadClassesForCells, classes } = this.props;

		if (column.hideBelowWidth > width) return null;

		const tableCellClasses = cx(
			classes.tableHeadCell,
			classes.tableCell,
			classes.tableHeadFontSize,
			{
				[customHeadCellClasses[customHeadClassesForCells.indexOf(columnKey)]]:
				customHeadClassesForCells.indexOf(columnKey) !== -1
			}
		);
		const filter = this.getColumnFilterButton(column);

		if (column.sort) {
			const isSorted = column.id === sortColumnId;
			return (
				<TableCell key={columnKey} align={column.align} className={tableCellClasses} sortDirection={isSorted ? sortDirection : undefined}>
					<Tooltip title="Sort" placement={column.numeric || column.align === "right" ? "top-end" : "top-start"} enterDelay={300}>
						<TableSortLabel active={isSorted} direction={sortDirection} onClick={this.createSortHandler(column)}>
							{column.title}
						</TableSortLabel>
					</Tooltip>
					{filter}
				</TableCell>
			);
		}

		return (
			<TableCell align={column.align} className={tableCellClasses} key={columnKey}>
				{column.title}
				{filter}
			</TableCell>
		);
	};

	calculateCellClasses = rowIndex => {
		const { coloredColls, colorsColls, customClassesForCells, customCellClasses, classes } = this.props;
		return cx(classes.tableCell, {
			[classes[colorsColls[coloredColls.indexOf(rowIndex)]]]:
			coloredColls.indexOf(rowIndex) !== -1,
			[customCellClasses[customClassesForCells.indexOf(rowIndex)]]:
			customClassesForCells.indexOf(rowIndex) !== -1
		});
	};

	renderTableRow = (rowIn, rowKey) => {
		const { classes, tableRenderer, disabledRow, hover, striped } = this.props;
		const { dimensions: { width } } = this.state;

		let rowColor = "";
		let rowColored = false;
		let row = rowIn;

		if (row.color !== undefined) {
			rowColor = row.color;
			rowColored = true;
			row = row.data;
		}
		const tableRowClasses = cx({
			[classes.tableRowHover]: hover,
			[classes[rowColor + "Row"]]: rowColored,
			[classes.tableStripedRow]: striped && rowKey % 2 === 0
		});

		if (tableRenderer) {
			return (
				<TableRow key={rowKey} hover={hover} className={cx(classes.tableRow, tableRowClasses, { [classes.disabledStyle]: disabledRow && disabledRow(row) })}>
					{tableRenderer.filter(columnRenderer => columnRenderer.hideBelowWidth === undefined || columnRenderer.hideBelowWidth <= width)
						.map((columnRenderer, fieldIndex) => (
							<TableCell align={columnRenderer.align} className={this.calculateCellClasses(fieldIndex)} key={fieldIndex}>
								{columnRenderer.renderCell(row, fieldIndex)}
							</TableCell>
						))}
				</TableRow>
			);
		}

		return (
			<TableRow key={rowKey} hover={hover} className={cx(classes.tableRow, tableRowClasses)}>
				{row.map((field, fieldIndex) => (
					<TableCell className={this.calculateCellClasses(fieldIndex)} key={fieldIndex}>
						{field}
					</TableCell>
				))}
			</TableRow>
		);
	};
}

const sortByRenderCell = (data, renderCell, sortDirection) => {
	sortDirection === DIRECTION.DESC
		? data.sort((a, b) => (renderCell(b) < renderCell(a) ? -1 : 1))
		: data.sort((a, b) => (renderCell(a) < renderCell(b) ? -1 : 1));
};

const sortByFunction = (data, sort, sortDirection) => {
	sortDirection === DIRECTION.DESC
		? data.sort(sort)
		: data.sort((a, b) => sort(b, a));
};

const applySorting = (data, tableRenderer, columnId, sortDirection) => {
	const orderedData = data.slice();
	const column = tableRenderer.find(r => r.id === columnId);

	if (column && column.sort) {
		if (typeof column.sort === "boolean") {
			sortByRenderCell(orderedData, column.renderCell, sortDirection);
		} else {
			sortByFunction(orderedData, column.sort, sortDirection);
		}
	}

	return orderedData;
};

const flipDirection = direction => direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;

export const applyPaging = (rows, currentPage, rowsPerPage) => {
	if (!rowsPerPage) return rows;
	const startIndex = currentPage * rowsPerPage;
	return rows.slice(startIndex, startIndex + rowsPerPage);
};

export const getPageNavigation = (rowCount, currentPage, rowsPerPage) => {
	const indexes = [];

	if (!rowsPerPage) return indexes;

	const pageCount = Math.ceil(rowCount / rowsPerPage);

	if (pageCount <= 1) return indexes;

	indexes.push(currentPage);

	const MAX_DISTANCE = 2;
	const MAX_ITEMS = MAX_DISTANCE * 2 + 1;
	let distance = 1;
	while (true) {
		const leftIndex = currentPage - distance;
		const rightIndex = currentPage + distance;
		const leftOutOfRange = leftIndex < 0;
		const righOutOfRange = rightIndex >= pageCount;

		if (leftOutOfRange && righOutOfRange) break;

		if (!leftOutOfRange) indexes.splice(0, 0, leftIndex);
		if (indexes.length >= MAX_ITEMS) break;

		if (!righOutOfRange) indexes.push(rightIndex);
		if (indexes.length >= MAX_ITEMS) break;

		distance++;
	}

	if (indexes[0] !== 0) {
		indexes.splice(0, 1, 0, null);
	}

	if (indexes[indexes.length - 1] !== pageCount - 1) {
		indexes.splice(-1, 1, null, pageCount - 1);
	}

	return indexes;
};

const FilterDialog = ({ content, handleClose, open }) => {
	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogContent>{content}</DialogContent>
		</Dialog>
	);
};

EnhancedTableBase.defaultProps = {
	tableHeaderColor: "gray",
	hover: false,
	colorsColls: [],
	coloredColls: [],
	striped: false,
	customCellClasses: [],
	customClassesForCells: [],
	customHeadCellClasses: [],
	customHeadClassesForCells: [],
};

EnhancedTableBase.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHeaderColor: PropTypes.oneOf([
		                                  "warning",
		                                  "primary",
		                                  "danger",
		                                  "success",
		                                  "info",
		                                  "rose",
		                                  "gray",
	                                  ]),
	tableData: PropTypes.array,
	tableRenderer: PropTypes.arrayOf(PropTypes.object).isRequired,
	persistSortFor: PropTypes.string,
	hover: PropTypes.bool,
	coloredColls: PropTypes.arrayOf(PropTypes.number),
	colorsColls: PropTypes.array,
	customCellClasses: PropTypes.arrayOf(PropTypes.string),
	customClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
	customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
	striped: PropTypes.bool,
	emptyContent: PropTypes.node,
	defaultSortColumnId: PropTypes.string,
	defaultSortDirection: PropTypes.string,
	disablePS: PropTypes.bool,
	filter: PropTypes.shape({
		                        key: PropTypes.string.isRequired,
		                        func: PropTypes.func.isRequired,
		                        dialog: PropTypes.func.isRequired,
	                        }),
	paginate: PropTypes.number,
	jumpButton: PropTypes.shape({
		                            label: PropTypes.string.isRequired,
		                            findPredicate: PropTypes.func.isRequired,
	                            }),
};

export const EnhancedTable = withStyles(enhancedTableStyle)(EnhancedTableBase);
export default EnhancedTable;
