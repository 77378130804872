import { EnumDetail, EnumWithColor } from "@hlcr/app/enum/EnumBase";

export enum BugBountyTriageStatusEnum {
	ACCEPTED = "ACCEPTED",
	REJECTED = "REJECTED",
	UNKNOWN = "UNKNOWN",
}

export const BUG_BOUNTY_TRIAGE_STATUSES: EnumDetail<BugBountyTriageStatusEnum, EnumWithColor> = {
	ACCEPTED: { title: "bugBounty.triageState.accepted", color: "success" },
	REJECTED: { title: "bugBounty.triageState.rejected", color: "error" },
	UNKNOWN: { title: "bugBounty.triageState.unknown", color: "gray" },
};

export const getBugBountyTriageStatusEnumDetail = (bountyStatus?: BugBountyTriageStatusEnum) => {
	if (bountyStatus === undefined || !Object.keys(BUG_BOUNTY_TRIAGE_STATUSES).includes(bountyStatus)) {
		console.debug(`invalid bounty triage status: ${bountyStatus}`);
		return;
	}
	return BUG_BOUNTY_TRIAGE_STATUSES[bountyStatus];
};
