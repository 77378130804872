import { Page } from "@hlcr/core";
import { Badge } from "@hlcr/mui";
import { PaletteColorKey } from "@hlcr/mui/theme/hacking-lab.theme";
import { useIntl } from "@hlcr/ui/Intl";
import { IconButton, LinearProgress, Tooltip } from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef, GridFilterModel, GridOverlay, GridRowParams, GridSortModel } from "@material-ui/data-grid";
import BugReportIcon from "@material-ui/icons/BugReport";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchReportedBugs } from "bugBounty/bugBounty.actions";
import styles from "bugBounty/components/reportedBugsTable.module.scss";
import { BugBountyReportSummary } from "bugBounty/models/BugBountyReport";
import { BugBountyStatusEnum, getBugBountyStatusEnumDetail } from "bugBounty/models/BugBountyStatusEnum";
import CustomInput from "components/CustomInput/CustomInput";
// eslint-disable-next-line import/no-named-as-default -- Suppress warning from old JSX import
import NoData from "components/NoData/NoData";
import { formatFullDate } from "helper/dateCalc";


function CustomLoadingOverlay() {
	return (
		<GridOverlay>
			<div style={{ position: "absolute", top: 0, width: "100%" }}>
				<LinearProgress />
			</div>
		</GridOverlay>
	);
}

function CustomNoBugsOverlay() {
	return (
		<GridOverlay>
			<NoData Icon={BugReportIcon} text={"No Bugs reported yet."} />
		</GridOverlay>
	);
}

interface ReportedBugsTableProps {
	eventId?: number;
}

export const ReportedBugsTable = (props: ReportedBugsTableProps) => {
	const dispatch = useDispatch();
	const intl = useIntl();

	// local states
	// data
	const [ loading, setLoading ] = useState<boolean>(false);
	const [ foundBugs, setFoundBugs ] = useState<BugBountyReportSummary[]>([]);
	const [ filterQuery, setFilterQuery ] = useState<string>("");
	const [ sort, setSort ] = useState<string>("");
	const [ filterStates, setFilterStates ] = useState<BugBountyStatusEnum[]>([]);

	// pagination
	const [ page, setPage ] = useState(0);
	const [ rowCount, setRowCount ] = useState(0);
	const [ pageSize, setPageSize ] = useState(10);


	useEffect(() => {
		console.log("filterQuery", filterQuery);
		console.log("filterStatus", filterStates);
	}, [ filterQuery, filterStates ]);


	const handleOnReportedBugsReceived = (reportedBugsPage: Page<BugBountyReportSummary>) => {
		setFoundBugs(reportedBugsPage.content);
		setRowCount(reportedBugsPage.totalElements);

		setLoading(false);
	};

	const reloadFoundBugs = () => {
		setLoading(true);
		dispatch(fetchReportedBugs(page, pageSize, props.eventId, filterQuery, sort, filterStates, handleOnReportedBugsReceived));
	};

	const onEditBugClick = (foundBug: BugBountyReportSummary) => {
		// TODO-BOUNTY: open the edit/view page
	};

	useEffect(() => {
		reloadFoundBugs();
	}, [ page, pageSize ]);


	const history = useHistory();

	const handleOpenBug = (eventId: number, bugId: string) => {
		console.log(`open bug report: ${bugId}`);
		history.push(`/bugbounty/${eventId}/bug/${bugId}`);
		// TODO: route to clicked report
		// TODO-BOUNTY: open the edit/view page
	};

	const handleOpenEvent = (eventId: number) => {
		console.log(`open event: ${eventId}`);
		history.push(`/bugbounty/${eventId}`);
	};

	const renderActionsCell = (params: GridCellParams) => {
		const rowBug = params.row as BugBountyReportSummary;

		// const handleEdit = () => handleEdit([ rowMessage.id ]);
		// const handleMarkAsRead = () => handleMarkRead([ rowMessage.id ]);
		// const handleMarkAsUnread = () => dispatch(inboxApi.markMessagesAsUnread([ rowMessage.id ], reloadMessages));

		const onEditBugClicks = () => {

		};
		return <>
			<Tooltip title={intl.fm("bugBounty.foundBugsTable.openBug")}>
				<IconButton onClick={
					(event) => {
						event.stopPropagation();
						handleOpenBug(rowBug.programId, rowBug.id!);
					}}>
					<OpenInNewIcon />
				</IconButton>
			</Tooltip>
		</>;

		// return (<>
		// <>
		// // 				<TableIconButton
		// 	// 					onClick={() => {
		// 	// 						onEditBugClick(foundBug);
		// 	// 					}}
		// 	// 					title={intl.fm("bugBounty.foundBugsTable.openBug")}
		// 	// 					color="info"
		// 	// 					faIcon="pencil-alt"
		// 	// 					disabled={false}
		// 	// 				><></>
		// 	// 				</TableIconButton>
		// 	// 			</>
		// 	<Tooltip title={"Delete"}>
		// 		<IconButton onClick={()=>onEditBugCl}>
		// 			<DeleteOutlined />
		// 		</IconButton>
		// 	</Tooltip>
		// </>);
	};

	const columns: GridColDef[] = [
		{
			field: "title",
			headerName: intl.fm("bugBounty.foundBugsTable.title"),
			width: 160,
			flex: 3,
			filterable: true,
			sortable: true,
		},
		{
			field: "programName",
			headerName: intl.fm("bugBounty.foundBugsTable.program"),
			flex: 2,
			width: 180,
			filterable: true,
			sortable: true,
			cellClassName: styles.programNameCell,
		},
		{
			field: "state",
			headerName: intl.fm("bugBounty.foundBugsTable.status"),
			width: 150,
			filterable: true,
			sortable: true,
			valueGetter: params => intl.fm(getBugBountyStatusEnumDetail((params.row as BugBountyReportSummary).state)?.title ?? ""),
			renderCell: params => <Badge color={(getBugBountyStatusEnumDetail((params.row as BugBountyReportSummary).state)?.color ?? "info") as PaletteColorKey}>{intl.fm(getBugBountyStatusEnumDetail((params.row as BugBountyReportSummary).state)?.title ?? "")}</Badge>,
		},
		{
			field: "cvss",
			headerName: intl.fm("bugBounty.foundBugsTable.cvss"),
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			width: 160,
			hideSortIcons: true,
			valueGetter: params => {
				const report = (params.row as BugBountyReportSummary);
				return `${report.hunterCvss.score ?? "-"} / ${report.triageCvss.score ?? "-"}`;
			},
		},
		{
			field: "bounty",
			headerName: intl.fm("bugBounty.foundBugsTable.bounty"),
			width: 140,
			sortable: true,
			filterable: false,
			disableColumnMenu: true,
		},
		{
			field: "updatedAt",
			type: "dateTime",
			headerName: intl.fm("bugBounty.foundBugsTable.lastUpdated"),
			width: 150,
			valueGetter: params => formatFullDate((params.row as BugBountyReportSummary).updatedAt),
			sortable: true,
			disableColumnMenu: true,
		},
		{
			field: "actions",
			headerName: intl.fm("user.inbox.header.actions"),
			width: 100,
			align: "center",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			hideSortIcons: true,
			renderCell: renderActionsCell,
		},
	];

	const onFilterInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;

		if (filterQuery !== value) {
			setFilterQuery(value);
		}
	};

	// default pagination handlers
	const handlePageChange = (page: number) => {
		setPage(page);
	};

	const handlePageSizeChange = (newPageSize: number) => {
		const firstElement = page * pageSize + 1;
		setPage(Math.floor(firstElement / newPageSize));
		setPageSize(newPageSize);
	};

	const handleSortModelChange = (model: GridSortModel) => {
		console.log("sort changed", model);
	};

	// const getRowClassName = (params: GridRowParams) => {
	// 	const rowClassNames = [];
	//
	// 	rowClassNames.push(`hlcr--inbox-${params.row.isRead ? "read" : "unread"}`);
	//
	// 	if (currentMessage === params.row) {
	// 		rowClassNames.push("hlcr--inbox-current");
	// 	}
	//
	// 	return rowClassNames.join(" ");
	// };

	// end default pagination handlers

	const handleOnRowClick = (params: GridRowParams, event: any) => {
		console.log("row clicked", params);
		const bugBountyReport = params.row as BugBountyReportSummary;
		handleOpenBug(bugBountyReport.programId, bugBountyReport.id!);
	};

	const handleOnCellClick = (params: GridCellParams, event: any) => {
		if (params.field === "programName") {
			event.stopPropagation();
			console.log(params);
			const bugBountyReport = params.row as BugBountyReportSummary;
			handleOpenEvent(bugBountyReport.programId);
		}
	};

	function handleFilterModelChange(model: GridFilterModel) {
		console.log("filter changed", model);
	}

	// TODO-BOUNTY: Replace with Datagrid
	return (
		<>
			<div className={styles.filters}>
				<CustomInput
					inputProps={{
						type: "inputSearch",
						onChange: onFilterInputChange,
						placeholder: intl.fm("common.labels.filter"),
					}}
				/>
			</div>
			<div style={{ height: 400, width: "100%" }}>
				<DataGrid
					// disableColumnFilter={true}
					rows={foundBugs}
					columns={columns}
					pagination={true}
					pageSize={pageSize}
					page={page}
					rowCount={rowCount}
					// checkboxSelection={true}
					disableSelectionOnClick={true}
					// onRowClick={handleOnRowClick}
					onRowDoubleClick={handleOnRowClick}
					onCellClick={handleOnCellClick}
					// onRowSelected={handleOnRowSelected}
					rowsPerPageOptions={[ 5, 10, 25 ]}
					paginationMode={"server"}
					sortingMode={"server"}
					filterMode={"server"}
					onPageChange={handlePageChange}
					onPageSizeChange={handlePageSizeChange}
					onSortModelChange={handleSortModelChange}
					loading={loading}
					components={{ LoadingOverlay: CustomLoadingOverlay, NoRowsOverlay: CustomNoBugsOverlay }}
					onFilterModelChange={model => handleFilterModelChange(model)}
					// getRowClassName={getRowClassName}
					// selectionModel={selectionModel}
					// onSelectionModelChange={handleSelectionChange}
				/>
			</div>
		</>
	);
};
