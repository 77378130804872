import { PaletteColorKey } from "@hlcr/mui/theme/hacking-lab.theme";
import * as React from "react";


import { useBadgeStyles } from "./style";

interface BadgeProps {
	color?: PaletteColorKey;
	large?: boolean;
}

export const Badge: React.FC<BadgeProps> = ({ color, large, children }) => {
	const classes = useBadgeStyles(color, large);
	return <span className={classes(color).badge}>{children}</span>;
};
