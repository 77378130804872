import { useIntl } from "@hlcr/ui/Intl";
import { Grid } from "@material-ui/core";

import { checkDenyRoles, checkHasAnyRole, checkHasRole } from "auth/authUtils";
import { RouteSummaryCard } from "routes/components/RouteSummaryCard";
import { TENANT_ADMIN_ROUTES } from "tenantAdmin/tenantAdmin.routes";

export const TenantAdmin = () => {
	const intl = useIntl();

	console.log("TenantAdmin");

	return <>
		<Grid container={true} spacing={3}>
			{
				Object.values(TENANT_ADMIN_ROUTES)
					.filter(route => !route.hasSidebarLink
						&& checkHasRole(route.needsRole)
						&& checkHasAnyRole(route.needsAnyRole)
						&& checkDenyRoles(route.prohibitIfAnyRole))
					.map(
						(route, index) => (
							<Grid key={index} item={true} md={6} xs={12}>
								<RouteSummaryCard route={route} />
							</Grid>
						),
					)
			}
		</Grid>
	</>;

};
