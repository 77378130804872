import { defaultSchema } from "@atlaskit/adf-schema/schema-default";
import { JSONTransformer } from "@atlaskit/editor-json-transformer";
import { MarkdownTransformer } from "@atlaskit/editor-markdown-transformer";
import { UUID } from "@hlcr/core/types";
import { Badge } from "@hlcr/mui";
import { Button } from "@hlcr/mui/Button";
import { PaletteColorKey } from "@hlcr/mui/theme/hacking-lab.theme";
import { ProcessedFile, Upload } from "@hlcr/mui/Upload";
import { useInput } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { useHlcrMarkdownStyles } from "@hlcr/ui/StyledMarkdown/style";
import { Grid, ListItemIcon, ListItemText, TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import BugReportIcon from "@material-ui/icons/BugReport";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { fetchHunterEvent, fetchReportedBug, submitNewBug, submitNewBugComment } from "bugBounty/bugBounty.actions";
import { BUG_BOUNTY_ROUTES, BugBountyBugReportRouteParams } from "bugBounty/bugBounty.routes";
import classes from "bugBounty/bugBountyBugReport.module.scss";
import { ReportNewBugForm } from "bugBounty/components/ReportNewBugForm";
import { BugBountyProgram } from "bugBounty/models/BugBountyProgram";
import { BugBountyReportComment, BugBountyReportSummary, NewBugBountyReport } from "bugBounty/models/BugBountyReport";
import { BugBountyStatusEnum, getBugBountyStatusEnumDetail } from "bugBounty/models/BugBountyStatusEnum";
import { BugBountyTriageStatusEnum, getBugBountyTriageStatusEnumDetail } from "bugBounty/models/BugBountyTriageStatusEnum";
import IconCard from "components/Cards/IconCard";
import RegularCard from "components/Cards/RegularCard";
import ModalWindow from "components/ModalWindow/ModalWindow";
import NoData from "components/NoData/NoData";
import { SolutionHistory } from "components/Solution/SolutionHistory";
import { createMemoize } from "helper/memoize";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { Event } from "shared/event/model/Event";

const getEvent = createMemoize((events: Event[], eventId: number) => {
	return events.find(event => event.id === eventId);
});

const getBugReport = createMemoize((reports: BugBountyReportSummary[], bugId: string | "new") => {
	return reports.find(report => report.id === bugId);
});

export const BugBountyBugReportBreadCrumb = () => {
	const intl = useIntl();

	const match = useRouteMatch<BugBountyBugReportRouteParams>(BUG_BOUNTY_ROUTES.BUG_REPORT.path);
	const eventId = match && parseInt(match.params.eventId);
	const bugId: UUID | "new" | null = match && match.params.bugId;

	const bugBountyReport: BugBountyReportSummary | undefined = useSelector((state: RootState) => !!bugId && bugId !== "new" && getBugReport(state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_REPORTS].data, bugId));
	const event: BugBountyProgram | undefined = useSelector((state: RootState) => !!eventId && getEvent(state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_EVENTS].data, eventId));

	if (bugId === "new") {
		return <span>{intl.fm("bugBounty.bugReport.newBug")}</span>;
	}

	if (!bugBountyReport?.title?.trim().length) {
		return <span>{intl.fm("bugBounty.bugReport.title")}</span>;
	}
	return <span>{bugBountyReport?.title}</span>;
};


export const BugBountyBugReport = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();

	const match = useRouteMatch<BugBountyBugReportRouteParams>(BUG_BOUNTY_ROUTES.BUG_REPORT.path);
	const eventId = match && parseInt(match.params.eventId);
	const bugId: UUID | "new" | null = match && match.params.bugId;

	const bugBountyEvent: BugBountyProgram | undefined = useSelector((state: RootState) => !!eventId && getEvent(state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_EVENTS].data, eventId));
	const bugBountyReport: BugBountyReportSummary | undefined = useSelector((state: RootState) => !!bugId && getBugReport(state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_REPORTS].data, bugId));

	const [ disableMarkdown, setDisableMarkdown ] = useState(false);
	const [ isNewCommentModalOpen, setNewCommentModalOpen ] = React.useState(false);
	const [ currentNewCommentAttachment, setCurrentNewCommentAttachment ] = React.useState<ProcessedFile>();
	const [ newCommentAttachments, setNewCommentAttachments ] = React.useState<ProcessedFile[]>([]);
	const [ isSubmitting, setIsSubmitting ] = React.useState(false);

	const { inputProps: bugCommentInputProps, reset: resetBugCommentInputProps } = useInput("");
	const mdClasses = useHlcrMarkdownStyles();

	// const adfToMarkdown = (adf: any) => {
	// 	const jsonTransformer = new JSONTransformer();
	// 	const node = jsonTransformer.parse(adf);
	// 	const mdTransformer = new MarkdownTransformer(defaultSchema);
	// 	return mdTransformer.encode(node); // NOT IMPLEMENTED YET
	// };

	const markdownToAdf = (markdown: string) => {
		const transformer = new MarkdownTransformer(defaultSchema);
		const node = transformer.parse(markdown);
		const jsonTransformer = new JSONTransformer();
		return jsonTransformer.encode(node);
	};

	// load the event with details
	useEffect(() => {
		if (eventId) {
			console.log(`fetching event ${eventId}`);
			dispatch(fetchHunterEvent(eventId));
		}

	}, [ fetchHunterEvent, eventId ]);

	// load the bug details unless a new bug is reported
	useEffect(() => {
		if (bugId && bugId !== "new") {
			console.log(`fetching bug ${bugId}`);
			dispatch(fetchReportedBug({ bugId }));
		}
	}, [ fetchReportedBug, bugId ]);

	const handleOnSuccess = (report: BugBountyReportSummary) => {
		setIsSubmitting(false);
		history.push(`/bugbounty/${report.programId}/bug/${report.id}`);
	};

	const handleSubmit = (report: NewBugBountyReport) => {
		if (eventId) {
			setIsSubmitting(true);
			dispatch(
				submitNewBug({ ...report, programId: eventId, description: { version: 1, ...markdownToAdf((report.description ?? "").toString()) as object } }, handleOnSuccess),
			);
		}
	};

	const handleCommentOnSuccess = (report: BugBountyReportSummary) => {
		setIsSubmitting(false);
		resetBugCommentInputProps();
		setNewCommentAttachments([]);
		setCurrentNewCommentAttachment(undefined);
		setNewCommentModalOpen(false);
	};

	const handleCommentSubmit = (comment: BugBountyReportComment) => {
		if (bugBountyReport?.id) {
			setIsSubmitting(true);
			dispatch(
				submitNewBugComment(bugBountyReport.id, { ...comment, body: { version: 1, ...markdownToAdf((comment.body ?? "").toString()) as object } }, handleCommentOnSuccess),
			);
		}
	};

	if (bugId === "new") {
		return (
			<>
				<IconCard
					title={intl.fm("bugBounty.bugReport.newBug")}
					icon={BugReportIcon}
					content={
						<ReportNewBugForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
					}
				/>
			</>
		);
	}

	if (!bugId || !bugBountyEvent || !bugBountyReport) {
		return <NoData />;
	}

	const Attachments = () => {
		if (bugBountyReport.attachments.length === 0) {
			return <></>;
		}
		return (
			<>
				<h3>Attachments</h3>
				<List>
					{(bugBountyReport.attachments ?? []).map((attachment, index) => (
						<a key={index} href={`/api/bug-bounty/hunter/bugs/${bugBountyReport.id}/attachments/${attachment.id}`} target="_blank" rel="noopener noreferrer">
							<ListItem>
								<ListItemIcon><DownloadIcon /></ListItemIcon>
								<ListItemText primary={attachment.name} secondary={attachment.size && `${Math.round(attachment.size / 1024)} kB`} />
							</ListItem>
						</a>
					))}
				</List>
			</>
		);
	};

	const formatRating = (rating: number) => {
		let formatted = "";
		for (let i = 0; i < rating; i++) {
			formatted += "⭐️ ";
		}
		return formatted;
	};

	const invalidFormState = bugCommentInputProps.value.length === 0;

	const handleCommentAttachmentAdded = (file: ProcessedFile | null, index?: number) => {
		if (file) {
			if (index !== undefined) {
				// replace attachment at index
				setNewCommentAttachments((prevState => {
					const newState = [ ...prevState ];
					newState[index] = file;
					return newState;
				}));
			} else {
				// add an attachment to the end of the list
				setNewCommentAttachments((prevState => [ ...prevState, file ]));
			}
		}
		if (file === null && index !== undefined) {
			// remove attachment at index
			setNewCommentAttachments((prevState => prevState.filter((_, i) => i !== index)));
		}
	};

	const handleSubmitClick = () => {
		handleCommentSubmit({ body: bugCommentInputProps.value, attachments: newCommentAttachments });
	};

	const getStatusEnumColor = (state: BugBountyStatusEnum, triageState: BugBountyTriageStatusEnum) => {
		if (state === BugBountyStatusEnum.DONE && triageState) {
			return getBugBountyTriageStatusEnumDetail(triageState)?.color;
		}
		return getBugBountyStatusEnumDetail(state)?.color;
	};

	const getStatusEnumTitle = (state: BugBountyStatusEnum, triageState: BugBountyTriageStatusEnum) => {
		if ((state === BugBountyStatusEnum.DONE || state === BugBountyStatusEnum.CLOSED) && triageState) {
			return intl.fm(getBugBountyStatusEnumDetail(bugBountyReport.state)?.title ?? "") + " / " + intl.fm(getBugBountyTriageStatusEnumDetail(bugBountyReport.triageState)?.title ?? "");
		}
		return intl.fm(getBugBountyStatusEnumDetail(bugBountyReport.state)?.title ?? "");
	};
	return (
		<>
			<IconCard
				title={bugBountyReport.title}
				icon={BugReportIcon}
				content={
					<>
						<Grid className={classes.infoBox} container={true} spacing={1}>
							<Grid item={true} xs={12}>
								<div className={classes.badgeContainer}>
									<Badge
										color={(getStatusEnumColor(bugBountyReport.state, bugBountyReport.triageState) ?? "info") as PaletteColorKey}>{getStatusEnumTitle(bugBountyReport.state, bugBountyReport.triageState)}</Badge>
								</div>
							</Grid>
							<Grid className={classes.title} item={true} xs={6} sm={3}>
								{intl.fm("bugBounty.bugReport.hunterCvss")}
							</Grid>
							<Grid item={true} xs={6} sm={9}>
								{bugBountyReport.hunterCvss.score} / {bugBountyReport.hunterCvss.vector}
							</Grid>
							<Grid className={classes.title} item={true} xs={6} sm={3}>
								{intl.fm("bugBounty.bugReport.triageCvss")}
							</Grid>
							<Grid item={true} xs={6} sm={9}>
								{bugBountyReport.triageCvss.score ? bugBountyReport.triageCvss.score + " / " + bugBountyReport.triageCvss.vector : intl.fm("common.labels.notAvailable")}
							</Grid>
							<Grid className={classes.title} item={true} xs={6} sm={3}>
								{intl.fm("bugBounty.bugReport.cwe")}
							</Grid>
							<Grid item={true} xs={6} sm={9}>
								{bugBountyReport.cweIds.map(cwe => {
									return (
										<Chip
											key={cwe}
											label={cwe}
											className={classes.chip}
										/>
									);
								})}
							</Grid>
							<Grid className={classes.title} item={true} xs={6} sm={3}>
								{intl.fm("bugBounty.bugReport.rating")}
							</Grid>
							<Grid item={true} xs={6} sm={9}>
								{formatRating(bugBountyReport.rating) || intl.fm("common.labels.notAvailable")}
							</Grid>
							{bugBountyReport.receivedBounty && <>
								<Grid className={classes.title} item={true} xs={6} sm={3}>
									{intl.fm("bugBounty.bugReport.bounty")}
								</Grid>
								<Grid item={true} xs={6} sm={9}>
									{bugBountyReport.receivedBounty} {bugBountyEvent.currency}
								</Grid>
							</>}
							{bugBountyReport.rejectionReason && <>
								<Grid className={classes.title} item={true} xs={6} sm={3}>
									{intl.fm("bugBounty.bugReport.rejectionReason")}
								</Grid>
								<Grid item={true} xs={6} sm={9}>
									{bugBountyReport.rejectionReason}
								</Grid>
							</>}
							<Grid item={true} xs={12}>
								<div className={"hl-markdown-html"} dangerouslySetInnerHTML={{ __html: (bugBountyReport.descriptionHtml ?? "") }}></div>
								{/* <StyledMarkdown source={reportDescriptionMd} darkMode={darkMode} />*/}
							</Grid>
							<Grid item={true} xs={12}>
								<Attachments />
							</Grid>
						</Grid>
					</>

				} />

			<RegularCard
				content={
					<>
						<SolutionHistory
							solutionComments={
								bugBountyReport.comments.map(reportComment => (
									{
										comment: (reportComment.bodyHtml ?? "").replace("\n", "").replace("\t", ""),
										user: {
											id: 0,
											username: (reportComment.fromTriage ? "" : reportComment.author) ?? "",
											email: "",
											userIdentifier: "",
										},
										creationTime: reportComment.createdAt?.toLocaleString() ?? "",
										inverted: !reportComment.fromTriage,
									}
								))
							}
							disableMarkdown={disableMarkdown}
							plainHtml={true}
							isDisabled={bugBountyReport.state === "CLOSED"}
							isTeacher={false}
							openSolutionModal={() => setNewCommentModalOpen(true)}
							maxPoints={0}
						/>
						<ModalWindow
							open={isNewCommentModalOpen}
							onClose={() => setNewCommentModalOpen(false)}
							helpLink={""}
							title={""}
							fullWidth={true}
						>
							<form>
								<Grid container={true} direction={"row"} spacing={3}>
									<Grid item={true} xs={12}>
										<Paper
											elevation={0}
											variant={"outlined"}
										>
											<TextField
												required={true}
												fullWidth={true}
												multiline={true}
												minRows={5}
												label={intl.fm("bugBounty.bugReportComment.comment")}
												{...bugCommentInputProps}
											/>
										</Paper>
									</Grid>
									<Grid item={true} xs={12}>
										<Upload
											type="file"
											file={currentNewCommentAttachment}
											disabled={isSubmitting}
											handleProcessedFile={(file) => handleCommentAttachmentAdded(file)}
										/>
									</Grid>
									{newCommentAttachments.map((attachment, index) => (
										<Grid item={true} xs={6} lg={4} xl={3} key={index}>
											<Upload
												rootClassName={classes.upload}
												type="file"
												file={attachment}
												disabled={false}
												handleProcessedFile={(file) => handleCommentAttachmentAdded(file, index)}
											/>
										</Grid>
									))}
								</Grid>
								<Grid container={true} direction={"row-reverse"} spacing={3}>
									<Grid item={true} xs={4} md={2}>
										<Button fullWidth={true} onClick={handleSubmitClick} color={"info"} disabled={invalidFormState || isSubmitting}>
											{
												isSubmitting
													? <CircularProgress size={18} />
													: `${intl.fm("bugBounty.bugReportComment.submit")}`
											}
										</Button>
									</Grid>
								</Grid>
							</form>
						</ModalWindow>
					</>
				}
			/>
		</>
	);
};
