import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import InputLabel from "@material-ui/core/InputLabel";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment/moment";
import * as React from "react";

import { checkHasRole } from "auth/authUtils";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import { CustomSelect } from "components/CustomSelect/CustomSelect";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { Upload } from "@hlcr/mui/Upload";
import WarningDialog from "components/ModalWindow/WarningDialog";
import TenaciousWarning, { TenaciousAction } from "components/ModalWindow/TenaciousWarning";
import NoData from "components/NoData/NoData";
import { getDateInputValue } from "helper/dateCalc";
import { getImageData } from "helper/manager";
import { EventType } from "models/EventType";
import { AccessibilitySelectList, VisibilitySelectList } from "variables/constants";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import { SCORING_MODE } from "shared/event/model/BaseEvent";

export default class EventDetails extends React.Component {
	state = {
		event: null,
		image: null,
		restoreOpen: false,
		deleteOpen: false,
		validation: {
			name: true,
			startTime: true,
			endTime: true,
			description: true,
			timeLimitInSeconds: true,
		},
	};

	static getDerivedStateFromProps(props, state) {
		if (props.event && (!state.event || props.event.id !== state.event.id)) {
			return {
				event: props.event,
				image: { name: "image" },
			};
		}
		return null;
	}

	render() {
		const { event, intl, classes } = this.props;
		const { validation } = this.state;
		const isFullValid = Object.values(validation).reduce((res, value) => res && value, true);

		const isArchived = checkHasRole(HackingLabRole.EVENT_RESTORE) && event && event.archived;

		return (
			<IconCard
				title={<span>{intl.fm("team.titles.general")}</span>}
				icon={InfoIcon}
				iconColor="purple"
				content={event ? this.inputForm() : <NoData />}
				footer={
					<div className={classes.actions}>
						<Button onClick={this.resetForm} color="defaultNoBackground">
							{intl.fm("common.labels.resetForm")}
						</Button>
						<Button onClick={this.submit} color="infoNoBackground" disabled={!isFullValid}>
							{intl.fm("common.labels.save")}
						</Button>
						<Button onClick={this.confirmDelete} color="dangerNoBackground">
							{intl.fm("common.labels.delete")}
						</Button>
						{isArchived && <Button onClick={this.confirmRestore} color="infoNoBackground">
							{intl.fm("common.labels.restore")}
						</Button>}
						<Button hidden={true} onClick={this.exportEvent} color={"defaultNoBackground"}>
							{intl.fm("common.labels.export")}
						</Button>
						<TenaciousWarning
							action={TenaciousAction.DELETE}
							onConfirm={this.delete}
							onClose={this.cancelDelete}
							entity={this.markedForDeletion()}
							entityIdentifier={entity => `${entity.name}`}
							messageLocalizationId="tenacious.warning.message.event.delete"
						/>
						{isArchived && <WarningDialog
							title={intl.fm("common.restoreConfirm.title")}
							message={intl.fm("common.restoreConfirm.message", null, { entityName: event.name })}
							onConfirm={this.restore}
							onClose={this.cancelRestore}
							open={this.state.restoreOpen}
							entityName={intl.fm("event.entityName")}
						/>}
					</div>
				}
			/>
		);
	}

	submit = () => {
		const { image, event } = this.state;
		const { updateManagerEvent, updateManagerCurriculumEvent, parentId } = this.props;

		const imageData = getImageData(image);
		const eventWithTimeLimit = {
			...event,
			timeLimitInSeconds: event.exam ? event.timeLimitInSeconds : null,
		};

		if (parentId) updateManagerCurriculumEvent(eventWithTimeLimit, imageData);
		else updateManagerEvent(eventWithTimeLimit, imageData);
	};

	exportEvent = () => {
		const { event: { id: eventId } } = this.state;
		window.open(`/api/events/${eventId}/export/`, "_blank");
	};

	restore = () => {
		const { restoreManagerEvent } = this.props;
		const { event } = this.state;
		this.setState({ restoreOpen: false });
		if (event) restoreManagerEvent(event);
	};

	publishRanking = () => {
		const { forcePublishRanking } = this.props;
		console.log(this.state.event);
		forcePublishRanking(this.state.event.id);
	};

	cancelRestore = () => {
		this.setState({ restoreOpen: false });
	};

	confirmRestore = () => {
		this.setState({ restoreOpen: true });
	};

	cancelDelete = () => {
		this.setState({ deleteOpen: false });
	};

	confirmDelete = () => {
		this.setState({ deleteOpen: true });
	};

	markedForDeletion = () => {
		const { deleteOpen, event } = this.state;
		if (deleteOpen) {
			return event;
		}
		return undefined;
	};

	delete = () => {
		const { deleteManagerEvent, history } = this.props;
		const { event } = this.state;
		this.setState({ deleteOpen: false });
		if (event) deleteManagerEvent(event, () => {
			if (event.type === EventType.CURRICULUM_EVENT) {
				history.push(`/manager/events/${event.parent}`);
			} else {
				history.push("/manager/events");
			}
		});
	};

	resetForm = () => {
		this.setState((_, props) => ({
			event: props.event,
			image: { name: "image" },
		}));
	};

	updateField = fieldName => (value, valid) => {
		this.setState(state => ({
			event: { ...state.event, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid },
		}));
	};

	updateFile = image => this.setState({ image });

	endDateIsGreater = (value, helpText) => {
		const { event, validation } = this.state;
		if (
			value === "" ||
			!(value instanceof moment) ||
			(validation.startTime && value.toISOString() > event.startTime)
		) {
			return true;
		}
		helpText.push("End Date must exceed start Date.");
		return false;
	};

	switchIsExam = event => {
		const exam = event.target.checked;
		this.setState(state => ({ event: { ...state.event, exam } }));
	};

	updateVisibility = event =>
		this.setState(prevState => ({ event: { ...prevState.event, visibility: event.target.value } }));

	updateAccessibility = event =>
		this.setState(prevState => ({
			event: {
				...prevState.event,
				accessibility: event.target.value,
			},
		}));

	updateScoringMode = event =>
		this.setState(prevState => ({ event: { ...prevState.event, scoringMode: event.target.value } }));

	switchRanked = () =>
		this.setState(prevState => ({
			event: {
				...prevState.event,
				ranked: !prevState.event.ranked,
			},
		}));

	switchRankedShowNoPointsUsers = () =>
		this.setState(prevState => ({
			event: {
				...prevState.event,
				rankedShowNoPointsUsers: !prevState.event.rankedShowNoPointsUsers,
			},
		}));

	switchPublished = () =>
		this.setState(prevState => ({
			event: {
				...prevState.event,
				published: !prevState.event.published,
			},
		}));

	inputForm = () => {
		const { classes, intl } = this.props;
		const { event, image } = this.state;

		const startTime = getDateInputValue(event.startTime);
		const endTime = getDateInputValue(event.endTime);

		const timeLimitValue =
			event.exam && event.timeLimitInSeconds !== undefined
				? event.timeLimitInSeconds
				: "";

		return (
			<form className={classes.detailsForm}>
				<div className={classes.descriptionBox}>
					<ValidationInput
						label={intl.fm("event.field.name")}
						value={event.name}
						onChange={this.updateField("name")}
						inputClasses={classes.inputOverflow}
						validations={{ required: true, minLength: 1, maxLength: 100 }}
					/>
					<ValidationInput
						type="date"
						value={startTime}
						onChange={this.updateField("startTime")}
						label={intl.fm("common.labels.chooseBeginDate")}
						validations={{ required: true }}
					/>
					<ValidationInput
						type="date"
						value={endTime}
						onChange={this.updateField("endTime")}
						label={intl.fm("common.labels.chooseEndDate")}
						validations={{ custom: this.endDateIsGreater }}
					/>
					{event.type !== EventType.CURRICULUM && event.type !== EventType.BUG_BOUNTY_EVENT && (
						<>
							<ValidationInput
								type="number"
								value={event.freezeTimeBeforeEndInMinutes}
								onChange={this.updateField("freezeTimeBeforeEndInMinutes")}
								label={intl.fm("common.labels.freezeTime")}
								validations={{ required: false }}
								fullWidth={true}
							/>
							<CustomTooltip title={intl.fm("common.labels.publishRankingInfo")}>
								<Button disabled={!event.freezeTimeBeforeEndInMinutes} style={{ padding: 0, marginTop: 0, marginBottom: "30px" }} color="infoNoBackground" onClick={this.publishRanking}>
									{intl.fm("common.labels.publishRanking")}
								</Button>
							</CustomTooltip>
						</>
					)}
					<div>
						<CustomSelect
							required={true}
							label={intl.fm("event.field.visibility")}
							value={event.visibility}
							menuItems={VisibilitySelectList}
							idField="id"
							displayField="text"
							onChange={this.updateVisibility}
							disabled={event.type === EventType.CURRICULUM_EVENT}
						/>
					</div>
					<div>
						<CustomSelect
							required={true}
							label={intl.fm("event.field.accessibility")}
							value={event.accessibility}
							menuItems={AccessibilitySelectList}
							idField="id"
							displayField="text"
							onChange={this.updateAccessibility}
							disabled={event.type === EventType.CURRICULUM_EVENT}
						/>
					</div>
					{event.type !== EventType.CURRICULUM && event.type !== EventType.BUG_BOUNTY_EVENT && (
						<div>
							<CustomSelect
								required={true}
								label={intl.fm("event.field.scoringMode")}
								value={event.scoringMode}
								menuItems={SCORING_MODE}
								idField="id"
								displayField="text"
								onChange={this.updateScoringMode}
							/>
						</div>
					)}
					<ValidationInput
						label={event.type === EventType.BUG_BOUNTY_EVENT ? intl.fm("bugBounty.newBugReport.description") : intl.fm("event.field.description")}
						value={event.description}
						onChange={this.updateField("description")}
						inputClasses={classes.inputOverflow}
						validations={{ required: true, minLength: 1 }}
						inputProps={{
							multiline: true,
							rows: event.type === EventType.BUG_BOUNTY_EVENT ? 15 : 4,
						}}
						fullWidth
					/>
					<div>
						<InputLabel className={classes.inputLabel}>
							{intl.fm("event.field.published")}
						</InputLabel>
						<CustomSwitch
							checked={event.published}
							onChange={this.switchPublished}
						/>
					</div>
					{event.type !== EventType.CURRICULUM && event.type !== EventType.BUG_BOUNTY_EVENT && (
						<>
							<div>
								<InputLabel className={classes.inputLabel}>
									{intl.fm("event.field.ranked")}
								</InputLabel>
								<CustomSwitch
									checked={event.ranked}
									onChange={this.switchRanked}
								/>
							</div>
							<div>
								<InputLabel className={classes.inputLabel}>
									{intl.fm("event.field.rankedShowNoPointsUsers")}
								</InputLabel>
								<CustomSwitch
									checked={event.rankedShowNoPointsUsers}
									onChange={this.switchRankedShowNoPointsUsers}
								/>
							</div>
						</>
					)}
					{event.type !== EventType.CURRICULUM && event.type !== EventType.BUG_BOUNTY_EVENT && (
						<div>
							<InputLabel className={classes.inputLabel}>
								{intl.fm("event.field.timeLimitInSeconds")}
							</InputLabel>
							<CustomSwitch checked={event.exam} onChange={this.switchIsExam} />
							<ValidationInput
								disabled={!event.exam}
								value={timeLimitValue}
								type="number"
								onChange={this.updateField("timeLimitInSeconds")}
								validations={{}}
							/>
						</div>
					)}
				</div>
				<Upload
					type="image"
					handleProcessedFile={this.updateFile}
					file={image}
					imageUrl={`/api/images/manager/events/${event.id}`}
					rootClassName={classes.imageUploadForm}
				/>
			</form>
		);
	};
}
