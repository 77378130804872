import { PaletteColorKey } from "@hlcr/mui/theme/hacking-lab.theme";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";


export const useBadgeStyles = (color?: PaletteColorKey, large?: boolean) => makeStyles(
	(theme: Theme) => createStyles({
		badge: {
			borderRadius: "12px",
			padding: "5px 12px",
			textTransform: large ? "none" : "uppercase",
			fontSize: "10px",
			fontWeight: 700,
			lineHeight: large ? 1.2 : 1,
			color: "#fff",
			textAlign: "center",
			whiteSpace: large ? "initial" : "nowrap",
			verticalAlign: "baseline",
			display: "inline",
			backgroundColor: color && (color === "grey" ? theme.palette.grey[100] : theme.palette[color]?.main) || color,
		},
	}),
);
