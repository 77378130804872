import { Page } from "@hlcr/core";
import { HttpMethod } from "@hlcr/core/enum/HttpMethod";
import { UUID } from "@hlcr/core/types";
import { ProcessedFile } from "@hlcr/mui/Upload";

import { ActionType } from "actions/ActionType";
import { BugBountyProgram } from "bugBounty/models/BugBountyProgram";
import { BugBountyStatusEnum } from "bugBounty/models/BugBountyStatusEnum";
import { EventType } from "models/EventType";
import { RemoteResource } from "models/RemoteResource";
import { DynamicRequestAction, DynamicRequestActionWithUrlParameter, RemoteResourceActionType } from "redux/actions";

import { BugBountyReportComment, BugBountyReportSummary, NewBugBountyReport } from "./models/BugBountyReport";


const BASE_URL = "/api";

export const fetchReportedBugs = (page: number, size: number, eventId: number | undefined, filterQuery: string, sort: string, filterStates: BugBountyStatusEnum[], onSuccess?: (bugBountyReportPage: Page<BugBountyReportSummary>) => void) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/bug-bounty/hunter/bugs?page=${page}&size=${size}&event=${eventId ?? ""}&filter=${filterQuery}&sort=${sort}&states=${filterStates.join(",")}`,
	method: "get",
	onSuccess: (_: any, result: Page<BugBountyReportSummary>) => onSuccess?.(result),
});

export const fetchHunterEvents: DynamicRequestAction = () => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/user/events?type=${EventType.BUG_BOUNTY_EVENT}`,
	resource: RemoteResource.BUG_BOUNTY_EVENTS,
	method: HttpMethod.GET,
});

export const fetchHunterEvent: DynamicRequestActionWithUrlParameter<BugBountyProgram, number> = (eventId: number) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/user/events/${eventId}`,
	resource: RemoteResource.BUG_BOUNTY_EVENTS,
	method: HttpMethod.GET,
});

export const fetchReportedBug: DynamicRequestActionWithUrlParameter<BugBountyReportSummary, { bugId: UUID }> = ({ bugId }) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/bug-bounty/hunter/bugs/${bugId}`,
	resource: RemoteResource.BUG_BOUNTY_REPORTS,
	method: HttpMethod.GET,
});

export const submitNewBug = (bugBountyReport: NewBugBountyReport, onSuccess?: (bugBountyReport: BugBountyReportSummary) => void) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/bug-bounty/hunter/bugs`,
	resource: RemoteResource.BUG_BOUNTY_REPORTS,
	method: HttpMethod.POST,
	payload: bugBountyReport,
	onSuccess: (_: any, result: BugBountyReportSummary) => onSuccess?.(result),
});

export const submitNewBugComment = (bugBountyId: string, bugBountyReportComment: BugBountyReportComment, onSuccess?: (bugBountyReport: BugBountyReportSummary) => void) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/bug-bounty/hunter/bugs/${bugBountyId}/comments`,
	resource: RemoteResource.BUG_BOUNTY_REPORTS,
	method: HttpMethod.POST,
	payload: bugBountyReportComment,
	onSuccess: (_: any, result: BugBountyReportSummary) => onSuccess?.(result),
});

export const submitNewBugAttachment = (bugBountyId: string, attachment: ProcessedFile, onSuccess?: (bugBountyReport: BugBountyReportSummary) => void) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/bug-bounty/hunter/bugs/${bugBountyId}/attachments`,
	resource: RemoteResource.BUG_BOUNTY_REPORTS,
	method: HttpMethod.POST,
	payload: { name: attachment.name, type: attachment.type, data: attachment.data },
	onSuccess: (_: any, result: BugBountyReportSummary) => onSuccess?.(result),
});
