import { percentage } from "@hlcr/core/numeric";
import { WithIntl, withIntl } from "@hlcr/ui/Intl";
import { InputAdornment, Tooltip } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import ValidationInput from "components/CustomInput/ValidationInput";
import { AssetType } from "models/Asset";
import { GRADING_MODES, GradingMode } from "models/EventUnit";
import { ScoringMode } from "shared/event/model/BaseEvent";

import { useStyles } from "./eventUnitModalStyle";
import { UpdateEventGradingModeFn, UpdateFlagWeightFn, UpdateMaxPointsFn } from "./eventUnitModalTypes";

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 1;
const MIN_POINTS_VALUE = 1;

interface EventUnitGradingSectionProps extends WithIntl {
	unitType: AssetType;
	gradingMode: GradingMode;
	scoringMode: ScoringMode;
	assetIsFlagBased?: boolean;
	maxPoints: number;
	flagWeight: number;
	handleGradingModeChange: UpdateEventGradingModeFn;
	handleMaxPointsChange: UpdateMaxPointsFn;
	handleFlagWeightChange: UpdateFlagWeightFn;
}

export const EventUnitGradingSection = withIntl((
	{ unitType, gradingMode, scoringMode, maxPoints, assetIsFlagBased = false, flagWeight, handleGradingModeChange, handleMaxPointsChange, handleFlagWeightChange, intl }: EventUnitGradingSectionProps) => {
	return (<>
		<h3>{intl.fm("manager.eventDetails.units.table.grading")}</h3>
		{unitType === AssetType.CHALLENGE && <ToggleButtons gradingMode={gradingMode} assetIsFlagBased={assetIsFlagBased} handleGradingModeChange={handleGradingModeChange} />}
		<GradingModeSettings maxPoints={maxPoints} flagWeight={flagWeight} gradingMode={gradingMode} handleMaxPointsChange={handleMaxPointsChange}
		                     handleFlagWeightChange={handleFlagWeightChange} scoringMode={scoringMode} />
		{gradingMode === GradingMode.WRITEUP_FLAG && <GradingModeDescriptionText maxPoints={maxPoints} flagWeight={flagWeight} />}
	</>);
});

interface ToggleButtonsProps extends WithIntl {
	gradingMode: GradingMode;
	assetIsFlagBased: boolean;
	handleGradingModeChange: UpdateEventGradingModeFn;
}

const ToggleButtons = withIntl(({ gradingMode, assetIsFlagBased, handleGradingModeChange, intl }: ToggleButtonsProps) => {
	const classes = useStyles();
	return (
		<div className={classes.toggleButtonWrapper}>
			<ToggleButtonGroup value={gradingMode} onChange={handleGradingModeChange} exclusive>
				{Object.entries(GRADING_MODES).map(([ key, gradingMode ]) =>
					<ToggleButton
						classes={{ root: classes.toggleButtonRoot, selected: classes.toggleButtonSelected }}
						key={key}
						value={key}
						disabled={!assetIsFlagBased && gradingMode.isFlagBased}
					>
						{intl.fm(gradingMode.title)}
					</ToggleButton>,
				)}
			</ToggleButtonGroup>
		</div>
	);
});

interface GradingModeSettingsProps extends WithIntl {
	maxPoints: number;
	flagWeight: number;
	gradingMode: GradingMode;
	scoringMode: ScoringMode;
	handleMaxPointsChange: UpdateMaxPointsFn;
	handleFlagWeightChange: UpdateFlagWeightFn;
}

const GradingModeSettings = withIntl((props: GradingModeSettingsProps) => {
	const classes = useStyles();
	const { maxPoints, flagWeight, gradingMode, scoringMode, handleMaxPointsChange, handleFlagWeightChange, intl } = props;
	return (<>
		<div className={classes.flexRowWrapper}>
			<Tooltip title={intl.fm("manager.eventDetails.units.table.maxPoints.explanation." + (scoringMode === ScoringMode.DYNAMIC ? "dynamic" : "static"))}>
				<div className={classes.columnWrapper}>
					<ValidationInput
						disabled={scoringMode === ScoringMode.DYNAMIC}
						value={maxPoints}
						onChange={handleMaxPointsChange}
						label={intl.fm("manager.eventDetails.units.table.maxPoints")}
						inputProps={{ min: MIN_POINTS_VALUE }}
						validations={{ verifyNumber: true }}
						formControlProps={{ className: classes.inputRoot }}
					/>
					{scoringMode === ScoringMode.DYNAMIC && (
						<span className={classes.dynamicScoringText}>{intl.fm("manager.eventDetails.units.table.maxPoints.dynamic")}</span>
					)}
				</div>
			</Tooltip>
			<div className={classes.columnWrapper}>
				{gradingMode === GradingMode.WRITEUP_FLAG && (
					<ValidationInput
						value={percentage(flagWeight)}
						onChange={handleFlagWeightChange}
						label={intl.fm("manager.eventDetails.units.table.flagWeightPercentage")}
						inputProps={{
							min: percentage(MIN_PERCENTAGE),
							max: percentage(MAX_PERCENTAGE),
							endAdornment: <InputAdornment position="end">%</InputAdornment>,
						}}
						validations={{ verifyNumber: true }}
						formControlProps={{ className: classes.inputRoot }}
					/>
				)}
			</div>
		</div>
	</>);
});


interface GradingModeDescriptionTextProps extends WithIntl {
	maxPoints: number;
	flagWeight: number;
}

const GradingModeDescriptionText = withIntl((props: GradingModeDescriptionTextProps) => {
	const classes = useStyles();
	const { maxPoints, flagWeight, intl } = props;

	const maxFlagPoints = Math.round(maxPoints * flagWeight);

	return (<>
		<div className={classes.flexRowWrapper}>
			<div className={classes.columnWrapper}>
				<span className={classes.flexRowText}>{intl.fm("manager.eventDetails.units.grading.pointsForCorrectFlag")}</span>
				<span className={classes.flexRowText}>{intl.fm("manager.eventDetails.units.grading.maxPointsWriteup")}</span>
			</div>
			<div className={classes.columnWrapper}>
				<span className={classes.flexRowGrow}>{maxFlagPoints}</span>
				<span className={classes.flexRowGrow}>{maxPoints - maxFlagPoints}</span>
			</div>
		</div>
	</>);
});
