import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { Fab, Grid, ListItemIcon, ListItemText } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CreateOutlined } from "@material-ui/icons";
import BugReportIcon from "@material-ui/icons/BugReport";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { fetchHunterEvent } from "bugBounty/bugBounty.actions";
import { BUG_BOUNTY_ROUTES, BugBountyEventRouteParams } from "bugBounty/bugBounty.routes";
import classes from "bugBounty/bugBountyEvent.module.scss";
import { ReportedBugsTable } from "bugBounty/components/ReportedBugsTable";
import { BugBountyProgram } from "bugBounty/models/BugBountyProgram";
import styles from "bugBounty/overviewPage.module.scss";
import IconCard from "components/Cards/IconCard";
import NoData from "components/NoData/NoData";
import { createMemoize } from "helper/memoize";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { Event } from "shared/event/model/Event";


const getEvent = createMemoize((events: Event[], eventId: number) => {
	return events.find(event => event.id === eventId);
});
export const BugBountyEventBreadCrumb = () => {
	const intl = useIntl();

	const match = useRouteMatch<BugBountyEventRouteParams>(BUG_BOUNTY_ROUTES.EVENT.path);
	const eventId = match && parseInt(match.params.eventId);

	const event: BugBountyProgram | undefined = useSelector((state: RootState) => !!eventId && getEvent(state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_EVENTS].data, eventId));

	if (!event?.name?.trim().length) {
		return <span>{intl.fm("manager.eventDetails.breadCrumb")}</span>;
	}
	return <span>{event.name}</span>;
};

export const BugBountyEvent = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();

	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const match = useRouteMatch<BugBountyEventRouteParams>(BUG_BOUNTY_ROUTES.EVENT.path);
	const eventId = match && parseInt(match.params.eventId);

	const event: BugBountyProgram | undefined = useSelector((state: RootState) => !!eventId && getEvent(state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_EVENTS].data, eventId));


	// load the event with details
	useEffect(() => {
		if (eventId && event?.id !== eventId) {
			console.log(`fetch event ${eventId}`);
			dispatch(fetchHunterEvent(eventId));
		}
	}, [ fetchHunterEvent, eventId ]);

	const [ disableMarkdown, setDisableMarkdown ] = useState(false);
	const [ reportingNewBug, setReportingNewBug ] = useState(false);

	const handleOnReportNewBugCancel = () => {
		// TODO: warn if form has changes
		// cleanup form and state
		// finally close the modal
		history.push(`/bugbounty/${eventId}/bug/new`);
		setReportingNewBug(false);
	};

	if (event === undefined) {
		return <NoData />;
	}

	const descriptionCleanRegex = /\[.*?\+(teaser|btn-link)\+\]\(.*?\)/gm;
	return (
		<>
			<Grid container={true} justifyContent={"center"}>
				<Grid item={true}>
					<Fab
						variant="extended"
						className={classes.fab}
						onClick={
							() => history.push(`/bugbounty/${eventId}/bug/new`)
						}
					>
						<CreateOutlined />
						{intl.fm("bugBounty.event.reportNewBug")}
					</Fab>
				</Grid>
			</Grid>
			<IconCard
				title={intl.fm("bugBounty.overviewPage.foundBugs.title")}
				icon={BugReportIcon}
				content={
					<div className={styles.foundBugsContent}>
						<ReportedBugsTable eventId={eventId ?? undefined} />
					</div>
				} />
			<IconCard
				title={event.name}
				icon={BugReportIcon}
				content={
					<>
						<div className={classes.container}>
							<div className={classes.eventDescription}>
								<div className={"hl-bb-description-markdown"}><StyledMarkdown source={(event?.description ?? "").replace(descriptionCleanRegex, "")} darkMode={darkMode} /></div>
								{ event.scope &&
									<>
										<h3>Scope</h3>
										<div className={"hl-bb-description-markdown"}><StyledMarkdown source={event?.scope} darkMode={darkMode} /></div>
									</>
								}
								{ event.termsAndConditions &&
									<>
										<h3>Terms and Conditions</h3>
										<div className={"hl-bb-description-markdown"}><StyledMarkdown source={event.termsAndConditions} darkMode={darkMode} /></div>
									</>
								}
							</div>
							<div className={classes.imageAndEventAttachments}>
								<img alt={`Image of event ${event?.name}`} className={classes.eventImage} src={`/api/images/events/${event?.id}`} />

								<List>
									{(event.attachments ?? []).map((attachment, index) => (
										<a key={index} href={`/api/bug-bounty/hunter/events/${event.id}/attachments/${attachment.id}`} target="_blank" rel="noopener noreferrer">
											<ListItem>
												<ListItemIcon><DownloadIcon /></ListItemIcon>
												<ListItemText primary={attachment.name} secondary={attachment.size && `${Math.round(attachment.size / 1024)} kB`} />
											</ListItem>
										</a>
									))}
								</List>
							</div>
						</div>
					</>
				}
			/>
		</>
	);
};
