import { percentage } from "@hlcr/core/numeric";
import { dangerColor, successColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { formatMessage, useIntl } from "@hlcr/ui/Intl";
import { StyledMarkdown } from "@hlcr/ui/StyledMarkdown";
import CrossIcon from "@material-ui/icons/Close";
import TickIcon from "@material-ui/icons/Done";
import * as React from "react";
import { useSelector } from "react-redux";

import SolutionTimeline from "components/Timeline/SolutionTimeline";
import { formatFullLongDate } from "helper/dateCalc";
import { isCommentFromTeacher, SolutionComment } from "models/Solution";
import { getSolutionCommentState, getSolutionState } from "models/SolutionState";
import { User } from "models/User";
import "rc-slider/assets/index.css";
import { RootState } from "reducers";


interface SolutionHistoryProps {
	eventName?: string;
	solutionComments: SolutionComment[];
	maxPoints: number;
	solutionId?: number;
	isTeacher: boolean;
	isDisabled: boolean;
	disableMarkdown: boolean;
	plainHtml?: boolean;
	openSolutionModal: () => void;
	submitGrading?: (grade: number) => void;
}

export const SolutionHistory = ({ solutionComments, isTeacher, isDisabled, disableMarkdown, plainHtml, openSolutionModal, submitGrading, eventName }: SolutionHistoryProps) => {
	const intl = useIntl();

	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const dialActions = isTeacher && submitGrading
		? [
				{ handleClick: () => openSolutionModal() },
				{
					icon: <TickIcon />,
					name: intl.fm("teacher.solution.grading.acceptWithoutComment"),
					style: { background: successColor },
					handleClick: () => submitGrading(1),
				},
				{
					icon: <CrossIcon />,
					name: intl.fm("teacher.solution.grading.rejectWithoutComment"),
					style: { background: dangerColor },
					handleClick: () => submitGrading(0),
				},
			]
		: [ { handleClick: () => openSolutionModal() } ];

	const displayName = (user: User) => (user.firstName || user.lastName) ? `${user.firstName || "?"} ${user.lastName || "?"} (${user.username})` : user.username;

	return (
		<SolutionTimeline
			addAction={!isDisabled && dialActions}
			stories={solutionComments.map(solutionComment => {
				const solutionCommentState = getSolutionCommentState(solutionComment);

				const userSubmissions = `${solutionComment.flag ?? ""}\n\n${solutionComment.writeup ?? ""}`.trim();
				const solutionState = getSolutionState(solutionComment.solutionStateSnapshot);
				const solutionStateSnapshot = solutionState ? intl.fm(solutionState.title) : "";

				let solutionSnapshotInformation = "";

				if (solutionComment.solutionStateSnapshot !== undefined) {
					solutionSnapshotInformation = `${intl.fm("solution_comment.snapshot_information")}: ${solutionStateSnapshot}`.trim();
				}

				const gradingResponses = `${solutionComment.comment ?? ""}\n\n${solutionSnapshotInformation}`.trim();

				const combinedComment = `${userSubmissions}\n\n${gradingResponses}`.trim();

				return {
					badgeColor: solutionCommentState.iconColor,
					badgeIcon: solutionCommentState.icon,
					inverted: solutionComment.inverted ?? isCommentFromTeacher(solutionComment),
					title: formatMessage(solutionCommentState.title),
					titleColor: solutionCommentState.color,
					titleMeta: (solutionComment.writeupGrade !== undefined && solutionComment.writeupGrade > 0 && solutionComment.writeupGrade < 1) ? `${percentage(solutionComment.writeupGrade)} %` : "",
					body: plainHtml ? <div className={"hl-markdown-html"}  dangerouslySetInnerHTML={{ __html: combinedComment }}></div> : disableMarkdown ? <pre>{combinedComment}</pre> : <StyledMarkdown source={combinedComment} darkMode={darkMode} />,
					attachment: solutionComment.attachment,
					footerTitle: formatFullLongDate(solutionComment.creationTime, intl.fm("common.labels.unknown")),
					footerMeta: solutionComment.user && displayName(solutionComment.user),
					eventName,
				};
			})}
			compact={plainHtml}
		/>
	);
};
