import { RemoteResourceState } from "@hlcr/core/api/RemoteResource";
import { useIntl } from "@hlcr/ui/Intl";
import { Grid } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { fetchEventJoinInfo, postEventRegistration } from "actions/events";
import { fetchHunterEvents } from "bugBounty/bugBounty.actions";
import { ReportedBugsTable } from "bugBounty/components/ReportedBugsTable";
import { BugBountyProgram } from "bugBounty/models/BugBountyProgram";
import styles from "bugBounty/overviewPage.module.scss";
import IconCard from "components/Cards/IconCard";
import { EventAccessTokenModal } from "eventAccessToken/EventAccessTokenModal";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { EventList } from "shared/event/components/EventList/EventList";
import { Event } from "shared/event/model/Event";
import JoinEventModal from "views/Events/JoinEventModal";


export const OverviewPage = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();

	const bugBountyEvents = useSelector<RootState, RemoteResourceState<BugBountyProgram[]>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.BUG_BOUNTY_EVENTS]);

	const [ showJoinDialog, setShowJoinDialog ] = useState(false);
	const [ token, setToken ] = useState("");
	const [ showRedeemDialog, setShowRedeemDialog ] = useState(false);
	const [ enteredToken, setEnteredToken ] = useState("");

	// if we come via url /events/redeem/:token => we show directly the redeem dialog
	const { tokenParam }: { tokenParam: string } = useParams();
	useEffect(() => {
		if (location.pathname.includes("bugbounty/redeem")) {
			setShowRedeemDialog(true);
		}
	}, [ location.pathname ]);

	useEffect(() => {
		if (tokenParam) {
			setEnteredToken(tokenParam);
			setShowRedeemDialog(true);
		}
	}, [ tokenParam ]);

	useEffect(() => {
		dispatch(fetchHunterEvents());
	}, [ fetchHunterEvents ]);

	const handleRegistration = (eventId: number) => {
		console.log(`register on event ${eventId}`);
		dispatch(
			postEventRegistration(
				eventId,
				(event: Event) => event?.id && history.push(`/bugbounty/${event.id}`),
			),
		);
	};

	const onCloseJoinDialog = (hasJoined: boolean, eventTitle: string) => {
		setToken("");
		setShowJoinDialog(false);

		if (hasJoined) {
			// TODO add id
			history.push("/bugbounty");
		} else {
			history.push("/bugbounty");
		}
	};

	const onCloseRedeemDialog = (eventTitle: string | undefined) => {
		setShowRedeemDialog(false);

		if (!eventTitle) {
			history.push("/bugbounty");
		}
	};

	const redeemToken = (token: string, callback: any, callbackFailure: any) => {
		dispatch(
			fetchEventJoinInfo(token, (eventJoinResult: any) => {
				console.log(eventJoinResult);
				setToken(token);
				setShowJoinDialog(true);
				if (callback && typeof callback === "function") {
					callback(eventJoinResult);
				}
			}, callbackFailure),
		);
	};


	return (
		<>
			<IconCard
				title={intl.fm("bugBounty.overviewPage.foundBugs.title")}
				icon={BugReportIcon}
				content={
					<div className={styles.foundBugsContent}>
						<ReportedBugsTable />
					</div>
				} />
			<Grid container={true} justifyContent={"space-between"} alignItems={"center"} spacing={3}>
				<Grid item={true}>
					<h3>Bug Bounty Programs</h3>
				</Grid>
				{/* <Grid item={true}>*/}
				{/*	<Button*/}
				{/*		onClick={() => history.push("/bugbounty/redeem")}*/}
				{/*		color="primary"*/}
				{/*		variant="contained"*/}
				{/*		style={{ borderWidth: 2 }}*/}
				{/*	>{intl.fm("events.redeem.accessToken")}</Button>*/}
				{/* </Grid>*/}
			</Grid>
			<EventList
				events={bugBountyEvents.data ?? []}
				register={handleRegistration}
			/>
			<JoinEventModal open={showJoinDialog} onClose={onCloseJoinDialog} token={token} />
			{showRedeemDialog &&
				<EventAccessTokenModal
					token={enteredToken}
					onClose={onCloseRedeemDialog}
					redeemToken={redeemToken}
				/>
			}
		</>
	);
};
