import { RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { useIntl } from "@hlcr/ui/Intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import BugReportIcon from "@material-ui/icons/BugReport";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import GavelIcon from "@material-ui/icons/Gavel";
import GroupIcon from "@material-ui/icons/Group";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CommonEventCard from "components/Cards/CommonEventCard";
import ExamInfo from "components/Exam/ExamInfo";
import NoData from "components/NoData/NoData";
import TweetButton from "components/Social/TweetButton";
import { checkDatePassed, checkDatePendent } from "helper/dateCalc";
import { getRankingLink } from "helper/links";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { PROFILE_PATH } from "routes/dashboardRoutes";
import styles from "shared/event/components/EventList/EventList.module.scss";
import { EventType, formatDateRange } from "shared/event/model/BaseEvent";
import { isUserCurriculumEvent, UserCurriculumEvent } from "shared/event/model/UserCurriculumEvent";
import { UserEvent } from "shared/event/model/UserEvent";
import { TermModal, TermReviewMode } from "term/components/termModal/TermModal";
import { TermAgreement } from "term/term.model";


interface EventListProps {
	socialSharing?: boolean;
	title?: string;
	events: Array<UserEvent | UserCurriculumEvent>;
	parentEvent?: UserEvent | UserCurriculumEvent;
	pending?: boolean;
	register: (eventId: number) => void; // is a function
	redeemEvent?: any; // is a function
}

const getEventLink = (event: UserEvent | UserCurriculumEvent) => {
	switch (event.type) {
		case EventType.CURRICULUM_EVENT:
			return `/events/${(event as UserCurriculumEvent).parent}/curriculumevents/${event.id}`;
		case EventType.BUG_BOUNTY_EVENT:
			return `/bugbounty/${event.id}`;
		default:
			return `/events/${event.id}`;
	}
};

export const EventList = ({ title, socialSharing, events, pending, register, parentEvent }: EventListProps) => {
	const intl = useIntl();

	const [ eventId, setEventId ] = useState<number>();
	const [ reviewTermsOpen, setReviewTermsOpen ] = useState(false);
	const [ termAgreements, setTermAgreements ] = useState<TermAgreement[]>([]);
	const [ termReviewMode, setTermReviewMode ] = useState<TermReviewMode>(TermReviewMode.NO_REASON);

	const filteredEvents = events.filter(e => !e.teaching);

	const termAgreementsState = useSelector<RootState, RemoteResourceState<TermAgreement[]>>(state => {
		return state.remoteResourceReducer.remoteResources[RemoteResource.TERM_AGREEMENTS];
	});

	useEffect(() => {
		if (termAgreementsState.status == RemoteResourceStatus.LOADED) {
			setTermAgreements(termAgreementsState?.data ?? []);
		}
	}, [ termAgreementsState ]);

	return (
		<>
			<TermModal
				eventId={eventId}
				reviewMode={termReviewMode}
				open={reviewTermsOpen}
				onClose={() => setReviewTermsOpen(false)}
				fullWidth={true}
			/>
			{title && (
				<h3 className={styles.title}>
					{title}{" "}
					{socialSharing && (<TweetButton text={`I am joining ${title}. Love to be here.`} />)}{" "}
					{pending && <CircularProgress size={18} />}
				</h3>
			)}
			{filteredEvents && filteredEvents.length ? (
				<div className={styles.cardGridContainer}>
					{filteredEvents.map(event => {
						const termAgreement = termAgreements.find(termAgreement => termAgreement.termId === event.termId);
						const comingSoon = checkDatePendent(event.startTime);
						const finished = checkDatePassed(event.endTime);
						const running = !comingSoon && !finished;

						const progressCardStat = isUserCurriculumEvent(event) && (event as UserCurriculumEvent).unitsCount
							? [ { cardStat: `${(event as UserCurriculumEvent).unitsWithSolutionCount || 0}/${(event as UserCurriculumEvent).unitsCount}`, faIcon: "check" } ]
							: [];

						const needsTermAgreement = (event.termId ?? false) && (termAgreement && !termAgreement.accepted || !termAgreement && !event.termAccepted);
						const anyPolicyViolated = event.accessPolicyViolated || needsTermAgreement;
						const hideRegistering = event.registered || !running || anyPolicyViolated;

						const isReviewModeAccordingToTermAcceptance = termAgreement && termAgreement.accepted || !termAgreement && event?.termAccepted;
						let reviewMode: TermReviewMode;
						if (isReviewModeAccordingToTermAcceptance) {
							reviewMode = TermReviewMode.ALREADY_ACCEPTED;
						} else if (event.accessPolicyViolated) {
							reviewMode = TermReviewMode.POLICY_VIOLATED;
						} else if (!running) {
							reviewMode = TermReviewMode.NOT_RUNNING;
						}

						const actions = [
							{
								cardStat: event.participantCount || "0",
								icon: GroupIcon,
							},
							...event.registered ? progressCardStat : [],
							{
								to: getRankingLink(event),
								icon: FormatListNumberedIcon,
								hidden: needsTermAgreement || !event.ranked || event.exam || !event.registered || anyPolicyViolated || event.type === EventType.BUG_BOUNTY_EVENT,
							},
							{
								to: getEventLink(event) + "/bug/new",
								icon: BugReportIcon,
								label: intl.fm("bugBounty.event.reportNewBug"),
								hidden: needsTermAgreement || !event.registered || anyPolicyViolated || event.type !== EventType.BUG_BOUNTY_EVENT,
							},
							{
								to: getEventLink(event),
								label: intl.fm("common.labels.goto"),
								hidden: !running || needsTermAgreement || !event.registered || anyPolicyViolated || event.type === EventType.BUG_BOUNTY_EVENT,
							},
							{
								onClick: register,
								label: intl.fm("common.labels.register"),
								hidden: hideRegistering,
							},
							{
								label: comingSoon ? intl.fm("common.labels.comingSoon") : intl.fm("common.labels.finished"),
								hidden: running || event.accessPolicyViolated,
							},
							{
								label: <><AccountCircleIcon />{intl.fm("common.labels.accessPolicyViolated")}</>,
								hidden: !event.accessPolicyViolated,
								to: PROFILE_PATH,
								tooltip: `${event.accessPolicyDescription} (${intl.fm("common.labels.editProfile")})`,
							},
							{
								label: intl.fm("common.labels.reviewPrivacyPolicy"),
								hidden: !needsTermAgreement || !running || event.accessPolicyViolated,
								onClick: () => {
									setTermReviewMode(reviewMode);
									setEventId(event.id);
									setReviewTermsOpen(true);
								},
							},
						];

						const overlayActions = [
							{
								to: getEventLink(event),
								icon: ArrowForwardIcon,
								hidden: !running || needsTermAgreement || !event.registered || anyPolicyViolated,
							},
							{
								onClick: register,
								icon: AddIcon,
								hidden: hideRegistering,
							},
							{
								icon: GavelIcon,
								hidden: !needsTermAgreement || !running ||  event.accessPolicyViolated,
								onClick: () => {
									setTermReviewMode(reviewMode);
									setEventId(event.id);
									setReviewTermsOpen(true);
								},
							},
						];

						const onTermsBowActionClick = () => {
							setTermReviewMode(reviewMode);
							setEventId(event.id);
							setReviewTermsOpen(true);
						};

						return (
							<div className={styles.cardContainer} key={event.id}>
								<CommonEventCard
									event={event}
									termAgreement={termAgreement}
									image={`/api/images/events/${event.id}`}
									actions={actions}
									overlayActions={overlayActions}
									subTitle={formatDateRange(event, intl, parentEvent)}
									contentText={<ExamInfo event={event} />}
									onTermsBowActionClick={onTermsBowActionClick}
								/>
							</div>
						);
					})}
				</div>
			) : (
				<NoData text={intl.fm("events.nodata")} />
			)}
		</>
	);
};
