import React from "react";

const typeImageBasePath = {
	CHALLENGE: "/api/images/challenges",
	THEORY: "/api/images/theories",
	QUIZ: "/api/images/quizzes"
};

export const getEventUnitImageSrc = (type, id) => {
	const baseUrl = typeImageBasePath[(type || "unknown").toUpperCase()];
	if (!baseUrl) return undefined;
	return `${baseUrl}/${id}`;
};

const userShowInfoCookieName = "USER_SHOW_INFO";
export const getShowInfoSetting = cookies => {
	const displayInfoString = cookies.get(userShowInfoCookieName);
	return displayInfoString === "true";
};

export const setShowInfoSetting = (cookies, displayInfo) => {
	cookies.set(userShowInfoCookieName, displayInfo, {
		path: "/",
		expires: new Date(2049, 12)
	});
};
