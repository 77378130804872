/**
 * ########################################
 * IMPORTANT
 * ########################################
 *
 * The following enum HackingLabRole can be automatically generated from Java backend file HackingLabAuthority.java
 * using the GET request to <local-backend-url>/dev/roles (e.g. http://localhost:8002/dev/roles)
 */
export enum HackingLabRole {
	ATTACHMENTS = "attachments",
	CLASS_TEACHERS = "class_teachers",
	CLASSES = "classes",
	EVENT_TEAMS = "event_teams",
	EVENT_UNIT_ISSUES = "event_unit_issues",
	EVENT_UNIT_RESET = "event_unit_reset",
	EVENT_UNITS = "event_units",
	EVENT_USERS = "event_users",
	EVENTS = "events",
	IMAGES = "images",
	MANAGER_IMAGES = "manager_images",
	MANAGER_VMS = "manager_vms",
	MEDIA = "media",
	NO_CSRF = "no_csrf",
	PUBLIC_RANKINGS_TEAMS = "public_rankings_teams",
	PUBLIC_RANKINGS_USERS = "public_rankings_users",
	RANKINGS_TEAMS = "rankings_teams",
	RANKINGS_USERS = "rankings_users",
	REPORTS_CLASSES = "reports_classes",
	REPORTS_SOLUTIONS = "reports_solutions",
	REPORTS_TEAMS = "reports_teams",
	REPORTS_USER = "reports_user",
	REPORTS_USER_ALL = "reports_user_all",
	SOLUTIONS = "solutions",
	STUDENTS = "students",
	TEACHER_CHALLENGES = "teacher_challenges",
	TEACHER_EVENTS = "teacher_events",
	TEACHER_QUIZ_SOLUTIONS = "teacher_quiz_solutions",
	TEACHER_SOLUTIONS = "teacher_solutions",
	TEACHER_VMS = "teacher_vms",
	TEACHERS = "teachers",
	TEAM_MEMBERS = "team_members",
	TEAMLEADER = "teamleader",
	TEAMLEADER_CREATE_TEAM = "teamleader_create_team",
	TEAMLEADER_DELETE_TEAM = "teamleader_delete_team",
	TEAMLEADER_DELETE_TEAM_MEMBER = "teamleader_delete_team_member",
	TEAMLEADER_REPORT = "teamleader_report",
	TEAMLEADER_TEAM_MEMBER_PROFILE = "teamleader_team_member_profile",
	TEAMLEADER_UPDATE_TEAM = "teamleader_update_team",
	TEAMS = "teams",
	USER_CHALLENGE_SOLUTIONS = "user_challenge_solutions",
	USER_CHALLENGES = "user_challenges",
	USER_EVENT_UNITS = "user_event_units",
	USER_EVENTS = "user_events",
	USER_PROFILE = "user_profile",
	USER_QUIZ_ANSWERS = "user_quiz_answers",
	USER_QUIZZES = "user_quizzes",
	USER_SOLUTIONS = "user_solutions",
	USER_TEAMS = "user_teams",
	USER_THEORIES = "user_theories",
	USERS = "users",
	TENANT_ACCESS_POLICY_GET = "tenant_access_policy_get",
	TENANT_ACCESS_POLICY_UPDATE = "tenant_access_policy_update",
	EVENT_ACCESS_POLICY_READ = "event_access_policy_read",
	EVENT_ACCESS_POLICY_UPDATE = "event_access_policy_update",
	EXECUTE_USER_TASK = "execute_user_task",
	EVENT_ADMIN = "event_admin",
	EVENT_RESTORE = "event_restore",
	COMPOSITE_ADMIN = "composite_admin",
	COMPOSITE_REPORTS = "composite_reports",
	COMPOSITE_AUDITOR = "composite_auditor",
	COMPOSITE_MANAGER = "composite_manager",
	COMPOSITE_TEACHER = "composite_teacher",
	COMPOSITE_TEAMLEADER = "composite_teamleader",
	COMPOSITE_USER = "composite_user",
	ROLE_BUG_BOUNTY_HUNTER = "role_bug_bounty_hunter",
	BYPASS_ACCESS_CHECK = "bypass_access_check",
	RANKING_EVENT = "ranking_event",
	RANKING_INDIVIDUALS = "ranking_individuals",
	RANKING_TEAMS = "ranking_teams",
	RANKING_UNITS = "ranking_units",
	RANKING_REPORT_READ = "ranking_report_read",
	RANKING_ALL = "ranking_all",
	MESSAGING_SEND_EMAIL_DIGEST = "messaging_send_email_digest",
	MESSAGE_CREATE = "message_create",
	MESSAGE_RECALL = "message_recall",
	MESSAGE_DELETE = "message_delete",
	TENANT_CONFIGURATION_CREATE = "tenant_configuration_create",
	TENANT_CONFIGURATION_READ = "tenant_configuration_read",
	TENANT_CONFIGURATION_UPDATE = "tenant_configuration_update",
	TENANT_CONFIGURATION_DELETE = "tenant_configuration_delete",
	TENANT_CONFIGURATION_EMAIL_CREATE = "tenant_configuration_email_create",
	TENANT_CONFIGURATION_EMAIL_READ = "tenant_configuration_email_read",
	TENANT_CONFIGURATION_EMAIL_UPDATE = "tenant_configuration_email_update",
	TENANT_CONFIGURATION_EMAIL_DELETE = "tenant_configuration_email_delete",
	TENANT_CONFIGURATION_EMAIL_TEST = "tenant_configuration_email_test",
	TENANT_ADMIN_ACCESS_POLICY_READ = "tenant_admin_access_policy_read",
	TENANT_ADMIN_CONFIG_READ = "tenant_admin_config_read",
	ROLE_PARTICIPANT = "role_participant",
	ROLE_TEACHER = "role_teacher",
	ROLE_TENANT_ADMIN = "role_tenant_admin",
	ROLE_RANKING = "role_ranking",
}

export interface HackingLabToken {
	sub: string;
	email?: string;
	preferred_username?: string;
	resource_access?: {
		// [client in string]?: {
		ccs?: {
			roles?: HackingLabRole[];
		}
	};
	// TODO: complete
}

/**
 * Mock an unsigned token with all roles
 */
export const getDevelopmentToken = () => {
	const userUuid = "ab842c08-b9cf-4b78-8e92-832ad55c4119";
	const userName = "development_user";
	const userMail = "team@hacking-lab.com";

	const token: HackingLabToken = {
		sub: userUuid,
		preferred_username: userName,
		email: userMail,
		resource_access: { ccs: { roles: Object.values(HackingLabRole) } },
	};

	return "." + btoa(JSON.stringify(token)) + ".";
};

export const getMockTeacherToken = () => {
	const userUuid = "e3c75929-b568-4745-8e61-25d9c79a8637";
	const userName = "mock-teacher-0";
	const userMail = "mock-teacher-0@dev.hacking-lab.com";

	const token: HackingLabToken = {
		sub: userUuid,
		preferred_username: userName,
		email: userMail,
		resource_access: { ccs: { roles: Object.values(HackingLabRole) } },
	};

	return "." + btoa(JSON.stringify(token)) + ".";
};
