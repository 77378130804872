import { ActionType } from "actions/ActionType";

const BASE_URL = "/api/teacher";

const fetchTeacherEvents = onSuccess => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/`,
	method: "get",
	resource: "teacherEvents",
	onSuccess: (_, events) => onSuccess?.(events),
});

export const fetchTeacherEvent = (eventId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}`,
	method: "get",
	resource: "teacherEvents",
	onSuccess: (dispatch, event) => {
		if (resolveParents && event.parent) {
			dispatch(fetchTeacherEvent(event.parent, resolveParents));
		}
	},
});

const fetchTeacherChallenge = (challengeId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}`,
	method: "get",
	resource: "teacherChallenges",
	onSuccess: (dispatch, challenge) => {
		const { sections, eventId } = challenge;
		if (resolveParents && eventId)
			dispatch(fetchTeacherEvent(eventId, resolveParents));
		if (Array.isArray(sections) && sections.length)
			sections.forEach(
				s => s.id && dispatch(fetchTeacherSteps(challengeId, s.id)),
			);
	},
});

const fetchTeacherTheory = (theoryId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/theories/${theoryId}`,
	method: "get",
	resource: "teacherTheories",
	onSuccess: (dispatch, theory) => {
		const { eventId } = theory;
		if (resolveParents && eventId)
			dispatch(fetchTeacherEvent(eventId, resolveParents));
	},
});

const fetchTeacherQuiz = (quizId, resolveParents) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}`,
	method: "get",
	resource: "teacherQuizzes",
	onSuccess: (dispatch, quiz) => {
		const { eventId } = quiz;
		if (quiz.trainingMode) dispatch(fetchQuizGradingInstructions(quiz.id));
		if (eventId) dispatch(fetchTeacherEvent(eventId, resolveParents));
	},
});

const fetchQuizGradingInstructions = quizId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizzes/${quizId}/gradingInstructions/`,
	method: "get",
	resource: "questionGradingInstructions",
	updatePropForArray: {
		key: "quizId",
		value: quizId,
	},
	setFieldOnObject: {
		key: "quizId",
		value: quizId,
	},
});

const gradeQuizAnswer = (answerId, solutionId, correct) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizsolutions/${solutionId}/answers/${answerId}`,
	method: "put",
	body: { correctAnswer: correct },
	onSuccess: dispatch => {
		dispatch(fetchTeacherQuizAnswers(solutionId));
		dispatch(fetchTeacherQuizSolution(solutionId));
	},
});

export const fetchTeacherEventUnits = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/units/`,
	method: "get",
	resource: "teacherEventUnits",
	updatePropForArray: {
		key: "eventId",
		value: eventId,
	},
});

const fetchAccessTokens = (eventId, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/accesstokens/`,
	method: "get",
	resource: "accessTokens",
	updatePropForArray: {
		key: "eventId",
		value: eventId,
	},
	onSuccess: onDone,
	onFailure: onDone,
});

const updateStaticToken = (
	eventId,
	tokenId,
	token,
	maxRedemptions,
	onSuccess,
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/accesstokens/${tokenId}`,
	method: "PUT",
	body: { token, maxRedemptions },
	resource: "accessTokens",
	updatePropForObj: { idSelector: token => `${token.id}.${token.eventId}` },
	onSuccess: onSuccess,
});

const fetchTeacherSolutions = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/solutions/`,
	method: "get",
	resource: "teacherSolutions",
	updatePropForArray: {
		key: "eventId",
		value: eventId,
	},
});

const fetchTeacherSolution = solutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/solutions/${solutionId}`,
	method: "get",
	resource: "teacherSolutions",
	onSuccess: (dispatch, solution) => {
		if (solution.eventId) dispatch(fetchTeacherEvent(solution.eventId));
		solution.id && dispatch(fetchTeacherSolutionComments(solution.id));
		if (solution.challenge && solution.challenge.id)
			dispatch(fetchTeacherGradingInstructions(solution.challenge.id));
	},
});

const fetchTeacherSolutionUserFlags = solutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/solutions/${solutionId}/userflags/`,
	method: "get",
	resource: "teacherSolutionUserFlags",
	updatePropForArray: {
		key: "solutionId",
		value: solutionId,
	},
});

const fetchTeacherSolutionComments = solutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/solutions/${solutionId}/comments/`,
	method: "get",
	resource: "teacherSolutionComments",
	updatePropForArray: {
		key: "solutionId",
		value: solutionId,
	},
});

const fetchTeacherGradingInstructions = challengeId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/gradingInstruction`,
	method: "get",
	resource: "teacherGradingInstruction",
});

const postTeacherSolutionComment = (
	comment,
	solutionId,
	onSuccess,
	onFailure,
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/solutions/${solutionId}/comments/`,
	body: comment,
	method: "post",
	resource: "solutionComments",
	successNotification: true,
	onSuccess,
	onFailure,
});

const fetchTeacherSteps = (challengeId, sectionId) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/sections/${sectionId}/steps/`,
	method: "get",
	resource: "teacherSteps",
	updatePropForArray: {
		key: "sectionId",
		value: sectionId,
	},
});

const fetchTeacherQuizSolutions = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/quizSolutions/`,
	method: "get",
	resource: "teacherQuizSolutions",
});

const fetchTeacherQuizSolution = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}`,
	method: "get",
	resource: "teacherQuizSolutions",
});

const fetchTeacherQuizAnswers = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/answers/`,
	method: "get",
	resource: "teacherQuizAnswers",
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
});

const fetchTeacherQuizComments = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/comments/`,
	method: "get",
	resource: "teacherQuizComments",
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
});

const fetchTeacherQuizGradingInstructions = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/gradingInstructions/`,
	method: "get",
	resource: "teacherQuizGradingInstructions",
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
});

const postTeacherQuizComment = (
	comment,
	quizSolutionId,
	onSuccess,
	onFailure,
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/comments/`,
	body: comment,
	method: "POST",
	resource: "teacherQuizComments",
	successNotification: true,
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
	onSuccess,
	onFailure,
});

const reopenQuizSolution = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/tasks`,
	body: { action: "reopenQuizSolution" },
	method: "POST",
	resource: "teacherQuizSolutions",
	setFieldOnObject: {
		key: "finished",
		value: false,
	},
});

const postTeacherSuggestion = (
	{ eventId, unitType, unitId, body },
	successNotification,
) => ({
	type: ActionType.API_REQUEST,
	url: `/api/events/${eventId}/units/${unitType}/${unitId}/issues/`,
	body: body,
	method: "post",
	successNotification,
});

const fetchParticipantProgress = eventId => ({
	type: ActionType.API_REQUEST,
	method: "get",
	resource: "teacherEventProgress",
	setFieldOnObject: {
		key: "eventId",
		value: eventId,
	},
	url: `${BASE_URL}/events/${eventId}/userprogress/`,
	updatePropForObj: { idSelector: participantProgress => participantProgress.eventId || eventId },
});

const participantBase = eventId => ({
	type: ActionType.API_REQUEST,
	method: "get",
	resource: "teacherEventParticipants",
	setFieldOnObject: {
		key: "eventId",
		value: eventId,
	},
});

const fetchEventParticipants = eventId => ({
	...participantBase(eventId),
	url: `${BASE_URL}/events/${eventId}/participants/`,
	updatePropForObj: { idSelector: participantProfile => participantProfile.eventId || eventId },
});

const participantEventIdSelector = eventId => participantProfile =>
	`${participantProfile.eventId || eventId}.${participantProfile.profile.id}`;

const fetchEventParticipant = (eventId, userId) => ({
	...participantBase(eventId),
	url: `${BASE_URL}/events/${eventId}/participants/${userId}/profile`,
	updatePropForObj: { idSelector: participantEventIdSelector(eventId) },
});
const updateParticipantNote = (eventId, userId, notes) => ({
	...participantBase(eventId),
	url: `${BASE_URL}/events/${eventId}/participants/${userId}/notes`,
	method: "put",
	body: notes,
	successNotification: true,
	updatePropForObj: { idSelector: participantEventIdSelector(eventId) },
});

const getVms = challengeId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/vms/`,
	method: "get",
	resource: "teacherVms",
	updatePropForObj: { idSelector: dto => dto.uuid },
});

const getVmState = (challengeId, vm, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/vms/${vm.uuid}/state`,
	method: "get",
	resource: "teacherVms",
	updatePropForObj: { idSelector: dto => dto.uuid },
	updateField: { key: "state", entityId: vm.uuid },
	onFailure: onDone,
	onSuccess: onDone,
});

const vmAction = (challengeId, vm, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	body: { resourceUuid: vm.uuid },
	method: "post",
	onSuccess: dispatch => dispatch(getVmState(challengeId, vm, onDone)),
	onBefore: onBefore,
	onFailure: onDone,
});

const startVm = challengeId => (vm, onBefore, onDone) => ({
	...vmAction(challengeId, vm, onBefore, onDone),
	url: `${BASE_URL}/challenges/${challengeId}/vms/${vm.uuid}/start`,
});

const stopVm = challengeId => (vm, onBefore, onDone) => ({
	...vmAction(challengeId, vm, onBefore, onDone),
	url: `${BASE_URL}/challenges/${challengeId}/vms/${vm.uuid}/stop`,
});

const resetVm = challengeId => (vm, onBefore, onDone) => ({
	...vmAction(challengeId, vm, onBefore, onDone),
	url: `${BASE_URL}/challenges/${challengeId}/vms/${vm.uuid}/reset`,
});

const revertVm = challengeId => (vm, onBefore, onDone) => ({
	...vmAction(challengeId, vm, onBefore, onDone),
	url: `${BASE_URL}/challenges/${challengeId}/vms/${vm.uuid}/revert`,
});

export default {
	fetchTeacherEvents,
	fetchTeacherEvent,
	fetchTeacherChallenge,
	fetchTeacherTheory,
	fetchTeacherQuiz,
	fetchQuizGradingInstructions,
	gradeQuizAnswer,
	fetchTeacherEventUnits,
	fetchAccessTokens,
	updateStaticToken,
	fetchTeacherSolutions,
	fetchTeacherSolution,
	fetchTeacherSolutionUserFlags,
	fetchTeacherGradingInstructions,
	postTeacherSolutionComment,
	fetchTeacherQuizSolutions,
	fetchTeacherQuizSolution,
	fetchTeacherQuizAnswers,
	fetchTeacherQuizComments,
	fetchTeacherQuizGradingInstructions,
	postTeacherQuizComment,
	reopenQuizSolution,
	postTeacherSuggestion,
	fetchParticipantProgress,
	fetchEventParticipants,
	fetchEventParticipant,
	updateParticipantNote,
	getVms,
	startVm,
	stopVm,
	resetVm,
	revertVm,
};
